import { CSSProperties, useEffect } from 'react';

import { BoxProps } from '@spotnana/pixel-react/dist/Box';
import {
  getProfileDisplayText,
  invalidateReadProfile,
  IUserOrgId,
  TravelerBasicInfo,
  useGetUserOrgIdFromUserId,
  useIsAdhocBookingEnabled,
  useLoggedInUserId,
  usePrimaryTraveler,
  usePrimaryTravelerId,
  useProfileReadQuery,
  UserOrgId,
} from 'obt-common';
import { TierEnum } from 'obt-common/types/api/v1/obt/common/user_org_id';
import useHandleToggleAdhocBooking from 'src/hooks/useHandleToggleAdhocBooking';
import { AutocompleteProps } from 'src/v2-components/shared/Autocomplete';
import { AutocompleteExpandDirections } from '../../constants/autocomplete';
import { IHandleTravelerChangeType } from '../../hooks/useTravelerChangeConfirmation';
import GuestTravelerChip from '../../v2-components/shared/GuestTravelerChip';
import TravelerSelector from './TravelerSelector';

interface IProps {
  containerStyles?: Omit<BoxProps, 'theme' | 'color'>;
  disabled: boolean;
  forceFreshTravelerSelectionOnMount?: boolean;
  setForceFreshTravelerSelectionOnMount?: (val: boolean) => void;
  onSelect?: (selectionValue: UserOrgId | undefined) => void;
  onClear?: VoidFunction;
  onSelection?: (val: boolean) => void; // this should be renamed or removed, very confusing name
  travelerSelectorExpandDirection?: AutocompleteExpandDirections;
  openRegisterModal?: () => void;
  showChangeTravelerText?: boolean;
  showRegisterTravelerOnFooter?: boolean;
  preSelectedTravelerId?: IUserOrgId;
  handleTravelerChange: (props: IHandleTravelerChangeType) => void;
  isAdhocBooking: boolean;
  outerContainerStyles: AutocompleteProps<TravelerBasicInfo>['outerContainerStyles'];
  clickAwayListernerStyles?: CSSProperties;
  inputContainerWidth?: string;
  userId: string;
  showInModal?: boolean;
}

const SearchFormTravelerSelector = ({
  containerStyles,
  disabled,
  forceFreshTravelerSelectionOnMount,
  setForceFreshTravelerSelectionOnMount,
  onSelect,
  onClear,
  onSelection,
  travelerSelectorExpandDirection,
  openRegisterModal,
  showChangeTravelerText,
  showRegisterTravelerOnFooter,
  preSelectedTravelerId,
  handleTravelerChange,
  isAdhocBooking,
  outerContainerStyles,
  clickAwayListernerStyles,
  inputContainerWidth,
  userId,
  showInModal = true,
}: IProps): JSX.Element => {
  const { handleToggleAdhocBooking } = useHandleToggleAdhocBooking({ isAdhocBooking });

  const { setPrimaryTravelerId: setTravelerId } = usePrimaryTravelerId();
  const loggedInUserId = useLoggedInUserId();
  const { data: selectedProfile, isLoading } = usePrimaryTraveler();

  const { isLoading: preSelectedTravelerIdLoading, data: preSelectedTravelerData } = useProfileReadQuery(
    preSelectedTravelerId,
    { enabled: !!preSelectedTravelerId },
  );

  const { userOrgId } = useGetUserOrgIdFromUserId(userId);

  const profileData = preSelectedTravelerData ?? selectedProfile;

  const { isAdhocBookingEnabled: isAdhocBookingFeatureEnabled } = useIsAdhocBookingEnabled();

  useEffect(() => {
    if (userOrgId) {
      setTravelerId(userOrgId);
      if (forceFreshTravelerSelectionOnMount && setForceFreshTravelerSelectionOnMount) {
        setForceFreshTravelerSelectionOnMount(false);
      }
      if (onSelection) {
        onSelection(false);
      }
    }
    // eslint-disable-next-line
  }, [userOrgId]);

  const handleMe = (): void => {
    onSelect?.(loggedInUserId);
    setTravelerId(loggedInUserId);
    if (forceFreshTravelerSelectionOnMount && setForceFreshTravelerSelectionOnMount) {
      setForceFreshTravelerSelectionOnMount(false);
    }
    if (onSelection) {
      onSelection(false);
    }
  };

  const handleSelection = (traveler: TravelerBasicInfo | null): void => {
    if (!traveler) {
      return;
    }

    const selectedTravelerId = (traveler.userOrgId as IUserOrgId) ?? userOrgId;
    if (selectedTravelerId) {
      invalidateReadProfile(selectedTravelerId);
      setTravelerId(selectedTravelerId);
      if (forceFreshTravelerSelectionOnMount && setForceFreshTravelerSelectionOnMount) {
        setForceFreshTravelerSelectionOnMount(false);
      }
      if (onSelection) {
        onSelection(false);
      }
    }
  };

  const showGuestTravelerComponent = isAdhocBookingFeatureEnabled && isAdhocBooking;
  const travelerName = selectedProfile?.user?.name;
  const travelerEmail = selectedProfile?.userBusinessInfo?.email ?? '';
  const travelerPicture = selectedProfile?.user?.profilePicture;
  const displayName = getProfileDisplayText(travelerName, travelerEmail, { usePreferredName: true });

  const guestTravelerSelectorOnClear = () => {
    return handleTravelerChange({ onTravelerChange: handleToggleAdhocBooking, adhocBookingFlow: true });
  };

  return showGuestTravelerComponent ? (
    <GuestTravelerChip
      loading={isLoading || preSelectedTravelerIdLoading}
      registrarTraveler={displayName}
      profilePicture={travelerPicture}
      onClear={guestTravelerSelectorOnClear}
      isSeat1a={selectedProfile?.tier === TierEnum.SEAT1A}
      disabled={disabled}
      containerStyles={containerStyles}
    />
  ) : (
    <TravelerSelector
      loading={isLoading || preSelectedTravelerIdLoading}
      onClear={onClear}
      addMe={() => handleTravelerChange({ onTravelerChange: handleMe })}
      onSelect={(selectionValue: TravelerBasicInfo | null) => {
        handleTravelerChange({ onTravelerChange: () => handleSelection(selectionValue) });
        onSelect?.(selectionValue?.userOrgId);
      }}
      selectedProfile={forceFreshTravelerSelectionOnMount ? undefined : profileData}
      disabled={disabled}
      displayCurrentTraveler={!forceFreshTravelerSelectionOnMount}
      expandDirection={travelerSelectorExpandDirection}
      outerContainerStyles={outerContainerStyles}
      clickAwayListernerStyles={clickAwayListernerStyles}
      inputContainerWidth={inputContainerWidth}
      openRegisterModal={() =>
        handleTravelerChange({
          onTravelerChange: () => {
            if (openRegisterModal) {
              openRegisterModal();
            }
          },
        })
      }
      showChangeTravelerText={showChangeTravelerText}
      showRegisterTravelerOnFooter={showRegisterTravelerOnFooter}
      showInModal={showInModal}
    />
  );
};

SearchFormTravelerSelector.defaultProps = {
  disabled: false,
} as Partial<IProps>;

export default SearchFormTravelerSelector;
