import snakeCase from 'lodash/snakeCase';
import { lazy } from 'react';
import * as React from 'react';

import { agentRoutes } from './app/agent/routes';
import { analyticsRoutes } from './app/analytics/routes';
import { selfEnrollmentRoutes } from './app/enrollment/routes';
import { privacyRoutes } from './app/privacy/routes';
import { configurationRoutes } from './app/configurations/routes';
import { paymentsRoutes } from './app/payments/routes';
import { platformRoutes } from './app/platform/routes';
import { profileRoutes } from './app/profile/routes';
import { authRoutes } from './app/auth/routes';
import { tripsRoutes } from './app/trips/routes';
import { userRoutes, USER_ACTION_ALL_ROUTES } from './app/user/routes';
import { onboardingRoutes } from './app/admin/onboarding/routes';

const AddPaymentTask = () => import('./WebView/AddPayment');
const AddPayment = lazy(AddPaymentTask);
// mobile views
const MobileAirCheckoutTask = () => import('./WebView/MobileAirCheckout');
const MobileAirCheckout = lazy(MobileAirCheckoutTask);

const MobileCcPostVerificationViewTask = () => import('./WebView/MobileCcPostVerificationView');
const MobileCcPostVerificationView = lazy(MobileCcPostVerificationViewTask);

const MobileCheckoutTask = () => import('./WebView/MobileCheckout');
const MobileCheckout = lazy(MobileCheckoutTask);

const MobileHotelCheckoutTask = () => import('./WebView/MobileHotelCheckout');
const MobileHotelCheckout = lazy(MobileHotelCheckoutTask);

const MobilePdfPageTask = () => import('./WebView/MobilePdfPage');
const MobilePdfPage = lazy(MobilePdfPageTask);

const MobilePdfPageV2 = lazy(() => import('./WebView/MobilePdfPageV2'));
// Admin page component

const AdminTask = () => import('./v1-components/admin');
const Admin = lazy(AdminTask);

// Login Page component
const AuthTask = () => import('./v1-components/auth');
const Auth = lazy(AuthTask);

const CarCancelTask = () => import('./v1-components/trips/details/cars/cancel');
const CarCancel = lazy(CarCancelTask);

const CarSearchFormTask = () => import('./v2-components/cars/search/form');
const CarSearchForm = lazy(CarSearchFormTask);

const CarSearchResultsTask = () => import('./v2-components/cars/search/results');
const CarSearchResults = lazy(CarSearchResultsTask);
// car components

const CarsCheckoutTask = () => import('./v2-components/cars/checkout/CarCheckout');
const CarsCheckout = lazy(CarsCheckoutTask);

const DeImpersonationTask = () => import('./v1-components/auth/Impersonation/DeImpersonation');
const DeImpersonation = lazy(DeImpersonationTask);

const FlightCancelTask = () => import('./v1-components/trips/details/flights/cancel/CancellationContainer/index');
const FlightCancel = lazy(FlightCancelTask);

const ManualFormPageTask = () => import('./app/flights/manualForm/ManualFormPage');
const ManualFormPage = lazy(ManualFormPageTask);

const CancelManualFormPageTask = () => import('./app/flights/cancelManualForm/CancelManualFormPage');
const CancelManualFormPage = lazy(CancelManualFormPageTask);

const FlightCheckPnrStatusTask = () => import('./app/flights/checkout/CheckoutFinalizePage/CheckPnrStatus');
const FlightCheckPnrStatus = lazy(FlightCheckPnrStatusTask);

const FlightExchangeFormContainerTask = () => import('./app/flights/ExchangeFlightSearchPage');
const FlightExchangeFormContainer = lazy(FlightExchangeFormContainerTask);

const FlightSearchResultsTask = () => import('./app/flights/FlightSearchResultsPage/FlightSearchResultsPageContainer');
const FlightSearchResults = lazy(FlightSearchResultsTask);

const FlightCheckoutContainerTask = () => import('./app/flights/checkout/ContainerPage');
const FlightCheckoutContainer = lazy(FlightCheckoutContainerTask);

const FlightRetryPaymentCheckoutPromise = import('./app/flights/RetryPaymentCheckoutPage');
const FlightRetryPaymentCheckout = lazy(() => FlightRetryPaymentCheckoutPromise);

const BookingHoldConfirmationTask = () => import('./app/flights/ConfirmHoldCheckoutPageV1');
const BookingHoldConfirmation = lazy(BookingHoldConfirmationTask);

const ConfirmHoldTask = () => import('./app/flights/ConfirmHoldCheckout/ContainerPage');
const ConfirmHold = lazy(ConfirmHoldTask);

/**
 * TO DO: use https://github.com/GoogleChromeLabs/quicklink for prefetching
 * in-viewport links instead of everything later. This needs webpack change
 *  */

// flight components
const FlightSearchFormTask = () => import('./app/flights/FlightSearchPage/FlightSearchForm');
const FlightSearchForm = lazy(FlightSearchFormTask);

const FlightExchangeResultsTask = () => import('./app/flights/ExchangeFlightSearchResultsPage');
const FlightExchangeResults = lazy(FlightExchangeResultsTask);

const FlightCheckoutV2Task = () => import('./app/flights/checkout-v2/ContainerPage');
const FlightCheckoutV2 = lazy(FlightCheckoutV2Task);

// const AmadeusPostVerificationTask = () => import(
//   './v2-components/flights/v2-checkout/air-checkout/AmadeusPostVerification/AmadeusPostVerification'
// );
// const AmadeusPostVerification = lazy(AmadeusPostVerificationTask);

const HotelCancelTask = () => import('./v1-components/trips/details/hotels/cancel');
const HotelCancel = lazy(HotelCancelTask);

const HotelDetailsTask = () => import('./v2-components/hotels/details');
const HotelDetails = lazy(HotelDetailsTask);

const HotelSearchFormTask = () => import('./v1-components/hotels/search/form');
const HotelSearchForm = lazy(HotelSearchFormTask);

const HotelSearchResultsTask = () => import('./v1-components/hotels/search/results');
const HotelSearchResults = lazy(HotelSearchResultsTask);

const HotelModifyTask = () => import('./app/hotels/ModifyFlowDetailsPage/ModifyFlowDetailsPage');
const HotelModify = lazy(HotelModifyTask);

// hotel components
const HotelsCheckoutTask = () => import('./v1-components/hotels/checkout');
const HotelsCheckout = lazy(HotelsCheckoutTask);

const ImpersonationTask = () => import('./v1-components/auth/Impersonation/index');
const Impersonation = lazy(ImpersonationTask);

const LegalEntityTask = () => import('./v1-components/auth/LegalEntity');
const LegalEntity = lazy(LegalEntityTask);

const LimoCancelTask = () => import('./v1-components/trips/details/limo/cancel');
const LimoCancel = lazy(LimoCancelTask);

const LogoutTask = () => import('./Logout');
const Logout = lazy(LogoutTask);

const MiscCancelTask = () => import('./v1-components/trips/details/misc/cancel');
const MiscCancel = lazy(MiscCancelTask);

const PnrCancelSuccessTask = () => import('./v1-components/trips/details/shared/Success');
const PnrCancelSuccess = lazy(PnrCancelSuccessTask);

const RailBookingSummaryTask = () => import('./v1-components/rails/checkout/bookingSummary');
const RailBookingSummary = lazy(RailBookingSummaryTask);

const RailCancelTask = () => import('./v1-components/trips/details/rail/cancel/RailCancel');
const RailCancel = lazy(RailCancelTask);

const RailModifyTask = () => import('./v2-components/trips/change/rail/search/RailModify');
const RailModify = lazy(RailModifyTask);

const RailModifyResultsTask = () => import('./v2-components/trips/change/rail/results/RailModifyResults');
const RailModifyResults = lazy(RailModifyResultsTask);

const RailModifySummaryTask = () => import('./v2-components/trips/change/rail/summary/RailModifySummary');
const RailModifySummary = lazy(RailModifySummaryTask);

const RailModifyCheckoutTask = () => import('./v2-components/trips/change/rail/checkout/RailModifyCheckout');
const RailModifyCheckout = lazy(RailModifyCheckoutTask);

const RailSearchResultsTask = () => import('./v1-components/rails/search/results');
const RailSearchResults = lazy(RailSearchResultsTask);

// rails components
const RailsCheckoutTask = () => import('./v1-components/rails/checkout');
const RailsCheckout = lazy(RailsCheckoutTask);

const SearchTask = () => import('./v1-components/search');
const Search = lazy(SearchTask);

const ShellPnrCreateTask = () => import('./v2-components/shellPnr/create');
const ShellPnrCreate = lazy(ShellPnrCreateTask);

const ShellPnrUpdate = lazy(() => import('./v2-components/shellPnr/updateShellPnr'));

// support tab
const SupportTabTask = () => import('./v1-components/support');
const SupportTab = lazy(SupportTabTask);

const TripDetailTask = () => import('./v2-components/trips/details');
const TripDetail = lazy(TripDetailTask);

const GroupTripDetailTask = () => import('./app/trips/GroupTripDetailsPage');
const GroupTripDetail = lazy(GroupTripDetailTask);

// trip page
const TripsTask = () => import('./v2-components/trips');
const Trips = lazy(TripsTask);

const GroupTripsTask = () => import('./app/trips/GroupTripListPage');
const GroupTrips = lazy(GroupTripsTask);

// company trips page
const CompanyTripsTask = () => import('./v2-components/trips/CompanyTripsPage');
const CompanyTrips = lazy(CompanyTripsTask);

const AirExchangeCheckoutContainerTask = () => import('./app/flights/ExchangeFlightCheckoutPage/V2CheckoutContainer');
const AirExchangeCheckoutContainer = lazy(AirExchangeCheckoutContainerTask);

const AirAddOnCheckoutContainerTask = () => import('./app/flights/AddOnsCheckoutPage');
const AirAddOnCheckoutContainer = lazy(AirAddOnCheckoutContainerTask);

const AirWebCheckInContainerTask = () => import('./app/flights/WebCheckInPage');
const AirWebCheckInContainer = lazy(AirWebCheckInContainerTask);

const AirWebCheckInConfirmationContainerTask = () => import('./app/flights/WebCheckInConfirmationPage');
const AirWebCheckInConfirmationContainer = lazy(AirWebCheckInConfirmationContainerTask);

const SeatEditFormTask = () => import('./app/flights/SeatEditSeatMapPage');
const SeatEditForm = lazy(SeatEditFormTask);

const HardApprovalTask = () => import('./v2-components/hardApproval');
const HardApproval = lazy(HardApprovalTask);

const EditSeatCheckoutTask = () => import('./app/flights/SeatEditCheckoutPage');
const EditSeatCheckout = lazy(EditSeatCheckoutTask);

const TMCAdminTask = () => import('./v2-components/tmc');
const TMCAdmin = lazy(TMCAdminTask);

const CenterSsoLoginTask = () => import('./v1-components/centerSsoLogin');
const CenterSsoLogin = lazy(CenterSsoLoginTask);

const Confirmation = lazy(() => import('./app/shared/components/Confirmation/ConfirmationSwitch'));

const TaskStatusDocumentTask = () => import('./v2-components/shared/TaskStatusDocument');
const TaskStatusDocument = lazy(TaskStatusDocumentTask);

const DebugToolTask = () => import('./v2-components/support/DebugTool');
const DebugTool = lazy(DebugToolTask);

// Preferred Language
const PreferredLanguage = lazy(() =>
  import('./v2-components/shared/PreferredLanguage/PreferredLanguage').then((module) => ({
    default: module.PreferredLanguage,
  })),
);

// Events
const EventsPage = lazy(() => import('./app/events/EventsPage'));

const ReportsPage = lazy(() =>
  import('./app/events/ReportsPage/ReportsPage').then((module) => ({
    default: module.ReportsPage,
  })),
);

const CreateEventPage = lazy(() =>
  import('./app/events/EventCreationPage/EventCreationPage').then((module) => ({
    default: module.EventCreationPage,
  })),
);

const EventTripPage = lazy(() => import('./app/events/EventTripPage'));

const EventEditPage = lazy(() => import('./app/events/EventEditPage'));

const FinalizePnrTask = () => import('./v2-components/trips/finalize/FinalizePnr');
const FinalizePnr = lazy(FinalizePnrTask);

interface IRouteConfig {
  key: string;
  path: string;
  // eslint-disable-next-line
  component: React.FC<any>;
  exact?: boolean;
  isPublicRoute?: boolean;
  search?: string;
}

const routesConfig: IRouteConfig[] = [
  ...agentRoutes,
  ...analyticsRoutes,
  ...selfEnrollmentRoutes,
  ...authRoutes,
  ...platformRoutes,
  ...profileRoutes,
  ...tripsRoutes,
  ...paymentsRoutes,
  ...privacyRoutes,
  ...configurationRoutes,
  ...userRoutes,
  ...onboardingRoutes,
  {
    key: 'home',
    path: '/',
    component: Auth,
    isPublicRoute: true,
  },
  {
    key: 'HEADLESS_AUTH',
    path: '/init-auth',
    component: Auth,
    isPublicRoute: true,
  },
  {
    key: 'Service',
    path: '/',
    component: Search,
    search: ':service(|flights|hotels|cars|concierge|rails)',
  },
  {
    key: 'login',
    path: '/login',
    component: Auth,
    exact: false,
    isPublicRoute: true,
  },
  {
    key: 'impersonate',
    path: '/impersonate',
    component: Impersonation,
  },
  {
    key: 'deimpersonation',
    path: '/deimpersonate',
    component: DeImpersonation,
  },
  {
    key: 'flights-checkout',
    path: '/flights/checkout/:step(ancillary|seatmap|finalize)',
    component: FlightCheckoutContainer,
  },
  {
    key: 'flights-results',
    path: '/flights/results',
    component: FlightSearchResults,
  },
  {
    key: 'flights',
    path: '/flights',
    component: FlightSearchForm,
  },
  {
    key: 'hotels',
    path: '/hotels',
    component: HotelSearchForm,
  },
  {
    key: 'hotels-results',
    path: '/hotels/results',
    component: HotelSearchResults,
  },
  {
    key: 'hotels-details',
    path: '/hotels/details',
    component: HotelDetails,
  },
  {
    key: 'hotels-modify-details',
    path: '/trips/:tripId/change/hotels/:pnrId/details',
    component: HotelModify,
  },
  {
    key: 'hotels-modify-details-checkout',
    path: '/trips/:tripId/change/hotels/:pnrId/checkout',
    component: HotelsCheckout,
  },
  {
    key: 'hotels-checkout',
    path: '/hotels/checkout',
    component: HotelsCheckout,
  },
  {
    key: 'cars',
    path: '/cars',
    component: CarSearchForm,
  },
  {
    key: 'cars-results',
    path: '/cars/results',
    component: CarSearchResults,
  },
  {
    key: 'cars-checkout',
    path: '/cars/checkout',
    component: CarsCheckout,
    exact: false,
  },
  {
    key: 'trips',
    path: '/trips',
    component: Trips,
    search: '/:type(upcoming|past|cancelled)?',
  },
  {
    key: 'group-trips',
    path: '/group-trips',
    component: GroupTrips,
    search: '/:type(upcoming|completed|cancelled)?',
  },
  {
    key: 'company-trips',
    path: '/company-trips',
    component: CompanyTrips,
    search: '/:type(upcoming|past|cancelled)?',
  },
  {
    key: 'rails-checkout',
    path: '/rails/checkout',
    component: RailsCheckout,
  },
  {
    key: 'rails-checkout-success',
    path: '/rails/checkout-success',
    component: RailBookingSummary,
    exact: true,
  },
  {
    key: 'trip-detail',
    path: '/trips/:tripId',
    component: TripDetail,
    search: '/:type(upcoming|past|cancelled)?',
  },
  {
    key: 'group-trip-detail',
    path: '/group/:groupId/trip/:tripId?',
    component: GroupTripDetail,
    search: '/:type(upcoming|past|cancelled)?',
  },

  {
    key: 'car-cancel',
    path: '/trips/car/cancel',
    component: CarCancel,
  },
  {
    key: 'misc-cancel',
    path: '/trips/misc/cancel',
    component: MiscCancel,
  },
  {
    key: 'flight-cancel',
    path: '/trips/flight/cancel',
    component: FlightCancel,
  },
  {
    key: 'hotel-cancel',
    path: '/trips/hotel/cancel',
    component: HotelCancel,
  },
  {
    key: 'limo-cancel',
    path: '/trips/limo/cancel',
    component: LimoCancel,
  },
  {
    key: 'rail-cancel',
    path: '/trips/rail/cancel',
    component: RailCancel,
  },
  {
    key: 'rail-modify',
    path: '/trips/:tripId/rail/modify/:pnrId',
    component: RailModify,
  },
  {
    key: 'rail-modify-results',
    path: '/trips/:tripId/rail/modify/:pnrId/results',
    component: RailModifyResults,
  },
  {
    key: 'rail-modify-summary',
    path: '/trips/:tripId/rail/modify/:pnrId/summary',
    component: RailModifySummary,
  },
  {
    key: 'rail-modify-checkout',
    path: '/trips/:tripId/rail/modify/:pnrId/checkout',
    component: RailModifyCheckout,
  },
  {
    key: 'pnr-cancel-success',
    path: '/trips/cancel/success',
    component: PnrCancelSuccess,
  },
  {
    key: 'shell-pnr-create',
    path: '/shellPnr/create',
    component: ShellPnrCreate,
  },
  {
    key: 'tmc-admin',
    path: '/admin/tmc/:page(companies|settings|custom)?/:subPage?',
    component: TMCAdmin,
    exact: false,
    search: '/:policyId?',
  },
  {
    key: 'admin',
    path: '/admin/:page(company|users|policies)?',
    component: Admin,
    exact: false,
    search: '/:employeePolicyId?/:nonEmployeePolicyId?',
  },
  {
    key: 'signout',
    path: '/signout',
    component: Logout,
    isPublicRoute: true,
  },
  {
    key: 'legal-entity',
    path: '/legal-entity',
    component: LegalEntity,
    isPublicRoute: true,
  },

  {
    key: 'create-shell-pnr-flights',
    path: '/trips/:tripId/shell-pnr/:travelType',
    component: ShellPnrCreate,
    exact: true,
  },
  {
    key: 'update-shell-pnr-flights',
    path: '/trips/:tripId/update-shell-pnr/:pnrId',
    component: ShellPnrUpdate,
    exact: true,
  },
  {
    key: 'support-tab',
    path: '/support',
    component: SupportTab,
  },
  {
    key: 'rails-results',
    path: '/rails/results',
    component: RailSearchResults,
  },
  {
    key: 'mobile-add-payment-card',
    path: '/mobile/payment/add-card',
    component: AddPayment,
    exact: true,
    isPublicRoute: true,
  },
  {
    key: 'mobile-checkout-air',
    path: '/mobile/checkout/air',
    component: MobileAirCheckout,
    exact: true,
    isPublicRoute: true,
  },
  {
    key: 'mobile-checkout-hotel',
    path: '/mobile/checkout/hotel',
    component: MobileHotelCheckout,
    exact: true,
    isPublicRoute: true,
  },
  {
    key: 'mobile-pdf-page',
    path: '/mobile/asset/pdf',
    component: MobilePdfPage,
    exact: true,
    isPublicRoute: true,
  },
  {
    key: 'mobile-pdf-page-v2',
    path: '/mobile/asset-v2/pdf',
    component: MobilePdfPageV2,
    exact: true,
    isPublicRoute: true,
  },
  {
    key: 'mobile-flights-cc-post-verification-old',
    path: '/mobile/flights/ccPostVerifcation',
    //                              ^^^ WE KNOW THIS ROUTE NAME IS SPELLED WRONG
    // For backwards compatibility, we need to keep this route name as is.
    // Mobile app users with older versions of the app will still be using this route.
    // bellow you can find a copy with correct route name
    component: MobileCcPostVerificationView,
    isPublicRoute: true,
  },
  {
    key: 'mobile-flights-cc-post-verification',
    path: '/mobile/flights/ccPostVerification',
    component: MobileCcPostVerificationView,
    isPublicRoute: true,
  },
  {
    key: 'mobile-checkout',
    path: '/mobile/checkout',
    component: MobileCheckout,
    isPublicRoute: true,
  },
  {
    key: 'flight-manual-form',
    path: '/trips/:tripId/import/flights/:flow/:step(select-travelers|upload-document|ticket-traveler-details|flight-details|ancillaries-and-add-ons|price-summary|finalize)?',
    component: ManualFormPage,
  },
  {
    key: 'flight-cancel-manual-form',
    path: '/trips/:tripId/cancel/flights/:flow/:step(ticket-traveler-details|price-summary|finalize)?',
    component: CancelManualFormPage,
  },
  {
    key: 'flight-exchange-form',
    path: '/trips/:tripId/change/flights/:pnrId/:currentLegDetailIndex/search',
    component: FlightExchangeFormContainer,
  },
  {
    key: 'flight-exchange-checkout',
    path: '/trips/:tripId/change/flights/:pnrId/:currentLegDetailIndex/checkout',
    component: AirExchangeCheckoutContainer,
  },
  {
    key: 'flight-add-on-checkout',
    path: '/trips/:tripId/edit/add-ons/:pnrId/legs/:legIndex/checkout',
    component: AirAddOnCheckoutContainer,
  },
  {
    key: 'flight-web-check-in',
    path: '/trips/:tripId/pnr/:pnrId/legs/:legIndex/web-check-in',
    component: AirWebCheckInContainer,
  },
  {
    key: 'flight-web-check-in-success',
    path: '/trips/:tripId/pnr/:pnrId/legs/:legIndex/web-check-in-success',
    component: AirWebCheckInConfirmationContainer,
  },
  {
    key: 'flight-retry-payment-checkout',
    path: '/trips/:tripId/flights/:pnrId/:currentLegDetailIndex/retry-payment',
    component: FlightRetryPaymentCheckout,
  },
  {
    key: 'flight-exchange-results',
    path: '/trips/:tripId/change/flights/:pnrId/:currentLegDetailIndex/results',
    component: FlightExchangeResults,
  },
  {
    key: 'flight-checkout-v2-traveler',
    path: '/flights/checkout-v2/:step(traveler|ancillary|payment)',
    component: FlightCheckoutV2,
  },
  {
    key: 'seat-edit-form',
    path: '/trips/:tripId/edit/:pnrId/legs/:legIndex/flights/:flightIndex/seat',
    component: SeatEditForm,
  },
  {
    key: 'seat-edit-checkout',
    path: '/trips/:tripId/edit/:pnrId/legs/:legIndex/flights/:flightIndex/seat/checkout',
    component: EditSeatCheckout,
  },
  {
    key: 'finalize-pnr',
    path: '/trips/:tripId/pnr/:pnrId/finalize',
    component: FinalizePnr,
  },
  {
    key: 'hard-approval',
    path: '/hardapproval',
    component: HardApproval,
  },
  {
    key: 'center-sso-login',
    path: '/authorize/center',
    component: CenterSsoLogin,
    isPublicRoute: true,
  },
  {
    key: 'booking-feedback',
    path: '/checkout/feedback',
    component: Confirmation,
    exact: true,
  },
  {
    key: 'flights-check-pnr-status',
    path: '/flights/check-pnr-status',
    component: FlightCheckPnrStatus,
  },
  {
    key: 'booking-hold-confirmation',
    path: '/trips/:tripId/booking-hold-confirmation/pnr/:pnrId',
    component: BookingHoldConfirmation,
    exact: true,
  },
  {
    key: 'confirm-hold',
    path: '/trips/:tripId/pnr/:pnrId/confirm-hold/:step(ancillary|seatmap|finalize)',
    component: ConfirmHold,
  },
  {
    key: 'task-status-document',
    path: '/documents/:id',
    component: TaskStatusDocument,
  },
  {
    key: 'preferred-language',
    path: '/preferred-language',
    component: PreferredLanguage,
  },
  {
    key: 'events',
    path: '/events',
    component: EventsPage,
    search: '/:type(upcoming|past|cancelled)?',
  },
  {
    key: 'events-create',
    path: '/events/create',
    component: CreateEventPage,
    search: '/:type(settings|participants)?',
  },
  {
    key: 'event-edit',
    path: '/events/:eventId/edit/:type(settings|participants)?',
    component: EventEditPage,
  },
  {
    key: 'events-report',
    path: '/events/:eventId/report',
    component: ReportsPage,
  },
  {
    key: 'event-trip',
    path: '/events/:eventId/my_trip',
    component: EventTripPage,
  },
  {
    key: 'support-debug-tool',
    path: '/debug-tool',
    component: DebugTool,
  },
];

export default routesConfig;

export type IRouteKeysOriginal = (typeof routesConfig)[number]['key'];

export type IRouteKeys =
  | 'HEADLESS_AUTH'
  | 'HOME'
  | 'LOGIN'
  | 'FLIGHTS'
  | 'FLIGHTS_RESULTS'
  | 'FLIGHT_CANCEL'
  | 'FLIGHT_EXCHANGE_CHECKOUT'
  | 'FLIGHT_ADD_ON_CHECKOUT'
  | 'HOTELS'
  | 'HOTELS_RESULTS'
  | 'HOTELS_DETAILS'
  | 'HOTELS_MODIFY_DETAILS'
  | 'HOTELS_MODIFY_DETAILS_CHECKOUT'
  | 'HOTELS_CHECKOUT'
  | 'CARS'
  | 'CARS_RESULTS'
  | 'CARS_CHECKOUT'
  | 'CARS_CHECKOUT_SUCCESS'
  | 'RAILS_CHECKOUT'
  | 'RAILS_CHECKOUT_SUCCESS'
  | 'PROFILE'
  | 'PROFILE_SEARCH'
  | 'PROFILE_BOOKING'
  | 'PROFILE_INFO'
  | 'PROFILE_REDIRECT'
  | 'SHELL_PNR_CREATE'
  | 'ADMIN'
  | 'TRIPS'
  | 'COMPANY_TRIPS'
  | 'TRIP_DETAIL'
  | 'SEAT_EDIT_CHECKOUT'
  | 'TERMS'
  | 'SIGNOUT'
  | 'REPORTS'
  | 'ANALYTICS'
  | 'CAR_CANCEL'
  | 'PNR_CANCEL_SUCCESS'
  | 'HOTEL_CANCEL'
  | 'LIMO_CANCEL'
  | 'LEGAL_ENTITY'
  | 'SUPPORT_TAB'
  | 'RAILS_RESULTS'
  | 'DUTY_OF_CARE'
  | 'MISC_CANCEL'
  | 'MOBILE_ADD_PAYMENT_CARD'
  | 'MOBILE_CHECKOUT_AIR'
  | 'MOBILE_PDF_PAGE'
  | 'MOBILE_PDF_PAGE_V2'
  | 'MOBILE_CHECKOUT_HOTEL'
  | 'MOBILE_FLIGHTS_CC_POST_VERIFICATION'
  | 'MOBILE_CHECKOUT'
  | 'FLIGHT_EXCHANGE_FORM'
  | 'FLIGHT-EXCHANGE-RESULTS'
  | 'FLIGHT-EXCHANGE-CHECKOUT'
  | 'FLIGHT_RETRY_PAYMENT_CHECKOUT'
  | 'FLIGHT_WEB_CHECK_IN'
  | 'FLIGHT_WEB_CHECK_IN_SUCCESS'
  | 'RAIL_CANCEL'
  | 'RAIL_MODIFY'
  | 'RAIL_MODIFY_RESULTS'
  | 'RAIL_MODIFY_SUMMARY'
  | 'RAIL_MODIFY_CHECKOUT'
  | 'RAILS'
  | 'FLIGHT_EXCHANGE_RESULTS'
  | 'FLIGHTS_CHECK_PNR_STATUS'
  | 'SEAT_EDIT_FORM'
  | 'AGENT_SEARCH'
  | 'AGENT_QUEUE_DASHBOARD'
  | 'AGENT' // TODO: remove later
  | 'AGENT_DESKTOP' // TODO: remove later
  | 'CENTER_SSO_LOGIN'
  | 'IMPORT_FLIGHT_BOOKING'
  | 'IMPORTED_FLIGHT_BOOKING_EDIT'
  | 'IMPORT_CAR_BOOKING'
  | 'IMPORTED_CAR_BOOKING_EDIT'
  | 'IMPORT_HOTEL_BOOKING'
  | 'IMPORTED_HOTEL_BOOKING_EDIT'
  | 'IMPORT_LIMO_BOOKING'
  | 'IMPORTED_LIMO_BOOKING_EDIT'
  | 'IMPORT_RAIL_BOOKING'
  | 'IMPORTED_RAIL_BOOKING_EDIT'
  | 'IMPORT_MISCELLANEOUS_BOOKING'
  | 'IMPORTED_MISCELLANEOUS_BOOKING_EDIT'
  | 'BOOKING_FEEDBACK'
  | 'BOOKING_HOLD_CONFIRMATION'
  // | 'AMADEUS_POST_VERIFICATION'
  | 'TASK_STATUS_DOCUMENT'
  | 'PREFERRED_LANGUAGE'
  | 'SPOTTERNET'
  | 'SPOTTERNET_ALL'
  | 'EVENTS'
  | 'EVENTS_CREATE'
  | 'EVENT_TRIP'
  | 'EVENT_DETAILS'
  | 'EVENT_EDIT'
  | 'SUPPORT_DEBUG_TOOL'
  | 'APPROVAL_DASHBOARD'
  | 'COMPANY_APPROVAL_DASHBOARD'
  | 'FLIGHTS_CHECKOUT_ANCILLARY'
  | 'FLIGHTS_CHECKOUT_SEATMAP'
  | 'FLIGHTS_CHECKOUT_FINALIZE'
  | 'FLIGHTS_MANUAL_FORM_SELECT_TRAVELERS'
  | 'FLIGHTS_MANUAL_FORM_UPLOAD_DOCUMENT'
  | 'FLIGHTS_MANUAL_FORM_TICKET_TRAVELER'
  | 'FLIGHTS_MANUAL_FORM_TICKET_FLIGHT'
  | 'FLIGHTS_MANUAL_FORM_ANCILLARIES_AND_ADD_ONS'
  | 'FLIGHTS_MANUAL_FORM_TICKET_FINALIZE'
  | 'FLIGHTS_MANUAL_FORM_PRICE_SUMMARY'
  | 'FLIGHTS_CANCEL_MANUAL_FORM_TICKET_TRAVELER'
  | 'FLIGHTS_CANCEL_MANUAL_FORM_TICKET_FINALIZE'
  | 'FLIGHTS_CANCEL_MANUAL_FORM_PRICE_SUMMARY'
  | 'FLIGHTS_CONFIRM_HOLD_ANCILLARY'
  | 'FLIGHTS_CONFIRM_HOLD_FINALIZE'
  | 'FLIGHTS_CHECKOUT_V2_TRAVELER'
  | 'FLIGHTS_CHECKOUT_V2_ANCILLARY'
  | 'FLIGHTS_CHECKOUT_V2_PAYMENT'
  | 'API_CLIENT'
  | 'PRIVACY_NOTICE'
  | 'EVENT_SETTINGS_CREATE'
  | 'EVENT_SETTINGS_EDIT'
  | 'EVENT_PARTICIPANTS_EDIT'
  | 'USER_ACTION_ALL_ROUTES'
  | 'EVENT_REPORT'
  | 'COMPANY_ENROLLMENT'
  | 'APPLY'
  | 'ONBOARDING';

type IRoutePaths = {
  [k in IRouteKeys]: string;
};

export const ROUTE_PATHS: IRoutePaths = routesConfig.reduce(
  (acc, route) => ({ ...acc, [snakeCase(route.key).toUpperCase()]: route.path }),
  {
    PROFILE: '/profile',
    TRIPS: '/trips',

    FLIGHTS_CHECKOUT_ANCILLARY: '/flights/checkout/ancillary',
    FLIGHTS_CHECKOUT_SEATMAP: '/flights/checkout/seatmap',
    FLIGHTS_CHECKOUT_FINALIZE: '/flights/checkout/finalize',

    FLIGHTS_CHECKOUT_V2_TRAVELER: '/flights/checkout-v2/traveler',
    FLIGHTS_CHECKOUT_V2_ANCILLARY: '/flights/checkout-v2/ancillary',
    FLIGHTS_CHECKOUT_V2_PAYMENT: '/flights/checkout-v2/payment',

    FLIGHTS_MANUAL_FORM_SELECT_TRAVELERS: '/trips/:tripId/import/flights/:flow/select-travelers',
    FLIGHTS_MANUAL_FORM_UPLOAD_DOCUMENT: '/trips/:tripId/import/flights/:flow/upload-document',
    FLIGHTS_MANUAL_FORM_TICKET_TRAVELER: '/trips/:tripId/import/flights/:flow/ticket-traveler-details',
    FLIGHTS_MANUAL_FORM_TICKET_FLIGHT: '/trips/:tripId/import/flights/:flow/flight-details',
    FLIGHTS_MANUAL_FORM_ANCILLARIES_AND_ADD_ONS: '/trips/:tripId/import/flights/:flow/ancillaries-and-add-ons',
    FLIGHTS_MANUAL_FORM_PRICE_SUMMARY: '/trips/:tripId/import/flights/:flow/price-summary',
    FLIGHTS_MANUAL_FORM_TICKET_FINALIZE: '/trips/:tripId/import/flights/:flow/finalize',

    FLIGHTS_CANCEL_MANUAL_FORM_TICKET_TRAVELER: '/trips/:tripId/cancel/flights/:flow/ticket-traveler-details',
    FLIGHTS_CANCEL_MANUAL_FORM_PRICE_SUMMARY: '/trips/:tripId/cancel/flights/:flow/price-summary',
    FLIGHTS_CANCEL_MANUAL_FORM_TICKET_FINALIZE: '/trips/:tripId/cancel/flights/:flow/finalize',

    FLIGHTS_CONFIRM_HOLD_ANCILLARY: '/trips/:tripId/pnr/:pnrId/confirm-hold/ancillary',
    FLIGHTS_CONFIRM_HOLD_FINALIZE: '/trips/:tripId/pnr/:pnrId/confirm-hold/finalize',

    EVENT_SETTINGS_CREATE: '/events/create/settings',
    EVENT_SETTINGS_EDIT: '/events/:eventId/edit/settings',
    EVENT_PARTICIPANTS_EDIT: '/events/:eventId/edit/participants',
    EVENT_REPORT: '/events/:eventId/report',
    SPOTTERNET_ALL: '/spotternet*',
    USER_ACTION_ALL_ROUTES,
  } as IRoutePaths,
);
