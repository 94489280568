import first from 'lodash/first';
import last from 'lodash/last';
import { RailDeliveryOption } from '../../../types/api/v2/obt/model/rail-delivery-option';
import { defineCommonMessage } from '../../../translations/defineMessage';
import getDateTimeDiff from '../../../date-utils/getDateTimeDiff';
import minutesToDurationString from '../../../date-utils/minutesToDurationString';
import { ETicketMetadataTypeEnum } from '../../../types/api/v2/obt/model/eticket-metadata';
import type { Rail } from '../../../types/api/v2/obt/model/rail';
import { RailSearchType } from '../../../types/api/v2/obt/model/rail-search-type';
import { getNameStringFromName } from '../../../utils/common';
import { MoneyUtil } from '../../../utils/Money';
import type { PnrV3ManagerProps } from '../PnrV3Manager';
import { PnrV3Manager } from '../PnrV3Manager';
import type { Fare } from './types';

export class RailPnrV3Manager extends PnrV3Manager {
  railPnr?: Rail;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    super({ pnrData, pnrId });
    this.railPnr = this.pnrData.railPnr;
  }

  public railDetails() {
    const legs = this.railPnr?.legInfos ?? [];
    const firstLeg = first(legs);
    const lastLeg = last(legs);

    const isOpenReturn = this.railPnr?.type === RailSearchType.OpenReturn;

    const outwardJourneyFirstLeg = this.railPnr?.legInfos[this.railPnr?.outwardJourney.legs[0] || 0];
    const outwardJourneyLastLeg = this.railPnr?.legInfos[this.railPnr?.outwardJourney.legs.length - 1 || 0];
    const outwardJourneyInfo = {
      originName: outwardJourneyFirstLeg?.originInfo?.name,
      destinationName: outwardJourneyLastLeg?.destinationInfo?.name,
      duration: minutesToDurationString(
        getDateTimeDiff(
          outwardJourneyLastLeg?.arriveAtLocal?.iso8601 ?? '',
          outwardJourneyFirstLeg?.departAtLocal?.iso8601 ?? '',
        ),
      ),
      legIndices: this.railPnr?.outwardJourney.legs,
    };

    const inwardJourneyFirstLegIndex = this.railPnr?.inwardJourney?.legs[0];
    const inwardJourneyFirstLeg = inwardJourneyFirstLegIndex
      ? this.railPnr?.legInfos[inwardJourneyFirstLegIndex]
      : undefined;

    const inwardJourneyInfo = {
      originName: inwardJourneyFirstLeg?.originInfo?.name,
      destinationName: lastLeg?.destinationInfo?.name,
      duration: minutesToDurationString(
        getDateTimeDiff(lastLeg?.arriveAtLocal?.iso8601 ?? '', inwardJourneyFirstLeg?.departAtLocal?.iso8601 ?? ''),
      ),
      legIndices: this.railPnr?.inwardJourney?.legs,
    };
    const hasInwardJourney = !!inwardJourneyFirstLeg;

    const railDetails = legs.map((leg, legIdx) => {
      const passengers = this.pnrData?.pnrTravelers?.map((pnrTraveler) => ({
        name: pnrTraveler.personalInfo?.name,
        id: pnrTraveler.userId.id,
      }));

      const passengerDetails =
        passengers?.map((passenger) => {
          const requiredTravelerInfo = leg.travelerRailInfo?.find(
            (travelerInfo) => passenger.id === travelerInfo.userId?.id,
          );

          const requiredAllocatedSpace = leg.allocatedSpaces?.find((space) => space.userId?.id === passenger?.id);
          return {
            name: getNameStringFromName(passenger?.name),
            preferredName: getNameStringFromName(passenger?.name, { usePreferredName: true }),
            loyaltyNumber: requiredTravelerInfo?.loyaltyDetails?.loyaltyNumber,
            loyaltyIssuedBy: requiredTravelerInfo?.loyaltyDetails?.loyaltyProgram,
            cardNumber: requiredTravelerInfo?.railCard?.cardNumber,
            cardName: requiredTravelerInfo?.railCard?.name,
            seatNumber: requiredAllocatedSpace?.seatNumber,
            coachNumber: requiredAllocatedSpace?.coachNumber,
          };
        }) ?? [];

      const layover =
        legIdx < legs.length - 1
          ? {
              code: legs[legIdx + 1].originInfo?.sourceRefInfos?.find((info) => info.inventoryName === 'Amtrak')
                ? legs[legIdx + 1].originInfo?.code
                : '',
              city: legs[legIdx + 1].originInfo?.cityName ?? '',
              duration: minutesToDurationString(
                getDateTimeDiff(legs[legIdx + 1].departAtLocal?.iso8601 ?? '', leg?.arriveAtLocal?.iso8601 ?? ''),
              ),
              isTrainChange: leg.vehicle.carrierName !== legs[legIdx + 1].vehicle.carrierName,
            }
          : undefined;

      const co2EmissionsInKg =
        leg.co2EmissionGramsPerPassenger && leg.co2EmissionGramsPerPassenger > 0
          ? leg.co2EmissionGramsPerPassenger / 1000
          : 0;
      const collectionReference =
        this.railPnr?.ticketDetails &&
        this.railPnr.ticketDetails.length > legIdx &&
        this.railPnr.ticketDetails[legIdx]?.deliveryOption === RailDeliveryOption.Kiosk
          ? this.railPnr.ticketDetails[legIdx].reference
          : undefined;

      const carrierConfirmation = first(first(this.railPnr?.sections)?.vendorConfirmations)?.vendorConfirmationId;

      const singleRailDetails = {
        legId: leg.legId,
        railwayDetails: {
          carrierName: leg.vehicle.carrierName,
          timetableId: leg.vehicle.timetableId,
          carrierConfirmation,
          bookingId: this.pnrId,
          fareType: leg.fareType,
          travelClass: leg.travelClass,
          coach: passengerDetails[0].coachNumber,
          seat: passengerDetails[0].seatNumber,
          railCard: passengerDetails[0].cardName,
          co2Emissions: co2EmissionsInKg,
          collectionReference,
          sourceConfirmation: this.pnrData.sourceInfo?.sourcePnrId,
        },
        origin: {
          date: leg?.departAtLocal,
          stationCode: leg.originInfo?.sourceRefInfos?.find((info) => info.inventoryName === 'Amtrak')
            ? leg.originInfo?.code
            : '',
          city: leg.originInfo?.cityName,
          name: leg.originInfo?.name,
          countryCode: leg.originInfo?.countryCode,
        },
        destination: {
          date: leg?.arriveAtLocal,
          stationCode: leg.destinationInfo?.sourceRefInfos?.find((info) => info.inventoryName === 'Amtrak')
            ? leg.destinationInfo?.code
            : '',
          city: leg.destinationInfo?.cityName,
          name: leg.destinationInfo?.name,
          countryCode: leg.destinationInfo?.countryCode,
        },
        duration: minutesToDurationString(
          getDateTimeDiff(leg?.arriveAtLocal?.iso8601 ?? '', leg?.departAtLocal?.iso8601 ?? ''),
        ),
        layover,
        passengerDetails,
        vendorName: leg.vendorName,
      };
      return singleRailDetails;
    });

    const totalDuration = minutesToDurationString(
      getDateTimeDiff(
        outwardJourneyLastLeg?.arriveAtLocal?.iso8601 ?? '',
        outwardJourneyFirstLeg?.departAtLocal?.iso8601 ?? '',
      ) + getDateTimeDiff(lastLeg?.arriveAtLocal?.iso8601 ?? '', inwardJourneyFirstLeg?.departAtLocal?.iso8601 ?? ''),
    );
    const travelerDetails = super.travelerDetails();

    const totalCo2Emissions = this.getTotalCo2EmissionsInKg();
    return {
      startDate: firstLeg?.departAtLocal,
      endDate: lastLeg?.arriveAtLocal,
      railDetails,
      travelerDetails,
      sourceConfirmation: this.pnrData.sourceInfo?.sourcePnrId,
      isOpenReturn,
      outwardJourneyInfo,
      inwardJourneyInfo,
      hasInwardJourney,
      duration: totalDuration,
      totalCo2Emissions,
    };
  }

  public previousRailDetails() {
    const previousItinerary = this.railPnr?.previousItinerary;
    const exchangeInfo = this.railPnr?.exchangeInfo;

    /**
     * TODO: expand this function to include all previous itinerary details that are contained in OpenAPI's RailPnrPreviousItinerary.
     * Currently this function only returns the sourceConfirmation, exchangeType, searchType, deliveryOption, and rate to avoid build errors.
     * See obt/model/rail-pnr-previous-itinerary.ts for more details.
     */

    return {
      sourceConfirmation: previousItinerary?.sourceReference,
      exchangeType: exchangeInfo?.exchangeType,
      searchType: previousItinerary?.type,
      deliveryOption: previousItinerary?.deliveryOption,
      rate: previousItinerary?.rate,
    };
  }

  public getTotalCo2EmissionsInKg() {
    const outwardCo2 = this.railPnr?.outwardJourney.co2EmissionDetails?.co2EmissionKilograms ?? 0;
    const inwardCo2 = this.railPnr?.inwardJourney?.co2EmissionDetails?.co2EmissionKilograms ?? 0;
    const inStr = inwardCo2 !== 0 ? inwardCo2.toFixed(1) : '0';
    const outStr = outwardCo2 !== 0 ? outwardCo2.toFixed(1) : '0';
    const totalCo2Emissions = Number(inStr) + Number(outStr);
    return totalCo2Emissions;
  }

  public splitFaresPerPax() {
    const numTravelers = this.pnrData?.pnrTravelers?.length || 1;
    const fares: Fare[] = [];
    const { baseAmount, taxAmount } = super.paymentInfo();
    const splitBaseAmount = baseAmount.divide(numTravelers);
    const splitTaxAmount = taxAmount.divide(numTravelers);
    for (let i = 0; i < numTravelers; i += 1) {
      fares.push({ base: splitBaseAmount, tax: splitTaxAmount });
    }
    return fares;
  }

  public paymentInfo() {
    const transactionDate = first(this.pnrData?.bookingHistory)?.bookingInfo?.updatedDateTime;
    const isDelayedInvoicing = this.pnrData?.invoiceDelayedBooking || false;
    const refundAmount = this.railPnr?.rate?.refundInfo?.refundAmount;
    const refundAmountTotal = MoneyUtil.convertV2MoneyToMoneyUtil(refundAmount?.base).add(
      MoneyUtil.convertV2MoneyToMoneyUtil(refundAmount?.tax),
    );
    const voucherAmount = MoneyUtil.convertV2MoneyToMoneyUtil(this.railPnr?.rate?.refundInfo?.refundVoucher?.amount);
    const cancelationFeeAmount = this.railPnr?.rate?.refundInfo?.penalty;
    const cancelationFee = MoneyUtil.convertV2MoneyToMoneyUtil(cancelationFeeAmount?.base).add(
      MoneyUtil.convertV2MoneyToMoneyUtil(cancelationFeeAmount?.tax),
    );

    const { paymentMethods, ...additionalPaymentInfo } = super.paymentInfo();
    const collectionReferenceId =
      this.pnrData.railPnr?.ticketDetails?.find(
        (ticket) => ticket.eticketMetadata?.type === ETicketMetadataTypeEnum.CollectionNumber,
      )?.reference ?? '';
    const paymentCard = paymentMethods.find((payment) => !!payment.name)?.name;
    const travelerNames = this.travelerDetails()?.map((traveler) => traveler.name) || [];

    const fares = this.splitFaresPerPax();

    return {
      ...additionalPaymentInfo,
      paymentMethods,
      transactionDate,
      isDelayedInvoicing,
      refundAmount: refundAmountTotal,
      voucherAmount,
      cancelationFee,
      collectionReferenceId,
      paymentCard,
      travelerNames,
      fares,
    };
  }

  public termsAndConditions() {
    return first(this.pnrData.railPnr?.termsAndConditions?.conditions);
  }

  public pnrTitle() {
    const { inwardJourneyInfo, outwardJourneyInfo } = this.railDetails();
    const journey = inwardJourneyInfo ?? outwardJourneyInfo;

    const pnrTitle = defineCommonMessage('Train to {{destination}}');
    pnrTitle.values = {
      destination: journey.destinationName ?? '',
    };
    return pnrTitle;
  }
}
