import { dateFormats, defaultHotelFilters } from '../constants';
import { dateUtil, dateUtilFormat, isBefore } from '../date-utils';
import type { ICommonI18nMessage } from '../translations/defineMessage';
import { defineCommonMessage } from '../translations/defineMessage';
import { localizeDateTime } from '../translations/localizers';
import type { CancellationPolicy1, Car, Flight, HotelModifyDetailsURLParams, IOccupancy } from '../types';
import { BedType, CancellationPolicy1PolicyEnum, FareTypeEnum, RateTypeV1 } from '../types';
import type { DataFlight } from '../types/api/v1/obt/air/air_search_response';
import type { ImageSet } from '../types/api/v1/obt/hotel/hotel_common';
import { FeeType } from '../types/api/v1/obt/hotel/hotel_common';
import { MoneyUtil } from './Money';
import { sentenceCase, underScoreToSpace } from './common';

const [DEFAULT_MIN_RATE, DEFAULT_MAX_RATE] = defaultHotelFilters.rates;

export const convertRateTypeV1ToFareTypeEnum = (rateType: RateTypeV1): FareTypeEnum => {
  switch (rateType) {
    case RateTypeV1.PUBLISHED:
      return FareTypeEnum.PUBLISHED;
    case RateTypeV1.CORPORATE:
      return FareTypeEnum.CORPORATE;
    case RateTypeV1.SPOTNANA:
      return FareTypeEnum.PRIVATE;
    default:
      return FareTypeEnum.UNRECOGNIZED;
  }
};

export type RateInputType = 'min' | 'max';

export const correctRates = (values: [number, number], updatedInput: RateInputType): [number, number] => {
  const [min, max] = values;
  if (min <= max) {
    return values;
  }
  if (updatedInput === 'min') {
    return [DEFAULT_MIN_RATE, max];
  }
  return [min, DEFAULT_MAX_RATE];
};

export const getOriginalOccupancy = (
  occupancyDetails: HotelModifyDetailsURLParams['originalReservation']['occupancyDetails'],
): IOccupancy => {
  const { checkInDate, checkOutDate, checkInTime, checkOutTime } = occupancyDetails;
  return {
    checkInDate,
    checkOutDate,
    checkInTime,
    checkOutTime,
    checkInDateTime: `${checkInDate} ${checkInTime}`,
    checkOutDateTime: `${checkOutDate} ${checkOutTime}`,
  };
};

export const getBedTypeLabel = (bedType: BedType): string => {
  switch (bedType) {
    case BedType.UnknownBedType:
      return 'Assigned at hotel';
    case BedType.TatamiMats:
    case BedType.RunOfTheHouse:
      return sentenceCase(underScoreToSpace(bedType));
    default:
      return sentenceCase(`${underScoreToSpace(bedType)} bed`);
  }
};

export const getHotelCancellationPolicyText = (cancellationPolicy: CancellationPolicy1): string => {
  const { deadline, policy, amount } = cancellationPolicy;
  const money = MoneyUtil.convertV2MoneyToMoneyUtil(amount);
  const currentDate = new Date().toISOString();
  const hasFreeCancellationDeadlinePassed = isBefore(deadline?.iso8601 ?? '', currentDate, dateFormats.ISO);
  const formattedDeadlineDate = localizeDateTime(deadline?.iso8601, {
    dateStyle: 'medium',
    timeStyle: 'short',
  });

  switch (policy) {
    case CancellationPolicy1PolicyEnum.NonRefundable:
      return 'Non refundable';
    case CancellationPolicy1PolicyEnum.FreeCancellationUntil:
      if (hasFreeCancellationDeadlinePassed) {
        return `Cancellation window expired ${formattedDeadlineDate} local time: No changes or refunds`;
      }
      return `Free cancellation - Until ${formattedDeadlineDate} local time`;
    case CancellationPolicy1PolicyEnum.PartiallyRefundable:
      return `Refundable for ${money.getDisplayAmount()} ${money.getCurrency()}`;
    default:
      return '';
  }
};

export const getImageUrlAboveThresholdWidth = ({
  imageSet,
  threshold,
}: {
  imageSet: ImageSet | undefined;
  threshold: number;
}): string => {
  const images = imageSet?.imageGroup?.images ?? [];
  if (images.length === 0) {
    return '';
  }

  // Spreading because we need to copy the array before sorting it
  const sortedImagesWithDimensions = [...images].sort(
    (img1, img2) => (img1.dimensions?.width ?? 0) - (img2.dimensions?.width ?? 0),
  );

  const imageWithDimensionsGreaterThanThreshold = sortedImagesWithDimensions.find(
    (imageInfo) => (imageInfo.dimensions?.width ?? 0) >= threshold,
  );

  const imageWithLargestAvailableDimensions = sortedImagesWithDimensions[sortedImagesWithDimensions.length - 1];

  return (imageWithDimensionsGreaterThanThreshold ?? imageWithLargestAvailableDimensions).url ?? '';
};

export const processedHotelSearchDateParams = (
  firstFlightAirLeg: Flight | DataFlight | undefined,
  secondFlightAirLeg?: Flight | DataFlight | undefined,
  firstCarPnr?: Car | undefined,
) => {
  let startDate;
  let endDate;

  if (firstFlightAirLeg) {
    startDate = dateUtilFormat(firstFlightAirLeg.arrivalDateTime.iso8601, dateFormats.ISO);
  } else if (firstCarPnr) {
    startDate = dateUtilFormat(firstCarPnr.pickupDateTime.iso8601, dateFormats.ISO);
  }

  if (secondFlightAirLeg) {
    endDate = dateUtilFormat(secondFlightAirLeg.departureDateTime.iso8601, dateFormats.ISO);
  } else if (firstFlightAirLeg) {
    endDate = dateUtilFormat(dateUtil(startDate).add(1, 'day'), dateFormats.ISO);
  } else if (firstCarPnr) {
    endDate = dateUtilFormat(firstCarPnr.dropOffDateTime.iso8601, dateFormats.ISO);
  }

  return {
    startDate,
    endDate,
  };
};

export const feeTypeTitleMapper: Record<FeeType, ICommonI18nMessage<{}>> = {
  [FeeType.BED_TAX]: defineCommonMessage('Bed Tax'),
  [FeeType.CITY_HOTEL_FEE]: defineCommonMessage('City Hotel Fee'),
  [FeeType.CITY_TAX]: defineCommonMessage('City Tax'),
  [FeeType.COUNTRY_TAX]: defineCommonMessage('Country Tax'),
  [FeeType.ENERGY_TAX]: defineCommonMessage('Energy Tax'),
  [FeeType.FEDERAL_TAX]: defineCommonMessage('Federal Tax'),
  [FeeType.FOOD_AND_BEVERAGE_TAX]: defineCommonMessage('Food and Beverage Tax'),
  [FeeType.LODGING_TAX]: defineCommonMessage('Lodging Tax'),
  [FeeType.MAINTENANCE_FEE]: defineCommonMessage('Maintenance Fee'),
  [FeeType.OCCUPANCY_TAX]: defineCommonMessage('Occupancy Tax'),
  [FeeType.PACKAGE_FEE]: defineCommonMessage('Package Fee'),
  [FeeType.RESORT_FEE]: defineCommonMessage('Resort Fee'),
  [FeeType.SALES_TAX]: defineCommonMessage('Sales Tax'),
  [FeeType.SERVICE_CHARGE]: defineCommonMessage('Service Charge'),
  [FeeType.STATE_TAX]: defineCommonMessage('State Tax'),
  [FeeType.SURCHARGE]: defineCommonMessage('Surcharge'),
  [FeeType.TOTAL_TAX]: defineCommonMessage('Total Tax'),
  [FeeType.TOURISM_TAX]: defineCommonMessage('Tourism Tax'),
  [FeeType.VAT_GST_TAX]: defineCommonMessage('VAT/GST Tax'),
  [FeeType.SURPLUS_LINES_TAX]: defineCommonMessage('Surplus Lines Tax'),
  [FeeType.INSURANCE_PREMIUM_TAX]: defineCommonMessage('Insurance Premium Tax'),
  [FeeType.APPLICATON_FEE]: defineCommonMessage('Application Fee'),
  [FeeType.EXPRESS_HANDLING_FEE]: defineCommonMessage('Express Handling Fee'),
  [FeeType.EXEMPT]: defineCommonMessage('Exempt Tax'),
  [FeeType.STANDARD]: defineCommonMessage('Standard Fee'),
  [FeeType.ZERO_RATED]: defineCommonMessage('Zero Rated Tax'),
  [FeeType.MISCELLANEOUS]: defineCommonMessage('Miscellaneous Fee'),
  [FeeType.ROOM_TAX]: defineCommonMessage('Room Tax'),
  [FeeType.EARLY_CHECKOUT_FEE]: defineCommonMessage('Early Checkout Fee'),
  [FeeType.COUNTRY_TAXES]: defineCommonMessage('Country Taxes'),
  [FeeType.EXTRA_PERSON_CHARGE]: defineCommonMessage('Extra Person Charge'),
  [FeeType.BANQUET_SERVICE_FEE]: defineCommonMessage('Banquet Service Fee'),
  [FeeType.ROOM_SERVICE_FEE]: defineCommonMessage('Room Service Fee'),
  [FeeType.LOCAL_FEE]: defineCommonMessage('Local Fee'),
  [FeeType.GOODS_AND_SERVICES_TAX]: defineCommonMessage('Goods and Services Tax'),
  [FeeType.VALUE_ADDED_TAX]: defineCommonMessage('Value Added Tax'),
  [FeeType.CRIB_FEE]: defineCommonMessage('Crib Fee'),
  [FeeType.ROLLAWAY_FEE]: defineCommonMessage('Rollaway Fee'),
  [FeeType.ASSESSMENT_LICENSE_TAX]: defineCommonMessage('Assessment License Tax'),
  [FeeType.PET_SANITATION_FEE]: defineCommonMessage('Pet Sanitation Fee'),
  [FeeType.CHILD_ROLLAWAY_CHARGE]: defineCommonMessage('Child Rollaway Charge'),
  [FeeType.CONVENTION_TAX]: defineCommonMessage('Convention Tax'),
  [FeeType.EXTRA_CHILD_CHARGE]: defineCommonMessage('Extra Child Charge'),
  [FeeType.STANDARD_FOOD_AND_BEVERAGE_GRATUITY]: defineCommonMessage('Standard Food and Beverage Gratuity'),
  [FeeType.NATIONAL_GOVERNMENT_TAX]: defineCommonMessage('National Government Tax'),
  [FeeType.ADULT_ROLLAWAY_FEE]: defineCommonMessage('Adult Rollaway Fee'),
  [FeeType.BEVERAGE_WITH_ALCOHOL]: defineCommonMessage('Beverage With Alcohol Charges'),
  [FeeType.BEVERAGE_WITHOUT_ALCOHOL]: defineCommonMessage('Beverage Without Alcohol Charges'),
  [FeeType.TOBACCO]: defineCommonMessage('Tobacco Charges'),
  [FeeType.FOOD]: defineCommonMessage('Food Charges'),
  [FeeType.TOTAL_SURCHARGES]: defineCommonMessage('Total Surcharges'),
  [FeeType.STATE_COST_RECOVERY_FEE]: defineCommonMessage('State Cost Recovery Fee'),
  [FeeType.MISCELLANEOUS_FEE]: defineCommonMessage('Miscellaneous Fee'),
  [FeeType.DESTINATION_AMENITY_FEE]: defineCommonMessage('Destination Fees'),
  [FeeType.REFUNDABLE_PET_FEE]: defineCommonMessage('Refundable Pet Fee'),
  [FeeType.CHARITY_SUPPORT_FEE]: defineCommonMessage('Charity Support Fee'),
  [FeeType.LOCAL_AMENITY_USAGE_MAINTENANCE_FEE]: defineCommonMessage('Local Amenity Usage Maintenance Fee'),
  [FeeType.CONVENTION_TOURISM_FEE]: defineCommonMessage('Convention Tourism Fee'),
  [FeeType.DESTINATION_MARKETING_FEE]: defineCommonMessage('Destination Marketing Fee'),
  [FeeType.HOTEL_DEVELOPMENT_FEE]: defineCommonMessage('Hotel Development Fee'),
  [FeeType.EVENT_FEE]: defineCommonMessage('Event Fee'),
  [FeeType.SUSTAINABILITY_FEE]: defineCommonMessage('Sustainability Fee'),
  [FeeType.TRANSPORTATION_TRANSFER_FEE]: defineCommonMessage('Transportation Transfer Fee'),
  [FeeType.INSURANCE_FEE]: defineCommonMessage('Insurance Fee'),
  [FeeType.LOCAL_GOVERNMENT_FEE]: defineCommonMessage('Local Government Fee'),
  [FeeType.LOCAL_ORDINANCE_SURCHARGE]: defineCommonMessage('Local Ordinance Surcharge'),
  [FeeType.GUARANTEED_EARLY_CHECK_IN_FEE]: defineCommonMessage('Guaranteed Early Check In Fee'),
  [FeeType.GUARANTEED_LATE_CHECK_OUT_FEE]: defineCommonMessage('Guaranteed Late Check Out Fee'),

  [FeeType.UNRECOGNIZED]: defineCommonMessage('Other Fees'),
  [FeeType.UNKNOWN]: defineCommonMessage('Other Fees'),
  [FeeType.NOT_KNOWN]: defineCommonMessage('Other Fees'),
};
