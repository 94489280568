import first from 'lodash/first';
import type {
  HotelSearchResponse,
  HotelSearchResponseHotelInfo,
  HotelSearchResponseHotelSearchRateInfo,
} from '../types/api/v1/obt/hotel/hotel_search_response';
import type {
  AvailableHotelChainsFilterOption,
  IHotelSummary,
  MandatoryFees,
  MandatoryFeesBreakdown,
} from '../types/hotel';
import type {
  HotelSpec,
  OccupancyDateParamsOccupancy,
  Rate,
  RateStatistics,
} from '../types/api/v1/obt/hotel/hotel_common';
import { FeeType, HotelAmenityType, HotelSpecStarRatingType } from '../types/api/v1/obt/hotel/hotel_common';
import type { Money as MoneyAPI } from '../types/api/v1/common/money';
import { feeTypeTitleMapper, getImageUrlAboveThresholdWidth, getVendorPreferences, MoneyUtil } from '../utils';
import { getDateDiff } from '../date-utils';
import { CancellationPolicyRefundableEnum } from '../types/api/v1/obt/hotel/hotel_details_response';

export default class HotelSearchResponseManager {
  private readonly data: HotelSearchResponse;

  constructor(responseData: HotelSearchResponse) {
    if (!responseData) {
      throw new Error('Invalid data passed to HotelSearchResponseManager');
    }
    this.data = { ...responseData };
  }

  public getAvailableHotelChainsFilterOptions(): AvailableHotelChainsFilterOption[] {
    return this.data.metadata?.availableHotelChains ?? [];
  }

  static getTotalAmount(money: MoneyAPI | undefined): MoneyUtil {
    return MoneyUtil.parse(money);
  }

  static getTotalNights(checkInDate: string, checkOutDate: string): number {
    return getDateDiff(checkInDate, checkOutDate);
  }

  public static getTotalFromRate = (rate: Rate): MoneyUtil => {
    const baseRate = rate.base ? MoneyUtil.parse(rate.base) : MoneyUtil.zeroMoney();
    const tax = rate.tax ? MoneyUtil.parse(rate.tax) : MoneyUtil.zeroMoney();

    let fees = MoneyUtil.zeroMoney();
    rate.fees?.forEach((feeValue) => {
      if (feeValue.amount) {
        fees = fees.add(MoneyUtil.parse(feeValue.amount));
      }
    });

    return baseRate.add(tax).add(fees);
  };

  static getAverageNightlyRate(averageNightlyFare: Rate): MoneyUtil {
    return this.getTotalFromRate(averageNightlyFare);
  }

  private static getHotelThumbnail(hotelSpec: HotelSpec | undefined): string {
    return getImageUrlAboveThresholdWidth({
      imageSet: first(hotelSpec?.imageSets),
      threshold: 512,
    });
  }

  private static getMandatoryFees(rateInfo: HotelSearchResponseHotelSearchRateInfo | undefined): MandatoryFees {
    const breakdown: MandatoryFeesBreakdown[] = [];
    let total = MoneyUtil.zeroMoney();
    rateInfo?.averageNightlyRate?.fees.forEach((fee) => {
      if (fee.displayFee) {
        const feeType = feeTypeTitleMapper[fee.type] ?? feeTypeTitleMapper[FeeType.UNKNOWN];
        total = total.add(MoneyUtil.parse(fee.amount));
        breakdown.push({
          title: feeType,
          feeInclusions: fee.feeInclusions,
          amount: MoneyUtil.parse(fee.amount),
        });
      }
    });

    return {
      total,
      breakdown,
    };
  }

  public getHotelsSummary(checkInDate: string, checkOutDate: string): IHotelSummary[] {
    return this.data.hotels.map((hotel: HotelSearchResponseHotelInfo) => {
      const {
        hotelDetailsKey,
        hotelSpec,
        total,
        distance,
        averageNightlyFare,
        hasNegotiatedRates,
        preferences,
        co2EmissionDetail,
        rateInfo,
      } = hotel;

      const totalAmount = HotelSearchResponseManager.getTotalAmount(total);
      const imageUrl = HotelSearchResponseManager.getHotelThumbnail(hotelSpec);
      const mandatoryFees = HotelSearchResponseManager.getMandatoryFees(rateInfo);

      return {
        id: hotelDetailsKey ?? '',
        title: hotelSpec?.name ?? '',
        rating: hotelSpec?.starRating ?? 1,
        ratingType: hotelSpec?.starRatingType ?? HotelSpecStarRatingType.OFFICIAL,
        address: hotelSpec?.address,
        amenities: hotelSpec?.amenities.slice(0, 4).map(({ type }) => HotelAmenityType[type]) ?? [''],
        totalAmount,
        totalNights: HotelSearchResponseManager.getTotalNights(checkInDate, checkOutDate),
        avgNightlyRate: averageNightlyFare
          ? HotelSearchResponseManager.getAverageNightlyRate(averageNightlyFare)
          : MoneyUtil.zeroMoney(),
        // averageNightlyFare is the v2 version where we get Base and Total prices for hotels (type: Rate)
        averageNightlyFare,
        position: {
          lat: hotelSpec?.coordinates?.latitude ?? 0,
          lng: hotelSpec?.coordinates?.longitude ?? 0,
        },
        imageUrl,
        distance,
        policyViolationMessage: hotel?.policyInfo?.violationMessage ?? '',
        policyInfo: hotel?.policyInfo,
        preferredType: getVendorPreferences(hotel?.preferredType, preferences),
        hasNegotiatedRates,
        preferences: preferences ?? [],
        isRefundable: hotel?.rateInfo?.refundable === CancellationPolicyRefundableEnum.TRUE,
        promotionalOffers: hotel.rateInfo?.promotionalOffers ?? [],
        rewardPointsEarned: hotel.rateInfo?.rewardPointsEarned ?? [],
        contactInfo: hotelSpec?.contactInfo ?? undefined,
        co2EmissionsValue: co2EmissionDetail?.co2EmissionValue,
        mandatoryFees,
      };
    });
  }

  public getShowOnlyBaseFare(): boolean {
    return this.data.showOnlyBaseFare ?? false;
  }

  public getHotelRateStatistics(): RateStatistics | undefined {
    return this.data.rateStatistics;
  }

  static getTotalTravelers(occupancy: OccupancyDateParamsOccupancy[] | undefined) {
    let totalTravelers = 0;

    if (!occupancy) {
      return totalTravelers;
    }

    occupancy?.forEach(({ numAdults = 0, numChildren = 0, numInfants = 0 }) => {
      totalTravelers += numAdults + numChildren + numInfants;
    });

    return totalTravelers;
  }
}
