import first from 'lodash/first';
import nth from 'lodash/nth';
import produce from 'immer';

import type { Dayjs } from 'dayjs';
import type { Moment } from 'moment';
import moment from 'moment';
import set from 'lodash/set';
import lowerCase from 'lodash/lowerCase';

import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import type { IconName } from '@spotnana/blocks/src/Icon/types';
import type { IAutocompleteSuggestion, IRailSuggestion } from '../types/autocomplete';
import {
  emptyRailSegmentLocation,
  IRailTimeTypeEnum,
  railAlternativesSortOrder,
  railTranslationKeys,
  sortedAvailableTravelClasses as sortedAvailableTravelClassesForTrainline,
  sortedAvailableTravelClassesForAmtrak,
  UK_RAIL_BOOKING_VENDOR_NAME,
  US_RAIL_BOOKING_VENDOR_NAME,
  viaFilterEmptyState,
} from '../constants/rails';
import type {
  IAncillary,
  IItineraryDetails,
  IListRailCardsResponse,
  IProfileRailCard,
  IRailCard,
  IRailLeg,
  IRailLegInfo,
  IRailOverlayAndDuration,
  IRailSearchRequest,
  IRailSearchSegmentErrorState,
  IRailSearchSegmentState,
  IRailSearchType,
  IRailSearchUrlParams,
  IRailSegment,
  IRateInfo,
  ISectionAlternative,
  ISectionTravelClassAlternativesCreateMethod,
  ITravelCard,
  RailAmenityV1,
  FareDetailsModalSectionInfo,
  SeatPreferenceOptions,
  IGetSeatPreferencesOptions,
  SplitTicketDetailsModalSectionInfo,
  IRailModifySearchRequest,
  IRailSearchNavigationRequest,
  RailAdditionalTraveler,
  IRailcardLoyaltyListItem,
} from '../types/rail';
import { IViaFilterType, RailVehicleType } from '../types/rail';
import type { ITraveler } from '../types/traveler';
import { MoneyUtil } from '../utils/Money';
import {
  convertDateFormat,
  dateUtil,
  dateUtilFormat,
  getDateTimeDiff,
  getDurationMinutes,
  minutesToDurationString,
} from '../date-utils';
import { dateFormats, timeFormats } from '../constants/common';
import type { RailSearchInwardRequest, RailSearchOutwardRequest } from '../types/api/v1/obt/rail/rail_search_request';
import type {
  Ancillary,
  AppliedPromotion,
  FareInfo,
  ItineraryDetails,
  JourneyInfo,
  LegInfo,
  RateInfo,
  SectionInfo,
  Vehicle,
} from '../types/api/v1/obt/rail/rail_common';
import {
  RailThirdParty as V1RailThirdParty,
  SearchType,
  TravelClass,
  FareComposition,
  VehicleType,
  DeliveryOption,
  StationType,
  RailAmenityRailAmenityType,
  RailExchangeType,
  PassengerType,
} from '../types/api/v1/obt/rail/rail_common';
import type { IBreadcrumb } from '../types/flight';
import { RailThirdParty } from '../types/api/v2/obt/model/rail-third-party';
import { joinTruthyValues } from '../utils/common';
import { getCountryName } from '../constants/countryList';
import { BookingFlowEnum } from '../types';
import { getIsAmtrakLeg, getIsAmtrakLegInfo } from '../utils/Rail/getIsAmtrakLeg';
import { defineMessage } from '../translations/defineMessage';
import type { RailSearchResponse as RailSearchResponseV1 } from '../types/api/v1/obt/rail/rail_search_response';
import type { RailSearchResponse as RailSearchResponseV2 } from '../types/api/v2/obt/model/rail-search-response';
import { PaymentV2ToV1Mapper } from '../types/api/v2/obt/model/payment-method';
import { SectionAlternativeCategory } from '../types/api/v1/obt/rail/rail_search_response';
import type { RailModifySearchRequest } from '../types/api/v2/obt/model/rail-modify-search-request';
import { RailExchangeType as RailExchangeTypeV2 } from '../types/api/v2/obt/model/rail-exchange-type';

export const isRailSuggestion = (suggestion?: IAutocompleteSuggestion | null): suggestion is IRailSuggestion =>
  (suggestion as IRailSuggestion)?.stationReferenceId !== undefined;

export const getRailAutocompleteOptionLabelV2 = (option: IRailSuggestion): string => {
  const countryName = option.countryCode ? getCountryName(option.countryCode) : '';
  const optionLabel = joinTruthyValues({ name: option?.name, country: countryName }, ', ');
  return optionLabel ?? '';
};

export const getRailAutocompleteOptionLabel = (option: IRailSuggestion): string => option?.name ?? '';

export const getEmptyRailSegment = (
  segment?: Partial<IRailSearchSegmentState<IRailSuggestion>>,
): IRailSearchSegmentState<IRailSuggestion> => ({
  origin: segment?.origin || emptyRailSegmentLocation,
  destination: segment?.destination || emptyRailSegmentLocation,
  date: segment?.date || dateUtilFormat(dateUtil(), dateFormats.LONG_DATE_REVERSE),
  time: segment?.time || dateUtilFormat(dateUtil().add(1, 'hour').startOf('hour'), timeFormats.HR12),
  type: segment?.type ?? IRailTimeTypeEnum[IRailTimeTypeEnum.DEPARTS_AFTER],
  via: segment?.via || viaFilterEmptyState,
});

export const getEmptyErrorState = (): IRailSearchSegmentErrorState => ({
  destination: '',
  origin: '',
  date: '',
  time: '',
  type: '',
  via: '',
});

const getJourney = ({
  origin,
  destination,
  time,
  date,
  via,
}: IRailSearchSegmentState<IRailSuggestion>): IRailSegment => {
  const HR24Time = convertDateFormat(time, timeFormats.HR12, timeFormats.HR24);
  const ISODate = convertDateFormat(date, dateFormats.LONG_DATE_REVERSE, dateFormats.LONG_DATE_REVERSE_FORMAT);

  const dateTimeWithoutOffset = dateUtilFormat(dateUtil.utc(`${ISODate} ${HR24Time}`), dateFormats.ISO);
  const dateTime = origin.timeZone
    ? dateUtilFormat(dateUtil.utc(`${ISODate} ${HR24Time}`).tz(origin.timeZone, true), '')
    : '';

  const originLocation = set({}, lowerCase(origin?.searchLocationType ?? 'station'), origin.stationReferenceId);
  const destinationLocation = set(
    {},
    lowerCase(destination?.searchLocationType ?? 'station'),
    destination.stationReferenceId,
  );

  // As of now searchLocationType is set only for AIRPORT case
  return {
    /** Timezone for AIRPORT case is handled by Backend, so we send without timezone information */
    time: { iso8601: origin.searchLocationType === 'AIRPORT' ? dateTimeWithoutOffset : dateTime },
    origin: originLocation,
    destination: destinationLocation,
    avoid: [],
    via: via.type === IViaFilterType.VIA ? [{ station: via.location.stationReferenceId }] : [],
  };
};

export const getRailSearchParams = ({
  segments,
  searchType,
  discountCards,
  railcards,
  primaryTravelerRailcards,
  additionalTravelers,
}: {
  segments: IRailSearchSegmentState<IRailSuggestion>[];
  searchType: IRailSearchType;
  discountCards: string[];
  /** The 'railcards' prop will be discontinued when mutlipax and the new railcard picker are fully implemented */
  railcards: IRailSearchUrlParams['railcards'];
  primaryTravelerRailcards?: IRailcardLoyaltyListItem[];
  additionalTravelers?: RailAdditionalTraveler[];
}): IRailSearchUrlParams => ({
  discountCards,
  railcards,
  searchType,
  segments,
  currLegNumber: 0,
  primaryTravelerRailcards,
  additionalTravelers,
});

export const getSelectedRailcardDisplay = (
  selectedRailcards: string[],
): { firstSelectedRailcard?: string; numberOfAdditionalSelections: string } => {
  const firstSelectedRailcard = first(selectedRailcards);
  const additionalSelections = selectedRailcards.length - 1;
  const numberOfAdditionalSelections = additionalSelections > 0 ? `+${additionalSelections}` : '';
  return { firstSelectedRailcard, numberOfAdditionalSelections };
};

export const getFirstRailSegment = (
  segments: IRailSearchSegmentState<IRailSuggestion>[],
): IRailSearchSegmentState<IRailSuggestion> => getEmptyRailSegment(first(segments));

/**
 * Currently it is tied to react-dates library which is using moment under the hood
 * Should be removed in future as we decided to use dayjs instead of moment
 * (TODO): Please take into account when refactoring the rail workflow on web
 *
 * @param segment IRailSearchSegmentState<IRailSuggestion>
 * @returns parsed date and time in moment format
 */
export const getInputDateTimeMoment = (segment?: IRailSearchSegmentState<IRailSuggestion>): Moment | null => {
  const inputTime = convertDateFormat(segment?.time ?? '', timeFormats.HR12, timeFormats.HR24);
  const inputDate =
    segment?.date && segment?.time
      ? moment(`${segment.date} ${inputTime}`, dateFormats.LONG_DATE_REVERSE_HR24_TIME_WITHOUT_SECONDS)
      : null;

  return inputDate;
};

/**
 * used in mobile and should be applied to web as well
 * @param segment IRailSearchSegmentState<IRailSuggestion>
 * @returns parsed date and time in moment format
 */
export const getInputDateTime = (segment?: IRailSearchSegmentState<IRailSuggestion>): Dayjs | null => {
  const inputTime = convertDateFormat(segment?.time ?? '', timeFormats.HR12, timeFormats.HR24);
  const inputDate =
    segment?.date && segment?.time
      ? dateUtil(`${segment.date} ${inputTime}`, dateFormats.LONG_DATE_REVERSE_HR24_TIME_WITHOUT_SECONDS)
      : null;

  return inputDate;
};

export const getPositionForRailFareType = (str: string, items: string[]): number => {
  /** 0.5 so that all other alternative types are
   * positioned between Advance and Anytime
   */
  let index = 0.5;

  items.forEach((item) => {
    if (str.includes(item)) {
      index = items.indexOf(item);
    }
  });

  return index;
};

export const sortRailFareTypes = (fareTypes: string[]) =>
  fareTypes.sort((fareType1, fareType2) => {
    const [alternativeType1] = fareType1.split('%');
    const [alternativeType2] = fareType2.split('%');

    const index1 = getPositionForRailFareType(alternativeType1, railAlternativesSortOrder);
    const index2 = getPositionForRailFareType(alternativeType2, railAlternativesSortOrder);

    return index1 - index2;
  });

export const pushToRailSectionObjectArr = ({
  allFares,
  sectionAlternative,
  key,
  categoryName,
  className,
}: ISectionTravelClassAlternativesCreateMethod) =>
  produce(allFares, (draft) => {
    draft[className][categoryName][key].push(sectionAlternative);
  });

export const createRailSectionObjectArr = ({
  allFares,
  sectionAlternative,
  key,
  categoryName,
  className,
}: ISectionTravelClassAlternativesCreateMethod) =>
  produce(allFares, (draft) => {
    draft[className][categoryName][key] = [sectionAlternative];
  });

export const getFilteredRailCards = (searchInput: string, railcards?: IRailCard[]): IRailCard[] =>
  railcards?.filter((card) => {
    const inName = card.name.toLowerCase().includes(searchInput.toLowerCase());
    const isSpotnanaCode = card.spotnanaCode.toLowerCase().includes(searchInput.toLowerCase());
    return inName || isSpotnanaCode;
  }) ?? [];

export const getRailDiscountCards = (
  { discountCards, railcards: profileRailcards }: Pick<IRailSearchUrlParams, 'railcards' | 'discountCards'>,
  railcardList?: IListRailCardsResponse,
): RailSearchOutwardRequest['passengers'][0]['discountCards'] => {
  let selectedRailcards: IProfileRailCard[] = [];

  if (discountCards?.length > 0 && railcardList && railcardList.railCards?.length > 0) {
    const { railCards } = railcardList;
    selectedRailcards =
      railCards
        .filter((railcard) => railcard.name === first(discountCards))
        ?.map((card) => {
          const { vendor, name, spotnanaCode } = card;
          return { vendor, name, spotnanaCode, cardNumber: '' };
        }) ?? [];
  }

  return (
    profileRailcards?.map((railcard) => {
      const { spotnanaCode, vendor, name, cardNumber } = railcard.product;
      return { spotnanaCode, vendor, name, cardNumber: cardNumber ?? '' };
    }) || selectedRailcards
  );
};

export const getRailPassengers = (
  params: Pick<
    IRailSearchUrlParams,
    'bookingFlow' | 'nonProfileUserInfo' | 'discountCards' | 'primaryTravelerRailcards' | 'additionalTravelers'
  >,
  traveler?: ITraveler,
  railcardList?: IListRailCardsResponse,
): RailSearchOutwardRequest['passengers'] => {
  const { bookingFlow, nonProfileUserInfo, primaryTravelerRailcards, additionalTravelers } = params;

  /** This identifies the multipax flow. Should be standard for singlepax later after new railcard picker is standard for all bookings */
  if (primaryTravelerRailcards || additionalTravelers) {
    const passengers: RailSearchOutwardRequest['passengers'] = [
      {
        discountCards:
          primaryTravelerRailcards && primaryTravelerRailcards.length > 0
            ? getRailDiscountCards(
                { discountCards: primaryTravelerRailcards.map((railCard) => railCard.label) },
                railcardList,
              )
            : [],
        userOrgId: traveler?.userOrgId,
        traveler,
        passengerType: PassengerType.ADULT,
      },
    ];

    additionalTravelers?.forEach((additionalTraveler) => {
      passengers.push({
        discountCards:
          additionalTraveler.selectedRailcards && additionalTraveler.selectedRailcards.length > 0
            ? getRailDiscountCards(
                { discountCards: additionalTraveler.selectedRailcards.map((railCard) => railCard.label) },
                railcardList,
              )
            : [],
        userOrgId: additionalTraveler.userOrgId,
        passengerType: additionalTraveler.paxType,
        passengerAge: additionalTraveler.paxAge,
      });
    });

    return passengers;
  }

  return [
    {
      discountCards: getRailDiscountCards(params, railcardList),
      ...(bookingFlow === BookingFlowEnum.TRAVELER || bookingFlow == null
        ? { userOrgId: traveler?.userOrgId, traveler }
        : {}),
      ...(bookingFlow === BookingFlowEnum.ADHOC ? { nonProfileUserInfo } : {}),
      passengerType: PassengerType.ADULT,
    },
  ];
};

export const getRailSearchRequest = (
  params: IRailSearchUrlParams,
  traveler?: ITraveler,
  railcardList?: IListRailCardsResponse,
): IRailSearchRequest => {
  const { searchKey, outwardRateKeys, outwardSearchKey, segments, searchType, tripId = '' } = params;

  if (searchKey) {
    return { searchKey };
  }

  if (outwardRateKeys && outwardSearchKey) {
    return { outwardRateKeys, outwardSearchKey };
  }

  return {
    passengers: getRailPassengers(params, traveler, railcardList),
    searchType: SearchType[searchType],
    outwardJourney: getJourney(segments[0]),
    inwardJourney: segments.length > 1 ? getJourney(segments[1]) : undefined,
    thirdParty: V1RailThirdParty[segments[0].origin.thirdParty ?? RailThirdParty.UnknownThirdParty],
    tripId: tripId.toString(),
  };
};

export function getTranslationKeyForTravelClass(travelClass: TravelClass): string {
  switch (travelClass) {
    case TravelClass.FIRST:
      return railTranslationKeys.FIRST_CLASS;
    case TravelClass.STANDARD:
      return railTranslationKeys.STANDARD;
    default:
      return '';
  }
}

export const isRailSearchOutwardRequest = (
  railSearchRequest: IRailSearchRequest,
): railSearchRequest is RailSearchOutwardRequest =>
  (railSearchRequest as RailSearchOutwardRequest).outwardJourney !== undefined;

export const isRailSearchInwardRequest = (
  railSearchRequest: IRailSearchRequest | IRailModifySearchRequest,
): railSearchRequest is RailSearchInwardRequest =>
  (railSearchRequest as RailSearchInwardRequest).outwardSearchKey !== undefined;

export const isRailModifySearchRequest = (
  railSearchRequest: IRailModifySearchRequest,
): railSearchRequest is RailModifySearchRequest =>
  (railSearchRequest as RailModifySearchRequest).exchangeIds !== undefined;

export const isRailSearchNavigationRequest = (
  railSearchRequest: IRailSearchRequest | IRailModifySearchRequest,
): railSearchRequest is IRailSearchNavigationRequest =>
  (railSearchRequest as IRailSearchNavigationRequest).searchKey !== undefined;

export const getTotalPrice = (
  currentTotalPrice?: MoneyUtil,
  currentDiscountedPrice?: MoneyUtil,
  outwardTotalPrice?: MoneyUtil,
  outwardDiscountedPrice?: MoneyUtil,
  currencySymbol?: string,
): {
  amount: string;
  currency: string | undefined;
  discountedAmount: string;
  discountedCurrency: string | undefined;
} => {
  let totalPrice;
  let totalDiscountedPrice;
  if (outwardTotalPrice && currentTotalPrice) {
    // Addition can fail when adding with original currency
    // because original currencies might differ
    // hence, adding the preferred currencies only
    totalPrice = currentTotalPrice.add(outwardTotalPrice);
  }

  if (outwardTotalPrice && !currentTotalPrice) {
    totalPrice = outwardTotalPrice;
  }

  if (!outwardTotalPrice && currentTotalPrice) {
    totalPrice = currentTotalPrice;
  }

  if (outwardDiscountedPrice && currentDiscountedPrice) {
    totalDiscountedPrice = currentDiscountedPrice.add(outwardDiscountedPrice);
  }

  if (outwardDiscountedPrice && !currentDiscountedPrice) {
    totalDiscountedPrice = outwardDiscountedPrice;
  }

  if (!outwardDiscountedPrice && currentDiscountedPrice) {
    totalDiscountedPrice = currentDiscountedPrice;
  }

  const currency = totalPrice?.getCurrencyInfo().symbol ?? currencySymbol;
  const amount = totalPrice?.getDisplayAmount() ?? '0';
  const discountedCurrency = totalDiscountedPrice?.getCurrencyInfo().symbol ?? currencySymbol;
  const discountedAmount = totalDiscountedPrice?.getDisplayAmount() ?? '0';
  return { amount, currency, discountedAmount, discountedCurrency };
};

export const getBreadcrumbs = (segments: IRailSearchSegmentState<IRailSuggestion>[]): IBreadcrumb[] =>
  segments.map((segment) => ({
    departure: segment.origin.name,
    arrival: segment.destination.name,
  }));

const parseTravelcardRateInfo = (rateInfo?: RateInfo): IRateInfo | undefined => {
  if (rateInfo) {
    const base = rateInfo.base ? MoneyUtil.parse(rateInfo.base) : undefined;
    const fees = rateInfo.fees ? MoneyUtil.parse(rateInfo.fees) : undefined;
    const railCardDiscount = rateInfo.railCardDiscount ? MoneyUtil.parse(rateInfo.railCardDiscount) : undefined;
    const rateDifference = rateInfo.rateDifference ? MoneyUtil.parse(rateInfo.rateDifference) : undefined;
    const totalRateDifference = rateInfo.totalRateDifference
      ? MoneyUtil.parse(rateInfo.totalRateDifference)
      : undefined;
    const total = rateInfo.total ? MoneyUtil.parse(rateInfo.total) : undefined;
    const transactionDateTime = rateInfo.transactionDateTime || undefined;

    const rateInfos = { base, fees, railCardDiscount, total, rateDifference, totalRateDifference, transactionDateTime };
    return rateInfos;
  }
  return undefined;
};

export const getRailItineraryDetails = (resp?: ItineraryDetails): IItineraryDetails | undefined => {
  if (!resp) return undefined;

  const { rateInfo: respRateInfo, ancillaries: respAncillaries, ...itineraryDetails } = resp;

  let rateInfo: IRateInfo | undefined;
  if (respRateInfo) {
    rateInfo = parseTravelcardRateInfo(respRateInfo);
  }

  const ancillaries: IAncillary[] = respAncillaries.map((ancillary) => {
    const { type, travelCard } = ancillary;

    if (travelCard) {
      const travelCardRateInfo = parseTravelcardRateInfo(travelCard.rateInfo);
      const parsedTravelCard = { ...travelCard, rateInfo: travelCardRateInfo };
      return { type, travelCard: parsedTravelCard };
    }

    return { type };
  });

  return {
    ...itineraryDetails,
    rateInfo,
    ancillaries,
  };
};

export const getRailTravelCards = (ancillaries: Ancillary[]): ITravelCard[] | undefined => {
  const travelCards = ancillaries.reduce((acc, { travelCard }) => {
    if (travelCard) {
      const rateInfo = parseTravelcardRateInfo(travelCard.rateInfo);
      const parsedTravelCard = { ...travelCard, rateInfo };
      acc.push(parsedTravelCard);
    }

    return acc;
  }, [] as ITravelCard[]);

  return travelCards.length ? travelCards : undefined;
};

export const getRailOverlayAndDuration = (legs: IRailLeg[], index: number): IRailOverlayAndDuration => {
  const {
    arrival: currLegArrival,
    departure: currLegDeparture,
    duration: currLegDuration,
    destination: currLegDestination,
  } = legs[index];
  const currLegArrivalISO = currLegArrival?.iso8601;
  const currLegDepartureISO = currLegDeparture?.iso8601;

  const { arrival: nextLegArrival, departure: nextLegDeparture, origin: nextLegOrigin } = nth(legs, index + 1) ?? {};
  const nextLegArrivalISO = nextLegArrival?.iso8601;
  const nextLegDepartureISO = nextLegDeparture?.iso8601;

  const hasOverlay = index < legs.length - 1 && currLegDepartureISO !== nextLegArrivalISO;
  const overlay =
    hasOverlay && nextLegDepartureISO && currLegArrivalISO
      ? getDateTimeDiff(nextLegDepartureISO, currLegArrivalISO, dateFormats.ISO)
      : 0;

  const duration = (currLegDuration && minutesToDurationString(getDurationMinutes(currLegDuration.iso8601))) ?? '';

  const isSameStation = currLegDestination === nextLegOrigin;

  return { overlay, duration, isSameStation };
};

export const isValidRailThirdParty = (segments: IRailSearchSegmentState<IRailSuggestion>[]): boolean => {
  const thirdParty = first(segments)?.origin.thirdParty;
  return Boolean(thirdParty && thirdParty !== RailThirdParty.UnknownThirdParty);
};

/** Open return option should only be available for
 * Origin: UK -> Destination: UK, i.e. when the vendor is ATOC
 * */
export const isOpenReturnAllowed = (segments: IRailSearchSegmentState<IRailSuggestion>[]): boolean => {
  return isUKSegment(segments);
};

export const isEUSegment = (segments: IRailSearchSegmentState<IRailSuggestion>[]): boolean => {
  const origin = first(segments)?.origin;
  const destination = first(segments)?.destination;

  // EU <=> EU
  return origin?.continentCode === 'EU' && destination?.continentCode === 'EU';
};

export const isUKSegment = (segments: IRailSearchSegmentState<IRailSuggestion>[]): boolean => {
  const origin = first(segments)?.origin;
  const destination = first(segments)?.destination;

  // UK <=> UK
  return origin?.countryCode === 'GB' && destination?.countryCode === 'GB';
};

export const getRailcardVendorsToShow = (
  segments: IRailSearchSegmentState<IRailSuggestion>[],
  railcardVendors: string[],
): string[] => {
  return railcardVendors.filter((railcardVendor) => {
    if (first(segments)?.origin.thirdParty === RailThirdParty.Amtrak) {
      return US_RAIL_BOOKING_VENDOR_NAME === railcardVendor;
    }

    if (first(segments)?.origin.countryCode === 'GB') {
      return railcardVendor === UK_RAIL_BOOKING_VENDOR_NAME;
    }

    // Return all except AMTRAK and ATOC
    return ![UK_RAIL_BOOKING_VENDOR_NAME, US_RAIL_BOOKING_VENDOR_NAME].includes(railcardVendor);
  });
};

export const getMinimumFareAlternativeForAvailableTravelClasses = (
  minimumFareAlternatives: Record<TravelClass, ISectionAlternative | null>,
  thirdParty: RailThirdParty | undefined,
): (ISectionAlternative | null)[] => {
  const sortedAvailableTravelClasses =
    thirdParty === RailThirdParty.Amtrak
      ? sortedAvailableTravelClassesForAmtrak
      : sortedAvailableTravelClassesForTrainline;

  return sortedAvailableTravelClasses.map((travelClass) => {
    return minimumFareAlternatives[travelClass];
  });
};

export const getTotalCo2Emissions = (itineraryDetails: ItineraryDetails | undefined): number => {
  const outwardJourneyCo2 = itineraryDetails?.outwardJourney?.co2EmissionDetails?.co2EmissionKilograms ?? 0;
  const inwardJourneyCo2 = itineraryDetails?.inwardJourney?.co2EmissionDetails?.co2EmissionKilograms ?? 0;
  return outwardJourneyCo2 + inwardJourneyCo2;
};

export function getRailJourneyOriginAndDestinationFormatted(journeyInfo: JourneyInfo): {
  origin: string;
  destination: string;
} {
  const LOCATION_UNAVAILABLE = 'N/A';

  const { originInfo, destinationInfo } = journeyInfo;

  const { name: originName, cityName: originCityName, stateCode: originStateCode } = originInfo ?? {};
  let origin = originName ?? LOCATION_UNAVAILABLE;
  if (originCityName && originStateCode) {
    origin = `${originCityName}, ${originStateCode}`;
  }

  const {
    name: destinationName,
    cityName: destinationCityName,
    stateCode: destinationStateCode,
  } = destinationInfo ?? {};
  let destination = destinationName ?? LOCATION_UNAVAILABLE;
  if (originCityName && destinationStateCode) {
    destination = `${destinationCityName}, ${destinationStateCode}`;
  }

  return {
    origin,
    destination,
  };
}

export const getRailSeatPriceText = (seatPrice?: MoneyUtil): string | undefined => {
  if (!seatPrice) {
    return undefined;
  }
  return seatPrice.isZero() ? 'Free' : `${seatPrice.getCurrencyInfo().symbol}${seatPrice.getAmount()}`;
};

export const getSegmentThirdPartyInfo = (
  segments: IRailSearchSegmentState<IRailSuggestion>[],
): {
  isAmtrakSegment: boolean;
  thirdParty: RailThirdParty;
} => {
  const thirdParty = first(segments)?.origin.thirdParty ?? RailThirdParty.Trainline;
  return {
    isAmtrakSegment: thirdParty === RailThirdParty.Amtrak,
    thirdParty,
  };
};

/**
 * Create fare groups (sets of locations/fare rules) using alternatives' legs
 */
export function getFareDetailsModalDataFromAlternativesAndLegs(
  alternatives: ISectionAlternative[],
  legs: IRailLeg[],
  getAlternativeWithPricesAmenities: (alternative: ISectionAlternative) => RailAmenityV1[],
): FareDetailsModalSectionInfo[] {
  return alternatives.flatMap((alternative) => {
    const amenities = getAlternativeWithPricesAmenities(alternative);

    /**
     * Make sure that fares and their legs are correctly orderer (first -> last)
     */
    const faresSortedByLegsIndices = sortBy(
      alternative.fares.map((fare) => ({
        ...fare,
        fareLegs: sortBy(fare.fareLegs, (v) => v.legIndex),
      })),
      (fare) => Math.min(...fare.fareLegs.map((item) => item.legIndex)),
    );

    /**
     * Put fares into groups by fare name and description.
     *
     * Reason: section may contain fares with different names and rules
     */
    const fareGroups: (typeof alternative.fares)[] = [];
    faresSortedByLegsIndices.forEach((fare) => {
      const existingFareGroupIdx = fareGroups.findIndex((group) =>
        group.some(
          (item) =>
            item.fareType?.description === fare.fareType?.description &&
            first(item.fareType?.fareDetails)?.description === first(fare.fareType?.fareDetails)?.description,
        ),
      );

      if (existingFareGroupIdx === -1) {
        fareGroups.push([fare]);
      } else {
        fareGroups[existingFareGroupIdx].push(fare);
      }
    });

    /**
     * Get fare group legs info and use it to display all the required info
     */
    return fareGroups.map((fares) => {
      const legIndices = fares.flatMap((fare) => fare.fareLegs.map((fareLeg) => fareLeg.legIndex));
      const fareLegs = compact(legIndices.map((legIdx) => nth(legs, legIdx)));

      const firstFareLeg = first(fareLegs);
      const lastFareLeg = last(fareLegs);

      const originStations = first(fares)?.originStations ?? [];
      const destinationStations = last(fares)?.destinationStations ?? [];

      const vehicles = uniqBy(
        fareLegs.map((leg) => leg.vehicle).filter((vehicle) => vehicle?.carrierName) as Vehicle[],
        (vehicle) => (vehicle?.carrierName || '') + (vehicle?.transportName || ''),
      );

      const appliedPromotions = fares.flatMap((fare) => fare.appliedPromotions || []);

      return {
        origin: firstFareLeg?.origin ?? '', // begins in the first leg's origin
        destination: lastFareLeg?.destination ?? '', // ends in the last leg's destination
        numOfChanges: fareLegs.length - 1,
        vehicles,
        isAmtrakCarrier: fareLegs.some((fareLeg) => getIsAmtrakLeg(fareLeg)),
        fareTypes: uniq(compact(fares.map((fare) => fare.fareType))),
        routeRestrictions: uniq(compact(fares.map((fare) => fare.routeRestriction))),
        fareDetails: first(fares)?.fareType?.fareDetails, // assume that fare rules are same for the whole group
        amenities, // controlled by an alternative; there's no way to split this per leg
        originStations,
        destinationStations,
        appliedPromotions,
      };
    });
  });
}

/**
 * Create fare groups using leg infos' vendor / location / fare info
 */
export function getFareDetailsModalDataFromLegInfos(
  legInfos: IRailLegInfo[],
  appliedPromotions?: AppliedPromotion[],
): FareDetailsModalSectionInfo[] {
  /**
   * Create groups which consist of legs with similar vendor names and fare types
   */
  const groups: Array<IRailLegInfo[]> = [];
  legInfos.forEach((legInfo) => {
    const { vendorName, fareType } = legInfo;
    const fareDescription = fareType?.description;

    const existingGroupIdx = groups.findIndex((group) =>
      group.some((item) => item.vendorName === vendorName && item.fareType?.description === fareDescription),
    );

    if (existingGroupIdx === -1) {
      groups.push([legInfo]);
    } else {
      groups[existingGroupIdx].push(legInfo);
    }
  });

  return groups.map((groupLegInfos, index) => {
    const firstLegInfo = first(groupLegInfos);
    const lastLegInfo = last(groupLegInfos);

    const vehicles = uniqBy(
      groupLegInfos.map((leg) => leg.vehicle).filter((vehicle) => vehicle?.carrierName) as Vehicle[],
      (vehicle) => (vehicle?.carrierName || '') + (vehicle?.transportName || ''),
    );

    return {
      origin: firstLegInfo?.origin ?? '', // begins in the first leg's origin
      destination: lastLegInfo?.destination ?? '', // ends in the last leg's destination
      numOfChanges: groupLegInfos.length - 1,
      vehicles,
      isAmtrakCarrier: groupLegInfos.some((legInfo) => getIsAmtrakLegInfo(legInfo)),
      fareDetails: firstLegInfo?.fareType?.fareDetails, // assume that fare rules are similar
      amenities: uniqBy(
        groupLegInfos.flatMap((item) => item.amenities),
        (item) => item.name,
      ), // show unique amenities only
      originStations: firstLegInfo?.origin ? [firstLegInfo.origin] : [],
      destinationStations: lastLegInfo?.destination ? [lastLegInfo.destination] : [],
      appliedPromotions: appliedPromotions && appliedPromotions[index] ? [appliedPromotions[index]] : [],
    };
  });
}

// Returns fares ordered by their leg index
function getFaresSortedByLegsIndices(alternative: ISectionAlternative) {
  return sortBy(
    alternative.fares.map((fare) => ({
      ...fare,
      fareLegs: sortBy(fare.fareLegs, (v) => v.legIndex),
    })),
    (fare) => Math.min(...fare.fareLegs.map((item) => item.legIndex)),
  );
}

// Returns the split ticket type and icon
function getSplitSectionTypeNIcon(fareComposition: FareComposition) {
  let splitTicketType: string | undefined;
  let splitTicketTypeIcon: IconName | undefined;

  if (fareComposition === FareComposition.DIRECT_SPLIT) {
    splitTicketType = defineMessage('Stay on the train, just switch tickets');
    splitTicketTypeIcon = 'Rail';
  } else if (fareComposition === FareComposition.INTERCHANGE_SPLIT) {
    splitTicketType = defineMessage('Switch trains, switch tickets');
    splitTicketTypeIcon = 'BookingChange';
  }
  return { splitTicketType, splitTicketTypeIcon };
}

export function getSplitTicketDetailsModalData(
  alternatives: ISectionAlternative[],
  legs: IRailLeg[],
): SplitTicketDetailsModalSectionInfo[] {
  return alternatives.flatMap((alternative) => {
    const { amenities } = alternative;

    // Make sure that fares and their legs are correctly orderer (first -> last)
    const faresSortedByLegsIndices = getFaresSortedByLegsIndices(alternative);

    // Determine the split ticket type of the alternative (if it's a split ticket) and map it to a correct string
    const { splitTicketType, splitTicketTypeIcon } = getSplitSectionTypeNIcon(alternative.fareComposition);

    /**
     * Put fares into groups by fare name and description.
     *
     * Reason: section may contain fares with different names and rules
     */
    const fareGroups: typeof alternative.fares = [];
    faresSortedByLegsIndices.forEach((fare) => {
      fare?.fareType?.fareDetails?.sort((fareDetailsItem) =>
        fareDetailsItem.name.toLocaleLowerCase() === 'description' ? -1 : 1,
      );
      fareGroups.push(fare);
    });

    /**
     * Get fare group legs info and use it to display all the required info
     */
    return fareGroups.map((fare) => {
      const { appliedPromotions } = fare;
      const legIndices = fare.fareLegs.map((fareLeg) => fareLeg.legIndex);
      const fareLegs = compact(legIndices.map((legIdx) => nth(legs, legIdx)));

      const firstFareLeg = first(fareLegs);
      const lastFareLeg = last(fareLegs);

      /*
       * The following origin/destination logic is exclusive to split tickets.
       * I left in the existing logic from through tickets in case alternatives don't supply the information we need.
       */

      const origin = fare?.originStations?.[0];
      const destination = fare?.destinationStations?.[0];

      const vehicles = uniqBy(
        fareLegs.map((fareLeg) => fareLeg.vehicle).filter((vehicle) => vehicle?.carrierName) as Vehicle[],
        (vehicle) => (vehicle?.carrierName || '') + (vehicle?.transportName || ''),
      );

      const originStations = fare.originStations || [];
      const destinationStations = fare.destinationStations || [];

      return {
        origin: origin || firstFareLeg?.origin || firstFareLeg?.originPlatform || '', // begins in the first leg's origin
        destination: destination || lastFareLeg?.destination || lastFareLeg?.destinationPlatform || '', // ends in the last leg's destination
        numOfChanges: fareLegs.length - 1,
        vehicles,
        isAmtrakCarrier: fareLegs.some((fareLeg) => getIsAmtrakLeg(fareLeg)),
        fareDetails: fare?.fareType?.fareDetails, // assume that fare rules are same for the whole group
        fareTypes: fare.fareType ? [fare.fareType] : [],
        routeRestrictions: fare.routeRestriction ? [fare.routeRestriction] : [],
        amenities, // controlled by an alternative; there's no way to split this per leg,
        splitTicketType: splitTicketType ?? undefined,
        splitTicketTypeIcon: splitTicketTypeIcon ?? undefined,
        originStations,
        destinationStations,
        appliedPromotions,
      };
    });
  });
}

/**
 * Sometimes this comfort class code is received from Trainline.
 * In that case we should replace it with "Sit in any unreserved seat" text.
 *
 * !!! It's a hack, so not moving this `obt-common` for now.
 */
const CFF_NO_RESERVATION = 'cff.no_reservation';

export function getAlternativeComfortClassTitleAndDescription(alternative: ISectionAlternative): {
  title: string;
  description: string | null;
} {
  const fareTitlesWithDescription = uniqBy(
    alternative.fares.flatMap((fare) =>
      fare.fareLegs.map((fareLeg) => {
        let title = fareLeg.comfortClass || 'Unknown comfort class';
        if (title === CFF_NO_RESERVATION) {
          title = 'Sit in any unreserved seat';
        }

        const description = fareLeg.comfortClassDescription || null;

        return { title, description };
      }),
    ),
    // Show uniq title/description pairs
    (item) => `${item.title} ${item.description}`,
  );

  /**
   * In case it's the only comfort class in the alternative,
   * `title` should be present, but `description` may be absent
   */
  if (fareTitlesWithDescription.length < 2) {
    return {
      title: first(fareTitlesWithDescription)?.title || 'Unknown comfort class',
      description: first(fareTitlesWithDescription)?.description || null,
    };
  }

  /**
   * Otherwise, we keep both `title` and `description` to show info in the following format:
   * title_1 / title_2
   * description_1 / description_2
   */
  return {
    title: fareTitlesWithDescription.map((item) => item.title).join(' / ') || 'Unknown comfort class',
    description: fareTitlesWithDescription.map((item) => item.description || 'No description available').join(' / '),
  };
}

export function getSeatPreferencesOptions(options: SeatPreferenceOptions | null): IGetSeatPreferencesOptions {
  if (!options) {
    return {
      position: [],
      direction: [],
      coachType: [],
      deck: [],
      seatType: [],
    };
  }
  return {
    position: options.position.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    direction: options.direction.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    coachType: options.coachType.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    deck: options.deck.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    seatType: options.seatType.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  };
}

export const isFirstPartyVehicle = (vehicle: IRailLeg['vehicle'] | undefined) =>
  !!vehicle?.type &&
  [
    RailVehicleType.TRAIN,
    RailVehicleType.METRO,
    RailVehicleType.BUS,
    RailVehicleType.FERRY,
    RailVehicleType.TAXI,
  ].includes(vehicle?.type);

/**
 * Retrieves and sorts journey fares based on the provided sections.
 * @param journey - The journey information.
 * @param sections - The sections associated with the journey.
 * @returns An array of sorted fares.
 */
export function getJourneySortedFares(journey: JourneyInfo, sections: SectionInfo[]): FareInfo[] {
  return sections
    .flatMap((section) =>
      section.fares.filter((fare) => fare.fareLegs.some((fareLeg) => journey.legs.includes(fareLeg.legIndex))),
    )
    .sort((a, b) => a.fareLegs[0].legIndex - b.fareLegs[0].legIndex);
}

/**
 * Retrieves and concatenates fare types associated with the journey using sections or legInfos.
 * @param journey - The journey information.
 * @param sections - The sections associated with the journey.
 * @param legInfos - The leg information.
 * @returns A string containing concatenated fare types.
 */
export function getJourneyFareTypesUsingSections(
  journey: JourneyInfo | undefined,
  sections: SectionInfo[] | undefined,
  legInfos: LegInfo[] | undefined,
): string {
  if (!journey) {
    return '';
  }
  if (!sections) {
    const firstLegIdx = first(journey.legs) ?? -1;
    return legInfos?.[firstLegIdx]?.fareType?.description || '';
  }
  const fares = getJourneySortedFares(journey, sections);
  return fares.map((fare) => fare?.fareType?.description).join(', ') || '';
}

/**
 * Retrieves and concatenates fare types associated with the journey using itinerary details.
 * @param journey - The journey information.
 * @param itineraryDetails - The itinerary details.
 * @returns A string containing concatenated fare types.
 */
export function getJourneyFareTypesUsingItineraryDetails(
  journey: JourneyInfo | undefined,
  itineraryDetails: IItineraryDetails | undefined,
): string {
  if (!itineraryDetails) {
    return '';
  }
  const { sections, legInfos } = itineraryDetails;
  return getJourneyFareTypesUsingSections(journey, sections, legInfos);
}

export function getSplitTicketDetailsModalDataUsingSections(
  journey: JourneyInfo,
  sections: SectionInfo[],
  legs: LegInfo[],
  appliedPromotions?: AppliedPromotion[],
): SplitTicketDetailsModalSectionInfo[] {
  const fares = getJourneySortedFares(journey, sections);

  const { splitTicketType, splitTicketTypeIcon } = getSplitSectionTypeNIcon(journey.fareComposition);
  /**
   * Get fare group legs info and use it to display all the required info
   */
  return fares.map((fare, index) => {
    const legIndices = fare.fareLegs.map((fareLeg) => fareLeg.legIndex);
    const fareLegs = compact(legIndices.map((legIdx) => nth(legs, legIdx)));
    const firstFareLeg = first(fareLegs);
    /*
     * The following origin/destination logic is exclusive to split tickets.
     * I left in the existing logic from through tickets in case alternatives don't supply the information we need.
     */
    const origin = fare?.originInfo?.name || '';
    const destination = fare?.destinationInfo?.name || '';

    const vehicles = uniqBy(
      fareLegs.map((leg) => leg.vehicle).filter((vehicle) => vehicle?.carrierName) as Vehicle[],
      (vehicle) => (vehicle?.carrierName || '') + (vehicle?.transportName || ''),
    );

    return {
      origin,
      destination,
      numOfChanges: fareLegs.length - 1,
      vehicles,
      isAmtrakCarrier: fareLegs.some((fareLeg) => getIsAmtrakLeg(fareLeg as unknown as IRailLeg)),
      fareDetails: fare?.fareType?.fareDetails, // assume that fare rules are same for the whole group
      fareTypes: fare.fareType ? [fare.fareType] : [],
      routeRestrictions: fare.routeRestriction ? [fare.routeRestriction] : [],
      amenities: firstFareLeg?.amenities, // controlled by an alternative; there's no way to split this per leg,
      splitTicketType,
      splitTicketTypeIcon,
      originStations: origin ? [origin] : [],
      destinationStations: destination ? [destination] : [],
      appliedPromotions: appliedPromotions && appliedPromotions[index] ? [appliedPromotions[index]] : [],
    };
  });
}

/**
 * Converts FareInfo and LegInfo array received from itinerary details post-booking into a format suitable for the autocomplete search component.
 * @param fare - FareInfo object containing fare-related information.
 * @param legInfos - Array of LegInfo objects containing information about legs of the journey.
 * @returns An array of objects representing search segments in a format compatible with the autocomplete/date-time picker component.
 */
export const convertFareInfoToSearchSegmentState = (
  fare: FareInfo,
  legInfos: LegInfo[],
): IRailSearchSegmentState<IRailSuggestion>[] => {
  // Extracting index of the first and last legs from the fare information
  const firstLegIndex = fare.fareLegs[0]?.legIndex;
  const lastLegIndex = fare.fareLegs[fare.fareLegs.length - 1]?.legIndex;
  if (firstLegIndex === undefined || lastLegIndex === undefined) {
    return [];
  }

  // Retrieving first and last legs from the legInfos array based on extracted indices
  const firstLeg = legInfos[firstLegIndex];
  const lastLeg = legInfos[lastLegIndex];

  // Extracting origin and destination information from the legs
  const { originInfo } = firstLeg;
  const { destinationInfo } = lastLeg;

  /** Verify that the origin info and destination info names are not equal (single-fare round trip edge case) */
  if (originInfo?.name === destinationInfo?.name) {
    return [];
  }

  // TODO: change dateUtil to localizeDate and localizeTime after they're integrated to autocomplete
  // Mapping the fare and leg information to a format suitable for autocomplete search
  return [
    {
      // Origin information from the first leg
      origin: {
        type: 'RAIL_STATION',
        location: originInfo?.name || '',
        state: originInfo?.stateCode || '',
        country: originInfo?.countryCode || '',
        name: originInfo?.name || '',
        cityCode: originInfo?.cityCode,
        cityName: originInfo?.cityName,
        continentCode: originInfo?.continentCode,
      },
      // Destination information from the last leg
      destination: {
        type: 'RAIL_STATION',
        location: destinationInfo?.name || '',
        state: destinationInfo?.stateCode || '',
        country: destinationInfo?.countryCode || '',
        name: destinationInfo?.name || '',
        cityCode: destinationInfo?.cityCode,
        cityName: destinationInfo?.cityName,
        continentCode: destinationInfo?.continentCode,
      },
      // Empty via information
      via: viaFilterEmptyState,
      // Formatting date and time for the first segment
      type: IRailTimeTypeEnum[IRailTimeTypeEnum.DEPARTS_AFTER],
      date: firstLeg.departAt
        ? dateUtil(firstLeg.departAt.iso8601).tz(originInfo?.timeZone).format(dateFormats.LONG_DATE_REVERSE)
        : '',
      time: firstLeg.departAt
        ? dateUtil(firstLeg.departAt.iso8601).tz(originInfo?.timeZone).format(timeFormats.HR12)
        : '',
    },
  ];
};

export const convertRailExchangeV2ToV1 = (exchangeType: RailExchangeTypeV2 | undefined): RailExchangeType => {
  switch (exchangeType) {
    case RailExchangeTypeV2.RebookAndRefund:
      return RailExchangeType.REBOOK_AND_REFUND;
    case RailExchangeTypeV2.PayTheDifference:
      return RailExchangeType.PAY_THE_DIFFERENCE;
    case RailExchangeTypeV2.AmendReservation:
      return RailExchangeType.AMEND_RESERVATION;
    default:
      return RailExchangeType.RAIL_EXCHANGE_TYPE_UNKNOWN;
  }
};

export const convertRailSearchResponseV2toV1 = (railSearchResponse: RailSearchResponseV2): RailSearchResponseV1 => {
  const { journeys, outwardJourneySummary, metadata, navigationParams, ...rest } = railSearchResponse;
  return {
    journeys: journeys?.map((journey) => ({
      ...journey,
      duration: {
        iso8601: journey.duration.iso8601 || '',
      },
      legs: journey.legs.map((leg) => ({
        ...leg,
        co2EmissionGramsPerPassenger: leg.co2EmissionGramsPerPassenger || 0,
        vehicle: {
          ...leg.vehicle,
          transportName: leg.vehicle.carrierName || '',
          type: VehicleType[leg.vehicle.type],
        },
        departure: {
          iso8601: leg.departure.iso8601 || '',
        },
        arrival: {
          iso8601: leg.arrival.iso8601 || '',
        },
        duration: {
          iso8601: leg.duration.iso8601 || '',
        },
        originPlatform: leg.originPlatform || '',
        destinationPlatform: leg.destinationPlatform || '',
        originInfo: {
          ...leg.originInfo,
          code: leg.originInfo?.code || '',
          name: leg.originInfo?.name || '',
          cityCode: leg.originInfo?.cityCode || '',
          timeZone: leg.originInfo?.timeZone || '',
          cityName: leg.originInfo?.cityName || '',
          continentCode: leg.originInfo?.continentCode || '',
          countryCode: leg.originInfo?.countryCode || '',
          stationType: leg.originInfo?.stationType
            ? StationType[leg.originInfo?.stationType]
            : StationType.UNRECOGNIZED,
          sourceRefInfos: leg.originInfo?.sourceRefInfos || [],
          stateCode: leg.originInfo?.stateCode || '',
        },
        destinationInfo: {
          ...leg.destinationInfo,
          code: leg.destinationInfo?.code || '',
          name: leg.destinationInfo?.name || '',
          cityCode: leg.destinationInfo?.cityCode || '',
          timeZone: leg.destinationInfo?.timeZone || '',
          cityName: leg.destinationInfo?.cityName || '',
          continentCode: leg.destinationInfo?.continentCode || '',
          countryCode: leg.destinationInfo?.countryCode || '',
          stationType: leg.destinationInfo?.stationType
            ? StationType[leg.destinationInfo?.stationType]
            : StationType.UNRECOGNIZED,
          sourceRefInfos: leg.destinationInfo?.sourceRefInfos || [],
          stateCode: leg.destinationInfo?.stateCode || '',
        },
      })),
      sections: journey.sections.map((section) => ({
        alternatives: section.alternatives.map((alternative) => ({
          ...alternative,
          travelClass: TravelClass[alternative.travelClass],
          allowedFop: [],
          nonSplitPrice: {
            ...alternative.nonSplitPrice,
            currencyCode: alternative.nonSplitPrice?.currencyCode || '',
            amount: alternative.nonSplitPrice?.amount || 0,
            otherCoinage: alternative.nonSplitPrice?.otherCoinage?.map((coinage) => ({
              ...coinage,
              coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
            })),
          },
          fareComposition: alternative.fareComposition
            ? FareComposition[alternative.fareComposition]
            : FareComposition.UNRECOGNIZED,
          amenities:
            alternative.amenities?.map((amenity) => ({
              ...amenity,
              legs: [],
              amenityKey: amenity.amenityKey || '',
              description: amenity.description || '',
              quantity: amenity.quantity || 0,
              type: amenity.type
                ? RailAmenityRailAmenityType[amenity.type]
                : RailAmenityRailAmenityType.RAIL_AMENITY_TYPE_UNKNOWN,
              price: {
                ...amenity.price,
                currencyCode: amenity.price?.currencyCode || '',
                amount: amenity.price?.amount || 0,
                otherCoinage: amenity.price?.otherCoinage?.map((coinage) => ({
                  ...coinage,
                  coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
                })),
              },
            })) || [],
          totalPrice: {
            ...alternative.totalPrice,
            currencyCode: alternative.totalPrice?.currencyCode || '',
            amount: alternative.totalPrice?.amount || 0,
            otherCoinage: alternative.totalPrice?.otherCoinage?.map((coinage) => ({
              ...coinage,
              coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
            })),
          },
          discountedPrice: {
            ...alternative.discountedPrice,
            currencyCode: alternative.discountedPrice?.currencyCode || '',
            amount: alternative.discountedPrice?.amount || 0,
            otherCoinage: alternative.discountedPrice?.otherCoinage?.map((coinage) => ({
              ...coinage,
              coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
            })),
          },
          flexibility: alternative.flexibility || '',
          deliveryOptions: alternative.deliveryOptions.map((deliveryOption) => DeliveryOption[deliveryOption]),
          category: SectionAlternativeCategory[alternative.category],
          type: alternative.type || '',
          co2EmissionGramsPerPassenger: alternative.co2EmissionGramsPerPassenger || 0,
          fares: alternative.fares.map((fare) => ({
            ...fare,
            fareType: {
              ...fare.fareType,
              fareDetails: fare.fareType.fareDetails || [],
              fareSummary: fare.fareType.fareSummary || '',
            },
            fareLegs: fare.fareLegs.map((fareLeg) => ({
              ...fareLeg,
              travelClass: TravelClass[fareLeg.travelClass],
              comfortClass: fareLeg.comfortClass || '',
              comfortClassDescription: fareLeg.comfortClassDescription || '',
            })),
            outwardValidity: {
              ...fare.outwardValidity,
              validFrom: {
                iso8601: fare?.outwardValidity?.validFrom || '',
              },
              validUntil: {
                iso8601: fare?.outwardValidity?.validUntil || '',
              },
            },
            inwardValidity: {
              ...fare.inwardValidity,
              validFrom: {
                iso8601: fare?.inwardValidity?.validFrom || '',
              },
              validUntil: {
                iso8601: fare?.inwardValidity?.validUntil || '',
              },
            },
            originStations: fare.originStations || [],
            destinationStations: fare.destinationStations || [],
            discountCards:
              fare?.discountCards?.map((discountCard) => ({
                ...discountCard,
                cardNumber: discountCard.cardNumber || '',
              })) || [],
            originInfo: {
              ...fare.originInfo,
              code: fare.originInfo?.code || '',
              name: fare.originInfo?.name || '',
              cityCode: fare.originInfo?.cityCode || '',
              timeZone: fare.originInfo?.timeZone || '',
              cityName: fare.originInfo?.cityName || '',
              continentCode: fare.originInfo?.continentCode || '',
              countryCode: fare.originInfo?.countryCode || '',
              stationType: fare.originInfo?.stationType
                ? StationType[fare.originInfo?.stationType]
                : StationType.UNRECOGNIZED,
              sourceRefInfos: fare.originInfo?.sourceRefInfos || [],
              stateCode: fare.originInfo?.stateCode || '',
            },
            destinationInfo: {
              ...fare.destinationInfo,
              code: fare.destinationInfo?.code || '',
              name: fare.destinationInfo?.name || '',
              cityCode: fare.destinationInfo?.cityCode || '',
              timeZone: fare.destinationInfo?.timeZone || '',
              cityName: fare.destinationInfo?.cityName || '',
              continentCode: fare.destinationInfo?.continentCode || '',
              countryCode: fare.destinationInfo?.countryCode || '',
              stationType: fare.destinationInfo?.stationType
                ? StationType[fare.destinationInfo?.stationType]
                : StationType.UNRECOGNIZED,
              sourceRefInfos: fare.destinationInfo?.sourceRefInfos || [],
              stateCode: fare.destinationInfo?.stateCode || '',
            },
            appliedPromotions: [],
            price: {
              ...fare.price,
              currencyCode: fare.price?.currencyCode || '',
              amount: fare.price?.amount || 0,
              otherCoinage: fare.price?.otherCoinage?.map((coinage) => ({
                ...coinage,
                coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
              })),
            },
          })),
        })),
      })),
    })),
    outwardJourneySummary: outwardJourneySummary
      ? {
          ...outwardJourneySummary,
          journey: {
            ...outwardJourneySummary?.journey,
            duration: {
              iso8601: outwardJourneySummary?.journey.duration.iso8601 || '',
            },
            legs:
              outwardJourneySummary?.journey.legs.map((leg) => ({
                ...leg,
                co2EmissionGramsPerPassenger: leg.co2EmissionGramsPerPassenger || 0,
                vehicle: {
                  ...leg.vehicle,
                  transportName: leg.vehicle.carrierName || '',
                  type: VehicleType[leg.vehicle.type],
                },
                departure: {
                  iso8601: leg.departure.iso8601 || '',
                },
                arrival: {
                  iso8601: leg.arrival.iso8601 || '',
                },
                duration: {
                  iso8601: leg.duration.iso8601 || '',
                },
                originPlatform: leg.originPlatform || '',
                destinationPlatform: leg.destinationPlatform || '',
                originInfo: {
                  ...leg.originInfo,
                  code: leg.originInfo?.code || '',
                  name: leg.originInfo?.name || '',
                  cityCode: leg.originInfo?.cityCode || '',
                  timeZone: leg.originInfo?.timeZone || '',
                  cityName: leg.originInfo?.cityName || '',
                  continentCode: leg.originInfo?.continentCode || '',
                  countryCode: leg.originInfo?.countryCode || '',
                  stationType: leg.originInfo?.stationType
                    ? StationType[leg.originInfo?.stationType]
                    : StationType.UNRECOGNIZED,
                  sourceRefInfos: leg.originInfo?.sourceRefInfos || [],
                  stateCode: leg.originInfo?.stateCode || '',
                },
                destinationInfo: {
                  ...leg.destinationInfo,
                  code: leg.destinationInfo?.code || '',
                  name: leg.destinationInfo?.name || '',
                  cityCode: leg.destinationInfo?.cityCode || '',
                  timeZone: leg.destinationInfo?.timeZone || '',
                  cityName: leg.destinationInfo?.cityName || '',
                  continentCode: leg.destinationInfo?.continentCode || '',
                  countryCode: leg.destinationInfo?.countryCode || '',
                  stationType: leg.destinationInfo?.stationType
                    ? StationType[leg.destinationInfo?.stationType]
                    : StationType.UNRECOGNIZED,
                  sourceRefInfos: leg.destinationInfo?.sourceRefInfos || [],
                  stateCode: leg.destinationInfo?.stateCode || '',
                },
              })) || [],
            sections:
              outwardJourneySummary?.journey.sections.map((section) => ({
                alternatives: section.alternatives.map((alternative) => ({
                  ...alternative,
                  travelClass: TravelClass[alternative.travelClass],
                  allowedFop: [],
                  nonSplitPrice: {
                    ...alternative.nonSplitPrice,
                    currencyCode: alternative.nonSplitPrice?.currencyCode || '',
                    amount: alternative.nonSplitPrice?.amount || 0,
                    otherCoinage: alternative.nonSplitPrice?.otherCoinage?.map((coinage) => ({
                      ...coinage,
                      coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
                    })),
                  },
                  fareComposition: alternative.fareComposition
                    ? FareComposition[alternative.fareComposition]
                    : FareComposition.UNRECOGNIZED,
                  amenities:
                    alternative.amenities?.map((amenity) => ({
                      ...amenity,
                      legs: [],
                      amenityKey: amenity.amenityKey || '',
                      description: amenity.description || '',
                      quantity: amenity.quantity || 0,
                      type: amenity.type
                        ? RailAmenityRailAmenityType[amenity.type]
                        : RailAmenityRailAmenityType.RAIL_AMENITY_TYPE_UNKNOWN,
                      price: {
                        ...amenity.price,
                        currencyCode: amenity.price?.currencyCode || '',
                        amount: amenity.price?.amount || 0,
                        otherCoinage: amenity.price?.otherCoinage?.map((coinage) => ({
                          ...coinage,
                          coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
                        })),
                      },
                    })) || [],
                  totalPrice: {
                    ...alternative.totalPrice,
                    currencyCode: alternative.totalPrice?.currencyCode || '',
                    amount: alternative.totalPrice?.amount || 0,
                    otherCoinage: alternative.totalPrice?.otherCoinage?.map((coinage) => ({
                      ...coinage,
                      coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
                    })),
                  },
                  discountedPrice: {
                    ...alternative.discountedPrice,
                    currencyCode: alternative.discountedPrice?.currencyCode || '',
                    amount: alternative.discountedPrice?.amount || 0,
                    otherCoinage: alternative.discountedPrice?.otherCoinage?.map((coinage) => ({
                      ...coinage,
                      coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
                    })),
                  },
                  flexibility: alternative.flexibility || '',
                  deliveryOptions: alternative.deliveryOptions.map((deliveryOption) => DeliveryOption[deliveryOption]),
                  category: SectionAlternativeCategory[alternative.category],
                  type: alternative.type || '',
                  co2EmissionGramsPerPassenger: alternative.co2EmissionGramsPerPassenger || 0,
                  fares: alternative.fares.map((fare) => ({
                    ...fare,
                    fareType: {
                      ...fare.fareType,
                      fareDetails: fare.fareType.fareDetails || [],
                      fareSummary: fare.fareType.fareSummary || '',
                    },
                    fareLegs: fare.fareLegs.map((fareLeg) => ({
                      ...fareLeg,
                      travelClass: TravelClass[fareLeg.travelClass],
                      comfortClass: fareLeg.comfortClass || '',
                      comfortClassDescription: fareLeg.comfortClassDescription || '',
                    })),
                    outwardValidity: {
                      ...fare.outwardValidity,
                      validFrom: {
                        iso8601: fare?.outwardValidity?.validFrom || '',
                      },
                      validUntil: {
                        iso8601: fare?.outwardValidity?.validUntil || '',
                      },
                    },
                    inwardValidity: {
                      ...fare.inwardValidity,
                      validFrom: {
                        iso8601: fare?.inwardValidity?.validFrom || '',
                      },
                      validUntil: {
                        iso8601: fare?.inwardValidity?.validUntil || '',
                      },
                    },
                    originStations: fare.originStations || [],
                    destinationStations: fare.destinationStations || [],
                    discountCards:
                      fare?.discountCards?.map((discountCard) => ({
                        ...discountCard,
                        cardNumber: discountCard.cardNumber || '',
                      })) || [],
                    originInfo: {
                      ...fare.originInfo,
                      code: fare.originInfo?.code || '',
                      name: fare.originInfo?.name || '',
                      cityCode: fare.originInfo?.cityCode || '',
                      timeZone: fare.originInfo?.timeZone || '',
                      cityName: fare.originInfo?.cityName || '',
                      continentCode: fare.originInfo?.continentCode || '',
                      countryCode: fare.originInfo?.countryCode || '',
                      stationType: fare.originInfo?.stationType
                        ? StationType[fare.originInfo?.stationType]
                        : StationType.UNRECOGNIZED,
                      sourceRefInfos: fare.originInfo?.sourceRefInfos || [],
                      stateCode: fare.originInfo?.stateCode || '',
                    },
                    destinationInfo: {
                      ...fare.destinationInfo,
                      code: fare.destinationInfo?.code || '',
                      name: fare.destinationInfo?.name || '',
                      cityCode: fare.destinationInfo?.cityCode || '',
                      timeZone: fare.destinationInfo?.timeZone || '',
                      cityName: fare.destinationInfo?.cityName || '',
                      continentCode: fare.destinationInfo?.continentCode || '',
                      countryCode: fare.destinationInfo?.countryCode || '',
                      stationType: fare.destinationInfo?.stationType
                        ? StationType[fare.destinationInfo?.stationType]
                        : StationType.UNRECOGNIZED,
                      sourceRefInfos: fare.destinationInfo?.sourceRefInfos || [],
                      stateCode: fare.destinationInfo?.stateCode || '',
                    },
                    appliedPromotions: [],
                    price: {
                      ...fare.price,
                      currencyCode: fare.price?.currencyCode || '',
                      amount: fare.price?.amount || 0,
                      otherCoinage: fare.price?.otherCoinage?.map((coinage) => ({
                        ...coinage,
                        coinageCode: PaymentV2ToV1Mapper[coinage.coinageCode],
                      })),
                    },
                  })),
                })),
              })) || [],
          },
        }
      : undefined,
    metadata: {
      ...metadata,
      travelClasses: metadata?.travelClasses?.map((travelClass) => TravelClass[travelClass]) || [],
      exchangeInfo: metadata?.exchangeInfo
        ? {
            ...metadata.exchangeInfo,
            exchangeType: convertRailExchangeV2ToV1(metadata?.exchangeInfo?.exchangeType),
          }
        : undefined,
    },
    navigationParams: {
      ...navigationParams,
      hasNext: navigationParams?.hasNext || false,
      hasPrevious: navigationParams?.hasPrevious || false,
    },
    ...rest,
  };
};
