import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import { getPrimaryTravelerFromPnr } from '../utils/getPrimaryTravelerFromPnr';
import type { ScheduleChangeAction } from '../types/api/v1/obt/air/air_schedule_change';
import type { FlightDetail } from '../types/api/v1/obt/common/flight_detail';
import { dateFormats, getDisplayTextUTA, IUserFacingStatusEnum } from '../constants';
import {
  convertDateFormat,
  dateUtil,
  getDateDiff,
  getDateTimeDiff,
  getDurationMinutes,
  isBefore,
  isFutureDate,
  isSame,
  minutesToDurationString,
} from '../date-utils';
import { defineMessage } from '../translations/defineMessage';
import type { Traveler, UserProfile, ITraveler } from '../types';
import { BookingFlowEnum, userFacingStatusV1ToV2 } from '../types';
import { DeviceType } from '../types/common';
import { CabinEnum, PassengerTypeEnum } from '../types/api/v1/obt/air/air_common';
import type {
  AirSearchResponse,
  DataFlight,
  MetadataFlightRestrictions,
  PaxInfoFareComponentLegInfo,
} from '../types/api/v1/obt/air/air_search_response';
import { PersonaEnum } from '../types/api/v1/obt/common/user_org_id';
import type { PaymentMetadataVirtualCardMetadata } from '../types/api/v1/obt/pnr/payment';
import type {
  AirPnr,
  AirPnrFlightInfoOtherStatus,
  AirPnrLegInfoTravelerRestrictions,
  AirPnrLegUpdate,
  AirPnrTravelerInfo,
  AirPnrVoidPolicy,
  Pnr,
  RailPnr,
  Ticket,
} from '../types/api/v1/obt/pnr/pnr';
import {
  PnrStatusV1,
  PNRType,
  TicketFlightCouponStatus,
  TicketType,
  UserFacingStatus,
} from '../types/api/v1/obt/pnr/pnr';
import type {
  LegInfo as IRailLegInfo,
  ItineraryDetails,
  LegInfo,
  SectionInfo,
} from '../types/api/v1/obt/rail/rail_common';
import { SectionInfoSectionStatus } from '../types/api/v1/obt/rail/rail_common';
import type { ApplicationContext, GetTripDetailsResponse } from '../types/api/v1/obt/trip/trip_details';
import type {
  IItineraryAirline,
  ILayoverInfo,
  ILegUTA,
  IPaxSelectedLoyalty,
  UnusedCreditInfo,
  UtaProps,
} from '../types/flight';
import { LoyaltyValidityEnum, SeatUpgradeStatus } from '../types/flight';
import type {
  IAirCancellationParameters,
  IAirPnrCard,
  ICancellationWorkflow,
  ICarCancellationParameters,
  IHotelCancellationParameters,
  IPnrChangeUsingSupport,
  IPnrFlightBrandNameBookingCodeCabin,
  ITripCarPnr,
  ITripFlightDetail,
  ITripHotelPnr,
  ITripInfo,
  ITripPnr,
  ITripRailPnr,
  ITripTravelerFlightDetails,
  ITripTravellerDetail,
  TripV3Info,
} from '../types/trip';
import {
  CancellationWorkflow,
  ChangeBySupportReasonEnum,
  IsRefundableEnum,
  IThirdPartySourceEnum,
  ITripPnrStatusEnum,
  ThirdPartySourceEnum,
  TripCancelMethodReasonEnum,
  TripCategoryPath,
} from '../types/trip';
import { getProfileDisplayText } from '../utils/common';
import { MoneyUtil } from '../utils/Money';
import type {
  GetTripDetailsDataParams,
  PnrSortingData,
  TripDetailsCardCommonData,
  TripDetailsData,
} from './TripV3DetailsResponseManager/types';
import type { PnrInfoTripAdd, TripsAddToTripEvent, TripsPnrCardActionsEvent } from '../telemetry/types/tripsEvent';
import { AirAncillariesResponseAncillaryAncillaryType } from '../types/api/v1/obt/air/air_ancillaries';
import { getTravelerBasicDetails } from '../utils/trips';
import AirSelectedItineraryResponseManager from './AirSelectedItineraryResponseManager';
import AirSearchResponseManager from './AirSearchResponseManager';
import { localizeDate } from '../translations/localizers';
import HotelDetailsManager from './HotelDetailsManager';
import { isSouthwestItin } from '../utils/Flights';
import type { UIEvent } from '../telemetry/types/baseType';
import type { Preference } from '../types/api/v1/obt/common/common';
import type { SimplePnrInfo } from '../types/api/v1/obt/pnr/simple_pnr';
import getLayoverInformationFromHiddenStop from '../utils/Flights/getLayoverInformationFromHiddenStop';

type NormalizedTripDetailsResponseData = {
  allPnrs: ITripPnr[];
  nonCancelledPnrs: ITripPnr[];
  totalCancelledPnrs: number;
  tripInfo: ITripInfo;
};

/** TODO: Dependency cycle error if this is kept in utils or transformers */
const getPersonaFromPnr = (pnr: Pnr): string => {
  return (
    pnr.air?.travelerInfo[0]?.traveler?.persona?.toString() ??
    pnr.hotel?.travelerInfo[0]?.traveler?.persona?.toString() ??
    pnr.car?.travelerInfo[0]?.traveler?.persona?.toString() ??
    pnr.rail?.itineraryDetails?.passengers[0]?.traveler?.persona?.toString() ??
    pnr.misc?.travelerInfo[0]?.traveler?.persona?.toString() ??
    pnr.limo?.travelerInfo[0]?.traveler?.persona?.toString() ??
    ''
  );
};

const personalPersona: string = PersonaEnum.PERSONAL.toString();
const adhocPersona: string = PersonaEnum.ADHOC.toString();

type ItineraryLegInfo = {
  departureAirportCode: string;
  arrivalAirportCode: string;
};

export default class V2TripDetailsResponseManager {
  private allPnrsTravelerUserProfiles: UserProfile[] = [];

  constructor(readonly response: GetTripDetailsResponse) {
    if (!response) {
      throw new Error('Invalid Trip Details Response passed to V2TripDetailsResponseManager');
    }
  }

  public GetTripId(): string {
    return this.response.tripInfo?.tripId ?? '';
  }

  public GetTripName(): string {
    return this.response.tripInfo?.name ?? '';
  }

  public GetTripStartDate(): string {
    return this.response.tripInfo?.startDate?.iso8601 ?? '';
  }

  public GetTripEndDate(): string {
    return this.response.tripInfo?.endDate?.iso8601 ?? '';
  }

  public GetTripDescription(): string {
    return this.response.tripInfo?.description ?? '';
  }

  public GetEventId(): string {
    return this.response.eventSummary?.id ?? '';
  }

  public GetApplicationContext(): ApplicationContext {
    return (
      this.response.tripInfo?.applicationContext ?? {
        applicationId: '',
        applicationName: '',
      }
    );
  }

  static SplitAirPnrIfNeeded(airPnr: Pnr): ITripPnr[] {
    const pnr = airPnr;
    if (
      !pnr.air ||
      !pnr.air.air ||
      !pnr.air.air.data.legs ||
      !(pnr.air.air.data.legs.length > 1) ||
      !(pnr.air.legInfo?.length > 1)
    ) {
      return [pnr];
    }

    const { data } = pnr.air.air;
    const travelerCopy = cloneDeep(pnr.air?.travelerInfo);
    const result: Pnr[] = [];

    // For each available leg, create a new pnr and add it to the result array
    // The split PNR will only consist of a single leg with the associated flights, tickets and seats information
    pnr.air.air.data.legs.forEach((leg) => {
      const pnrCopy = { ...cloneDeep(pnr) };
      if (pnrCopy.air?.air?.data) {
        pnrCopy.air.air.data.flights = data.flights.filter((_flight, flightIndex) =>
          leg.flightIndices.includes(flightIndex),
        );
        pnrCopy.air.air.data.legs = [leg];
        pnrCopy.air.travelerInfo =
          travelerCopy?.map((traveler) => ({
            ...traveler,
            tickets: traveler.tickets?.filter((ticket) =>
              ticket.flightCoupons.find((coupon) => leg.flightIndices.includes(coupon.flightIndex)),
            ),
            seatInfo: traveler.seatInfo.filter((seat) => leg.flightIndices.includes(seat.flightIndex)),
          })) ?? [];
        pnrCopy.air.flightInfo = pnrCopy.air.flightInfo?.filter((flightInfo) =>
          leg.flightIndices.includes(flightInfo.flightIndex),
        );
      }
      result.push(pnrCopy);
    });

    // It is possible that the same leg is repeated more than once, because it has different status under the legInfo field
    // So add that leg that many times in that case and return the final result
    // Also add the legInfoIndex so that we can trace back the original pnr leg index
    const returnResult: ITripPnr[] = [];
    pnr.air.legInfo.forEach(({ legIndex }, legInfoIndex) => {
      if (legIndex < result.length) {
        returnResult.push({ ...result[legIndex], legInfoArrayIndex: legInfoIndex });
      }
    });
    return returnResult;
  }

  static SplitAirSimplePnrIfNeeded(simplePnr: SimplePnrInfo): SimplePnrInfo[] {
    if (!simplePnr.air || !simplePnr.air.legs || !(simplePnr.air.legs.length > 1)) {
      return [simplePnr];
    }

    const result: SimplePnrInfo[] = [];

    // For each available leg, create a new simple pnr and add it to the result array
    // The split simple PNR will only consist of a single leg with the associated flights
    simplePnr.air.legs.forEach((leg) => {
      const simplePnrCopy = { ...cloneDeep(simplePnr) };
      if (simplePnrCopy.air) {
        simplePnrCopy.air.legs = [leg];
      }
      result.push(simplePnrCopy);
    });

    return result;
  }

  static getPriorityIndexOfPnr(pnr: Pnr): number {
    let priorityIndex = 0;
    if (pnr.hotel) {
      priorityIndex = pnr.hotel?.sortingPriority ?? 0;
    } else if (pnr.car) {
      priorityIndex = pnr.car?.sortingPriority ?? 0;
    } else if (pnr.air) {
      const legIndex = this.GetAirLegInfoIndexFromSplitPnr(pnr);
      priorityIndex = pnr.air.legInfo?.[legIndex]?.sortingPriority ?? 0;
    } else if (pnr.limo) {
      // Limo Pnrs are split into individual items if necessary(more than 1 legs)
      // each splitted pnr will have only one leg in the limoInfo.legs array
      priorityIndex = pnr.limo.limoInfo.legs[0].sortingPriority ?? 0;
    } else if (pnr.rail) {
      priorityIndex =
        pnr.rail.itineraryDetails?.inwardJourney?.sortingPriority ??
        pnr.rail.itineraryDetails?.outwardJourney?.sortingPriority ??
        0;
    } else if (pnr.misc) {
      priorityIndex = pnr.misc.sortingPriority ?? 0;
    }
    return priorityIndex;
  }

  static getPriorityIndexOfSimplePnr(simplePnr: SimplePnrInfo): number {
    // these are splitted simple pnr, therefore using 0 index for air and rail
    let priorityIndex = 0;
    if (simplePnr?.air && simplePnr?.air?.legs?.length > 0) {
      priorityIndex = simplePnr?.air?.legs[0].sortingPriority ?? 0;
    }
    // TODO: Uncomment when other PNR types are implemented
    // else if (simplePnr.hotel) {
    //   priorityIndex = simplePnr.hotel?.sortingPriority ?? 0;
    // } else if (simplePnr.car) {
    //   priorityIndex = simplePnr.car?.sortingPriority ?? 0;
    // } else if (simplePnr.rail) {
    //   // TODO: Check this when implementing rail
    //   priorityIndex = simplePnr.rail?.sections[0].legs[0].sortingPriority ?? 0;
    // }
    return priorityIndex;
  }

  /**
   * Gets the rail itinerary status based on the section status.
   * @param section - The rail section to determine the status from.
   * @param itinerary - The rail itinerary to fallback to if the section status is unknown.
   * @returns The rail itinerary status.
   */
  static getRailItineraryStatusFromSection(section: SectionInfo, itinerary: RailPnr | undefined) {
    switch (section.sectionStatus) {
      case UserFacingStatus.CONFIRMED_STATUS:
        return PnrStatusV1.CONFIRMED;
      case UserFacingStatus.CANCELLED_STATUS:
        return PnrStatusV1.CANCELLED;
      default:
        return itinerary?.status || PnrStatusV1.UNKNOWN;
    }
  }

  static getNewItineraryUsingLegs(
    legs: number[],
    legInfos: LegInfo[],
    itineraryDetails: ItineraryDetails,
    section: SectionInfo,
    isOutwardJourney: boolean,
    sectionIndex: number,
  ): ItineraryDetails {
    const { tickets } = itineraryDetails;
    const { originInfo, departAt } = legInfos[legs[0]];
    const { destinationInfo, arriveAt } = legInfos[legs[legs.length - 1]];
    const newSection = {
      ...section,
      fares: section.fares.filter((fare) => legs.includes(fare.fareLegs[0].legIndex)),
    };
    const wasSectionExchanged =
      itineraryDetails.exchangeInfo?.relatedSectionInfo?.newSectionIndexes?.includes(sectionIndex);
    const journeyKey = isOutwardJourney ? 'outwardJourney' : 'inwardJourney';
    // Create a new itinerary based on the current section.
    return {
      ...itineraryDetails,
      sections: [newSection], // Set the sections to the current section.
      tickets: tickets.filter((ticket) => legs.includes(ticket.legs[0])), // Filter relevant tickets for this section.
      [isOutwardJourney ? 'inwardJourney' : 'outwardJourney']: undefined,
      previousItineraryDetails: itineraryDetails.previousItineraryDetails
        ? {
            ...itineraryDetails.previousItineraryDetails,
            sections: wasSectionExchanged ? itineraryDetails?.previousItineraryDetails?.sections || [] : [],
          }
        : undefined,
      [journeyKey]: {
        ...itineraryDetails[journeyKey],
        arriveAt,
        departAt,
        legs,
        originInfo,
        destinationInfo,
        origin: originInfo?.name || '',
        destination: destinationInfo?.name || '',
        journeyStatus: section.sectionStatus ?? itineraryDetails[journeyKey]?.journeyStatus,
      },
    };
  }

  /**
   * Process the itinerary details to create separate itineraries for each section.
   *
   * @param itineraryDetails - The original itinerary details to be processed.
   * @returns An array of processed itinerary details, one for each section within the original itinerary.
   */
  static processItinerary(itineraryDetails: ItineraryDetails): ItineraryDetails[] {
    const processedItinerary: ItineraryDetails[] = [];

    // Extract necessary data from the original itinerary details.
    const { inwardJourney, sections, legInfos } = itineraryDetails;
    const inwardJourneyFirstLeg: number = inwardJourney?.legs[0] || Infinity;

    sections.forEach((section, sectionIndex) => {
      const { fares } = section;

      // Extract leg indices from the fares and flatten the result.
      const legs =
        fares
          .map((fare) => fare.fareLegs.map((fareLeg) => fareLeg.legIndex))
          .flat()
          .sort((a, b) => a - b) || [];

      if (legs[0] < inwardJourneyFirstLeg && itineraryDetails.outwardJourney) {
        const outwardLegs = legs.filter((leg) => leg < inwardJourneyFirstLeg);
        // Create a new itinerary based on the current section.
        const newItinerary: ItineraryDetails = this.getNewItineraryUsingLegs(
          outwardLegs,
          legInfos,
          itineraryDetails,
          section,
          true,
          sectionIndex,
        );
        // Add the processed itinerary to the result.
        processedItinerary.push(newItinerary);
      }
      if (legs[legs.length - 1] >= inwardJourneyFirstLeg && itineraryDetails.inwardJourney) {
        const inwardLegs = legs.filter((leg) => leg >= inwardJourneyFirstLeg);
        // Create a new itinerary based on the current section.
        const newItinerary: ItineraryDetails = this.getNewItineraryUsingLegs(
          inwardLegs,
          legInfos,
          itineraryDetails,
          section,
          false,
          sectionIndex,
        );
        // Add the processed itinerary to the result.
        processedItinerary.push(newItinerary);
      }
    });

    return processedItinerary;
  }

  /**
   * Splits a rail PNR if needed based on its sections.
   * @param pnr - The rail PNR to potentially split.
   * @returns An array of ITripPnr objects.
   */
  static SplitRailPnrIfNeeded(pnr: Pnr): ITripPnr[] {
    // Check if the rail PNR has sections within its itinerary details and contains at least one section.
    if (pnr?.rail?.itineraryDetails?.sections && pnr?.rail?.itineraryDetails?.sections?.length > 0) {
      // Process each section of the itinerary within the rail PNR.
      const itineraries = this.processItinerary(pnr.rail.itineraryDetails);
      // Map each processed itinerary to a new trip PNR and return the resulting array of trip PNRs.
      return itineraries.map((itinerary) => ({
        ...pnr,
        rail: {
          ...pnr.rail,
          itineraryDetails: itinerary, // Update the itinerary details in the rail section.
          status: this.getRailItineraryStatusFromSection(itinerary.sections[0], pnr.rail),
        },
        bookingStatus: itinerary.sections[0].sectionStatus ?? pnr.bookingStatus,
        legInfoArrayIndex: itinerary.outwardJourney?.legs?.[0] ?? itinerary.inwardJourney?.legs?.[0] ?? 0,
      }));
    }
    // For one-way trips (do not have an inward journey) or if itinerary details are not present,
    // no need to split the PNR. Return a single trip PNR.
    if (!pnr.rail?.itineraryDetails || !pnr.rail.itineraryDetails.inwardJourney) {
      return [
        {
          ...pnr,
          legInfoArrayIndex: pnr.rail?.itineraryDetails?.outwardJourney?.legs?.[0] ?? 0,
        },
      ];
    }

    return [
      {
        ...pnr,
        rail: {
          ...pnr.rail,
          itineraryDetails: {
            ...(pnr.rail.itineraryDetails ?? {}),
            inwardJourney: undefined,
          },
        },
        legInfoArrayIndex: pnr.rail.itineraryDetails?.outwardJourney?.legs?.[0] ?? 0,
      },
      {
        ...pnr,
        rail: {
          ...pnr.rail,
          itineraryDetails: {
            ...(pnr.rail.itineraryDetails ?? {}),
            outwardJourney: undefined,
          },
        },
        legInfoArrayIndex: pnr.rail.itineraryDetails?.inwardJourney?.legs?.[0] ?? 1,
      },
    ];
  }

  static SplitLimoPnrIfNeeded(pnr: Pnr): ITripPnr[] {
    if (!pnr.limo) {
      return [pnr];
    }

    const pnrLimo = pnr.limo;
    // Split limos cards into cards (by each leg)
    return pnrLimo.limoInfo.legs.map((limoLeg, legIdx) => ({
      ...pnr,
      limo: { ...pnrLimo, limoInfo: { ...pnrLimo.limoInfo, legs: [limoLeg] } },
      legInfoArrayIndex: legIdx,
    }));
  }

  static GetPnrStartDate(pnr?: ITripPnr): string {
    let start = '';
    if (!pnr) {
      return start;
    }
    if (pnr.air) {
      start = first(pnr.air.air?.data.flights)?.departureDateTime.iso8601 ?? '';
    }
    if (pnr.hotel) {
      start = `${pnr.hotel?.hotel?.occupancyDates?.checkInDate?.iso8601 ?? ''}T${
        pnr.hotel?.hotel?.hotelSpec?.checkInTime?.iso8601 ?? ''
      }`;
    }
    if (pnr.car) {
      start = pnr.car.car?.pickup?.iso8601 ?? '';
    }
    if (pnr.rail) {
      // eslint-disable-next-line no-restricted-syntax
      const legInfo = V2TripDetailsResponseManager.GetRailPnrLegInfo(pnr);
      start = legInfo?.departAt?.iso8601 ?? '';
    }
    if (pnr.limo) {
      start = pnr.limo.limoInfo.legs[0].pickupDateTime.iso8601 ?? '';
    }
    if (pnr.misc) {
      start = pnr.misc.miscInfo?.startDateTime?.iso8601 ?? '';
    }

    return start;
  }

  static GetPnrEndDate(pnr?: ITripPnr): string {
    let end = '';
    if (!pnr) {
      return end;
    }
    if (pnr.air) {
      end = last(pnr.air.air?.data.flights)?.arrivalDateTime.iso8601 ?? '';
    }
    if (pnr.hotel) {
      end = `${pnr.hotel?.hotel?.occupancyDates?.checkOutDate?.iso8601 ?? ''}T${
        pnr.hotel?.hotel?.hotelSpec?.checkOutTime?.iso8601 ?? ''
      }`;
    }
    if (pnr.car) {
      end = pnr.car.car?.drop?.iso8601 ?? '';
    }
    if (pnr.rail) {
      // eslint-disable-next-line no-restricted-syntax
      const legInfo = V2TripDetailsResponseManager.GetRailPnrLegInfo(pnr);
      end = legInfo?.arriveAt?.iso8601 ?? '';
    }
    if (pnr.limo) {
      end = last(pnr.limo.limoInfo.legs)?.pickupDateTime.iso8601 ?? '';
    }
    if (pnr.misc) {
      end = pnr.misc.miscInfo?.endDateTime?.iso8601 ?? '';
    }

    return end;
  }

  static GetRailPnrLegInfo(pnr?: ITripPnr): IRailLegInfo | undefined {
    const legIndex = pnr?.legInfoArrayIndex ?? 0;
    return pnr?.rail?.itineraryDetails?.legInfos[legIndex];
  }

  public getTripUrlType(): TripCategoryPath {
    const status = this.response.tripStatus;
    if (status === UserFacingStatus.CANCELLED_STATUS) {
      return TripCategoryPath.Cancelled;
    }
    const endDate = this.GetTripEndDate();
    if (
      status === UserFacingStatus.COMPLETED_STATUS ||
      (status === UserFacingStatus.PENDING_STATUS && !isFutureDate(endDate))
    ) {
      return TripCategoryPath.Past;
    }
    return TripCategoryPath.Upcoming;
  }

  public isPastTrip(): boolean {
    return this.getTripUrlType() === TripCategoryPath.Past;
  }

  public getTravelersInfo(pnrId: string): ITraveler[] {
    const travelers = this.getAirTravelers(pnrId).map((traveler) => traveler.traveler);
    const travelersInfo = travelers.filter((traveler): traveler is ITraveler => !!traveler);
    return travelersInfo;
  }

  public isPastOrCancelledTrip(): boolean {
    const status = this.response.tripStatus;
    const endDate = this.GetTripEndDate();
    if (
      status === UserFacingStatus.COMPLETED_STATUS ||
      status === UserFacingStatus.CANCELLED_STATUS ||
      (status === UserFacingStatus.PENDING_STATUS && !isFutureDate(endDate))
    ) {
      return true;
    }
    return false;
  }

  public getAllFlightCreditsForPnr(pnrId: string): UnusedCreditInfo[] {
    const pnr = this.getPnrFromPnrId(pnrId);
    const allTickets = pnr?.air?.travelerInfo.flatMap((traveler) => traveler.lastConfirmedTickets);

    if (!allTickets) {
      return [];
    }

    const allFlightCredits = allTickets.map((ticket) => ticket.createdUnusedCredit);
    const filteredFlightCredits = allFlightCredits.filter(
      (flightCredit) => flightCredit !== undefined,
    ) as UnusedCreditInfo[];

    return filteredFlightCredits;
  }

  public GetSortedPnrDetails({
    includePendingManualFormPnrs = false,
  }: { includePendingManualFormPnrs?: boolean } = {}): ITripPnr[] {
    let pnrs: ITripPnr[] = this.response.pnr;

    /**
     * !! Attention
     * At the moment this works with (and applies to) hotel PNRs only
     */
    if (includePendingManualFormPnrs) {
      pnrs = pnrs.concat(
        this.response.pendingManualFormPnrs.map((pnr) => {
          return produce(pnr, (draft: ITripPnr) => {
            /**
             * Each pending manual form pnr should have `incomplete: true` flag
             */
            draft.incomplete = true;
          });
        }),
      );
    }

    return pnrs
      .map((pnr) => {
        let splitPnrs: Pnr[] = [pnr];
        if (pnr.air) {
          // eslint-disable-next-line no-restricted-syntax
          splitPnrs = [...V2TripDetailsResponseManager.SplitAirPnrIfNeeded(pnr)];
        } else if (pnr.rail) {
          // eslint-disable-next-line no-restricted-syntax
          splitPnrs = [...V2TripDetailsResponseManager.SplitRailPnrIfNeeded(pnr)];
        } else if (pnr.limo) {
          // eslint-disable-next-line no-restricted-syntax
          splitPnrs = [...V2TripDetailsResponseManager.SplitLimoPnrIfNeeded(pnr)];
        }
        return splitPnrs;
      })
      .flat()
      .sort((firstPnr, secondPnr) => {
        // eslint-disable-next-line no-restricted-syntax
        const firstPnrPriority = V2TripDetailsResponseManager.getPriorityIndexOfPnr(firstPnr);
        // eslint-disable-next-line no-restricted-syntax
        const secondPnrPriority = V2TripDetailsResponseManager.getPriorityIndexOfPnr(secondPnr);
        return firstPnrPriority - secondPnrPriority;
      })
      .filter(
        (pnr) =>
          !pnr.air?.air ||
          (pnr.air?.air?.itineraries[0]?.rateOptions && pnr.air?.air?.itineraries[0]?.rateOptions.length > 0),
      );
  }

  static GetNonCancelledPnrs(allPnrs: ITripPnr[]): ITripPnr[] {
    return allPnrs.filter((pnr) => {
      if (pnr.air) {
        // eslint-disable-next-line no-restricted-syntax
        const { legStatus } = V2TripDetailsResponseManager.GetAirPnrCardDetails(pnr);
        return !(legStatus === UserFacingStatus.CANCELLED_STATUS || legStatus === UserFacingStatus.VOIDED_STATUS);
      }
      // eslint-disable-next-line no-restricted-syntax
      const status = V2TripDetailsResponseManager.GetPnrStatus(pnr);
      // eslint-disable-next-line no-restricted-syntax
      return !V2TripDetailsResponseManager.IsPnrStatusCancelled(status);
    });
  }

  static isInwardJourneyLegId(pnr: ITripPnr, legId: string): boolean {
    const legInfoIndex = pnr.rail?.itineraryDetails?.legInfos.findIndex((leg) => leg.legId === legId);
    return pnr.rail?.itineraryDetails?.inwardJourney?.legs[0] === legInfoIndex;
  }

  static GetTotalCancelledPnrs(allPnrs: ITripPnr[], nonCancelledPnrs: ITripPnr[]): number {
    return Math.abs(allPnrs.length - nonCancelledPnrs.length);
  }

  static IsPersonalPnr(pnr: ITripPnr): boolean {
    return pnr.air?.travelerInfo[0].traveler?.persona?.toString() === personalPersona;
  }

  static GetVirtualCardMetadata(pnr: ITripPnr): PaymentMetadataVirtualCardMetadata | undefined {
    return first(pnr.costOfGoodsSold?.payments)?.fop?.paymentMetadata?.virtualCardMetadata;
  }

  /** All persona in an adhoc trip, Multipax or not, will be same, and will
   *  be equal to ADHOC. And, Adhoc traveler will be the Primary traveler */
  public isAdhocTrip(): boolean {
    const allPrimaryTravellersPersonas: string[] = this.response.pnr.map((pnr) => getPersonaFromPnr(pnr));
    return allPrimaryTravellersPersonas.length
      ? allPrimaryTravellersPersonas.every((persona) => persona === adhocPersona)
      : false;
  }

  /** If adhoc user's profile was saved even once, one should be able to see
   * Add flight, car, etc buttons on trip details page for that adhoc user trip
   */
  public isAdhocUserSaved(): boolean {
    // eslint-disable-next-line no-restricted-syntax
    return V2TripDetailsResponseManager.getPrimaryTravelerFromTrip(this.response)?.adhocUserInfo?.isSaved || false;
  }

  private isPersonalTrip(): boolean {
    const allPrimaryTravellersPersonas: string[] = this.response.pnr.map((pnr) => getPersonaFromPnr(pnr));
    return allPrimaryTravellersPersonas.length
      ? allPrimaryTravellersPersonas.every((persona) => persona === personalPersona)
      : false;
  }

  public GetTripInfo(): ITripInfo {
    return {
      info: this.response.tripInfo,
      start: this.GetTripStartDate(),
      end: this.GetTripEndDate(),
      itineraryNo: this.GetTripId(),
      status: this.response.tripStatus,
      isPersonalTrip: this.isPersonalTrip(),
      isAdhocTrip: this.isAdhocTrip(),
      applicationContext: this.GetApplicationContext(),
      isAdhocUserSaved: this.isAdhocUserSaved(),
    };
  }

  public static GetPrimaryTraveler(pnr: Pnr): ITraveler | null {
    if (pnr.air !== undefined) {
      return pnr.air.travelerInfo[0]?.traveler ?? null;
    }

    if (pnr.hotel !== undefined) {
      return pnr.hotel.travelerInfo[0]?.traveler ?? null;
    }

    if (pnr.car !== undefined) {
      return pnr.car.travelerInfo[0]?.traveler ?? null;
    }

    if (pnr.rail !== undefined) {
      return pnr.rail.itineraryDetails?.passengers[0]?.traveler ?? null;
    }

    if (pnr.limo !== undefined) {
      return pnr.limo.travelerInfo[0]?.traveler ?? null;
    }

    if (pnr.misc !== undefined) {
      return pnr.misc.travelerInfo[0]?.traveler ?? null;
    }

    return null;
  }

  /** Returns UserId Id since we do not get userOrgId in tripDetails incase of Adhoc booking */
  public getProfileOwnerUserIdIdFromTrip(): string | undefined {
    /** Guest incase of Adhoc Trip */
    // eslint-disable-next-line no-restricted-syntax
    const primaryTraveler = V2TripDetailsResponseManager.getPrimaryTravelerFromTrip(this.response);
    /** Guest's owner */
    const adhocUserProfileOwnerUserId = primaryTraveler?.adhocUserInfo?.profileOwner?.userId?.id;
    const isAdhocTrip = this.isAdhocTrip();
    /** Return Guest's owner incase of Adhoc Trip */
    return isAdhocTrip ? adhocUserProfileOwnerUserId : undefined;
  }

  public static getPrimaryTravelerFromTrip(response: GetTripDetailsResponse): ITraveler | undefined {
    const traveler = response.pnr.map(getPrimaryTravelerFromPnr)[0];
    return traveler;
  }

  public getTripPrimaryTraveler() {
    // eslint-disable-next-line no-restricted-syntax
    return V2TripDetailsResponseManager.getPrimaryTravelerFromTrip(this.response);
  }

  private static getBrandNameBookingCodeAndCabinInfo(
    pnr: ITripPnr,
    legInfoIndex: number,
  ): IPnrFlightBrandNameBookingCodeCabin[] {
    const legInfo = pnr.air?.air?.itineraries[0].rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legInfoIndex];
    const brandNameBookingCodeCabinArray: IPnrFlightBrandNameBookingCodeCabin[] = [];
    legInfo?.flightInfo.forEach((flightDetail) => {
      brandNameBookingCodeCabinArray.push({
        brandName: legInfo?.brandName ?? '',
        bookingCode: flightDetail.bookingCode,
        cabin: CabinEnum[flightDetail.cabin],
      });
    });
    return brandNameBookingCodeCabinArray;
  }

  // Calculates total duration of leg(flight time + layover)
  private static calculateLegDuration(pnr: ITripPnr): number {
    // Calculating total flight time
    const flightDuration = !pnr.air?.air?.data.flights
      ? 0
      : pnr.air?.air?.data.flights.reduce(
          (currentDuration, flight) => currentDuration + getDurationMinutes(flight.duration.iso8601),
          0,
        );

    // Calculating total layover time
    let layoverDuration = 0;
    if (pnr.air?.air?.data.flights) {
      for (let i = 0; i < pnr.air?.air?.data.flights.length - 1; i += 1) {
        layoverDuration += getDateTimeDiff(
          pnr.air?.air?.data.flights[i + 1].departureDateTime.iso8601,
          pnr.air?.air?.data.flights[i].arrivalDateTime.iso8601,
        );
      }
    }

    return flightDuration + layoverDuration;
  }

  static GetFlightDetailsWithUpdates(pnr: ITripPnr, legUpdate?: AirPnrLegUpdate): ITripFlightDetail[] {
    // eslint-disable-next-line no-restricted-syntax
    const brandNameBookingCodeCabinInfo = V2TripDetailsResponseManager.getBrandNameBookingCodeAndCabinInfo(
      pnr,
      // eslint-disable-next-line no-restricted-syntax
      V2TripDetailsResponseManager.GetAirLegInfoIndexFromSplitPnr(pnr),
    );
    if (!pnr.air?.air) {
      return [];
    }

    const airPnr: AirSearchResponse = pnr.air.air;
    const airSearchResponseManager = new AirSearchResponseManager(airPnr);

    if (!airSearchResponseManager) {
      return [];
    }

    const details: ITripFlightDetail[] = airPnr.data.flights.map((flight, flightNumber) => {
      const flightInfo = airSearchResponseManager.getFlightInfoForFlightIndex(flightNumber);
      const hiddenStops = airPnr.data.flights[flightNumber].hiddenStops || [];
      const hiddenLayovers: ILayoverInfo[] = [];
      hiddenStops.forEach((stopInfo) => {
        hiddenLayovers.push(getLayoverInformationFromHiddenStop(stopInfo));
      });

      const {
        arrivalDateTime,
        departureDateTime,
        arrivalAirport,
        arrivalAirportTerminal,
        arrivalAirportName,
        arrivalAirportCity,
        arrivalCountryCode,
        departureAirport,
        departureCountryCode,
        departureAirportTerminal,
        departureAirportName,
        departureAirportCity,
        marketingAirlineLabel,
        operatingAirlineLabel,
        duration,
        airline,
      } = flightInfo;

      const flightUpdates = pnr.air?.flightInfo[flightNumber]?.flightUpdates;
      const flightSourceStatus = pnr.air?.flightInfo[flightNumber]?.sourceStatus;

      // Arrival DateTime
      const arrivalDateTimePrevious = flightUpdates?.previousArrivalDateTime?.iso8601;
      const isArrivalDateTimeChanged =
        arrivalDateTimePrevious && !isSame(arrivalDateTime, arrivalDateTimePrevious, dateFormats.ISO);
      const isArrivalDateTimeDelayed =
        arrivalDateTimePrevious && isBefore(arrivalDateTimePrevious, arrivalDateTime, dateFormats.ISO);
      const arrivalDateTimeDelayMinutes =
        isArrivalDateTimeDelayed && getDateTimeDiff(arrivalDateTime, arrivalDateTimePrevious, dateFormats.ISO);

      // Departure DateTime
      const departureDateTimePrevious = flightUpdates?.previousDepartureDateTime?.iso8601;
      const isDepartureDateTimeChanged =
        departureDateTimePrevious && !isSame(departureDateTime, departureDateTimePrevious, dateFormats.ISO);
      const isDepartureDateTimeDelayed =
        departureDateTimePrevious && isBefore(departureDateTimePrevious, departureDateTime, dateFormats.ISO);
      const departureDateTimeDelayMinutes =
        isDepartureDateTimeDelayed && getDateTimeDiff(departureDateTime, departureDateTimePrevious, dateFormats.ISO);

      // Departure Gate
      const departureAirportGate = flight.origin.gate;
      const departureAirportGatePrevious = flightUpdates?.previousDepartureGate?.gate;
      const isDepartureAirportGateChanged =
        departureAirportGatePrevious && departureAirportGate !== departureAirportGatePrevious;

      // Departure Terminal
      const departureAirportTerminalPrevious = flightUpdates?.previousDepartureGate?.terminal;
      const isDepartureAirportTerminalChanged =
        departureAirportTerminalPrevious && departureAirportTerminal !== departureAirportTerminalPrevious;

      // Arrival Terminal
      const arrivalAirportTerminalPrevious = flightUpdates?.previousArrivalGate?.gate;
      const isArrivalAirportTerminalChanged =
        arrivalAirportTerminalPrevious && arrivalAirportTerminal !== arrivalAirportTerminalPrevious;

      // Connection Risks
      const connectionAlert = legUpdate?.connectionAlerts.find((cr) => cr.departingFlightIndex === flightNumber);
      const isHavingConnectionRisk = !!connectionAlert;
      const { minConnectionDuration, previousConnectionDuration, updatedConnectionDuration } = connectionAlert || {};

      // Is Flight Cancelled (by Vendor)
      const isFlightCancelled = pnr.air?.flightInfo[flightNumber]?.status === ITripPnrStatusEnum.CANCELLED_BY_VENDOR;

      // Is Flight Reinstated
      const isFlightReinstated = pnr.air?.flightInfo[flightNumber]?.status === ITripPnrStatusEnum.REINSTATED;

      const flightIndex = pnr.air?.flightInfo[flightNumber]?.flightIndex ?? 0;

      return {
        arrivalCountryCode,
        arrivalDateTime,
        arrivalDateTimePrevious,
        isArrivalDateTimeChanged,
        isArrivalDateTimeDelayed,
        arrivalDateTimeDelayMinutes,

        departureCountryCode,
        departureDateTime,
        departureDateTimePrevious,
        isDepartureDateTimeChanged,
        isDepartureDateTimeDelayed,
        departureDateTimeDelayMinutes,

        duration,
        departureAirport,
        departureAirportName,
        departureAirportCityName: departureAirportCity,
        departureAirportTerminal,
        departureAirportTerminalPrevious,
        isDepartureAirportTerminalChanged,
        departureAirportGate,
        departureAirportGatePrevious,
        isDepartureAirportGateChanged,

        arrivalAirport,
        arrivalAirportName,
        arrivalAirportCityName: arrivalAirportCity,
        arrivalAirportTerminal,
        arrivalAirportTerminalPrevious,
        isArrivalAirportTerminalChanged,

        cabinType: brandNameBookingCodeCabinInfo[flightNumber]?.cabin,
        marketingAirlineLabel,
        marketing: airline.marketing,
        operating: airline.operating,
        operatingAirlineLabel,
        marketingAirLine: flight.marketing.airline,
        brandName: brandNameBookingCodeCabinInfo[flightNumber]?.brandName,
        bookingCode: brandNameBookingCodeCabinInfo[flightNumber]?.bookingCode,

        isHavingConnectionRisk,
        minConnectionDurationIso8601: minConnectionDuration?.iso8601,
        previousConnectionDurationIso8601: previousConnectionDuration?.iso8601,
        updatedConnectionDurationIso8601: updatedConnectionDuration?.iso8601,

        isFlightCancelled,
        isFlightReinstated,
        flightIndex,
        flightSourceStatus,
        hiddenLayovers,
      } as ITripFlightDetail;
    });

    return details;
  }

  private static GetTicketNumber(
    legIndex: number,
    flightIndex: number,
    travelerInfo: AirPnrTravelerInfo | undefined,
  ): string {
    if (!travelerInfo?.tickets || travelerInfo?.tickets.length === 0) return '';

    let ticketNumber = '';
    travelerInfo.tickets.forEach((ticket) => {
      const isFlightOrUnknownTicket = [TicketType.UNKNOWN_TICKET_TYPE, TicketType.FLIGHT].includes(ticket.ticketType);

      if (isFlightOrUnknownTicket && ticket?.flightCoupons?.length) {
        ticket.flightCoupons.forEach((flightCoupon) => {
          if (flightCoupon.legIndex === legIndex && flightIndex === flightCoupon.flightIndex) {
            ticketNumber = ticket.number;
          }
        });
      }
    });
    return ticketNumber;
  }

  private static GetCouponStatus(
    legIndex: number,
    flightIndex: number,
    travelerInfo: AirPnrTravelerInfo | undefined,
  ): string {
    if (!travelerInfo?.tickets || travelerInfo?.tickets.length === 0) return '';

    let couponStatus = '';
    travelerInfo.tickets.forEach((ticket) => {
      const isFlightOrUnknownTicket = [TicketType.UNKNOWN_TICKET_TYPE, TicketType.FLIGHT].includes(ticket.ticketType);

      if (isFlightOrUnknownTicket && ticket?.flightCoupons?.length) {
        ticket.flightCoupons.forEach((flightCoupon) => {
          if (flightCoupon.legIndex === legIndex && flightCoupon.flightIndex === flightIndex) {
            couponStatus = flightCoupon.sourceStatus;
          }
        });
      }
    });
    return couponStatus;
  }

  static GetFlightTravellerFlightDetails(
    flight: DataFlight,
    flightIndex: number | undefined,
    travelerInfo: AirPnrTravelerInfo | undefined,
    legIndex: number,
    otherStatuses: AirPnrFlightInfoOtherStatus[],
  ): ITripTravelerFlightDetails {
    const { airline } = flight.marketing;
    return {
      ticketNumber: this.GetTicketNumber(legIndex, flightIndex || 0, travelerInfo),
      couponStatus: this.GetCouponStatus(legIndex, flightIndex || 0, travelerInfo),
      loyalty: travelerInfo?.traveler?.travelerPersonalInfo?.loyaltyInfos.find((loyalty) =>
        loyalty.appliedTo.includes(airline),
      ),
      seat: travelerInfo?.seatInfo.find((seat) => seat.flightIndex === flightIndex)?.number ?? '',
      seatInfo: travelerInfo?.seatInfo.find((seat) => seat.flightIndex === flightIndex),
      earlyBirdInfo: travelerInfo?.otherAncillaries?.find(
        (ancillaryInfo) =>
          ancillaryInfo.flightIds.find(
            (flightId) => flightId.flightIndex === flightIndex && flightId.legIndex === legIndex,
          ) && ancillaryInfo.type === AirAncillariesResponseAncillaryAncillaryType.EARLY_BIRD,
      ),
      origin: flight.origin.airport,
      destination: flight.destination.airport,
      upgradeStatus: this.getFlightUpgradeStatus(otherStatuses),
    };
  }

  static getFlightUpgradeStatus(otherStatuses: AirPnrFlightInfoOtherStatus[]): SeatUpgradeStatus {
    if (otherStatuses.length === 0) {
      return SeatUpgradeStatus.NONE;
    }
    const sortedStatuses = otherStatuses.sort((statusA, statusB) => statusB.cabin - statusA.cabin);
    const highestWaitlistedStatusCabin =
      sortedStatuses.filter((upgradeStatus) => upgradeStatus.status === PnrStatusV1.WAITLISTED)[0]?.cabin || -1;
    const highestConfirmedStatusCabin =
      sortedStatuses.filter((upgradeStatus) => upgradeStatus.status === PnrStatusV1.CONFIRMED)[0]?.cabin || -1;
    if (highestWaitlistedStatusCabin > highestConfirmedStatusCabin) {
      return SeatUpgradeStatus.UPGRADE_REQUESTED;
    }
    return SeatUpgradeStatus.NONE;
  }

  static GetFlightTravellerDetails(pnr: Pnr, legIndex: number): ITripTravellerDetail[] {
    const details =
      pnr.air?.travelerInfo.map((travelerDetails) => {
        const { traveler, paxType, specialServiceRequestInfos } = travelerDetails;
        const allLegFlightIndices = pnr.air?.air?.data.legs?.flatMap((l) => l.flightIndices);
        return {
          name: traveler?.user?.name,
          paxType: paxType ?? PassengerTypeEnum.UNKNOWN_PASSENGER_TYPE,
          persona: traveler?.persona,
          redressNumber: traveler?.user?.identityDocs.find((doc) => doc.redressNumber)?.redressNumber ?? '',
          redress: traveler?.user?.identityDocs.find((doc) => doc.redress)?.redress,
          tsaNumber: traveler?.user?.identityDocs.find((doc) => doc.knownTravelerNumber)?.knownTravelerNumber ?? '',
          ktn: traveler?.user?.identityDocs.find((doc) => doc.ktn)?.ktn,
          flightTravellerDetails:
            pnr.air?.air?.data.flights.map((flight, flightIndex) =>
              // eslint-disable-next-line no-restricted-syntax
              V2TripDetailsResponseManager.GetFlightTravellerFlightDetails(
                flight,
                allLegFlightIndices?.[flightIndex],
                travelerDetails,
                legIndex,
                pnr.air?.flightInfo[flightIndex]?.otherStatuses || [],
              ),
            ) ?? [],
          email: traveler?.user?.email,
          profilePhoto: traveler?.user?.profilePicture?.url ?? '',
          tier: traveler?.tier,
          userOrgId: traveler?.userOrgId,
          itinSource: first(pnr.air?.air?.itineraries)?.rateOptions[0].source ?? ThirdPartySourceEnum.UNKNOWN_SOURCE,
          specialServiceRequests: specialServiceRequestInfos,
          otherServiceInfos: pnr.air?.otherServiceInfos,
          originalTraveler: traveler,
        };
      }) ?? [];
    return details;
  }

  // Only use this method with a split pnr
  static GetAirOriginalLegIndexFromSplitPnr(splitPnr: ITripPnr): number {
    // Leg index can be found if we have the legInfoIndex from the below contract definition
    // eslint-disable-next-line no-restricted-syntax
    const legInfoIndex = V2TripDetailsResponseManager.GetAirLegInfoIndexFromSplitPnr(splitPnr);
    return splitPnr.air?.legInfo?.[legInfoIndex]?.legIndex ?? 0;
  }

  // Only use this method with a split pnr
  static GetAirLegInfoIndexFromSplitPnr(splitPnr: ITripPnr): number {
    // Air split pnr will always have legInfoIndex, but split pnr for rail doesn't need this information, thus it has `Pnr` type without the legInfoIndex as its return type. To allow both these data types to be returned when all pnrs are sorted (returntype of GetSortedPnrDetails) we have to allow legInfoIndex to be undefined, thus null coalescing to zero is required.
    return splitPnr.legInfoArrayIndex ?? 0;
  }

  static GetAirLegStatusFromSplitPnr(splitPnr: ITripPnr): UserFacingStatus {
    // eslint-disable-next-line no-restricted-syntax
    const legInfoIndex = V2TripDetailsResponseManager.GetAirLegInfoIndexFromSplitPnr(splitPnr);
    const legInfo = splitPnr.air?.legInfo?.[legInfoIndex];
    return legInfo?.legStatus ?? UserFacingStatus.UNKNOWN_STATUS;
  }

  public GetItineraryAllAirlines(sourcePnrId: string): IItineraryAirline[] {
    const pnr = this.response.pnr.find((currentPnr) => currentPnr.sourcePnrId === sourcePnrId);
    const legs = pnr?.air?.air?.data?.legs;
    if (!legs) {
      return [];
    }
    const itineraryAllAirlines: IItineraryAirline[] = [];
    legs.forEach((leg, legIndex: number) => {
      leg.flightIndices.forEach((dataFlightIndex, flightIndex) => {
        itineraryAllAirlines.push({
          legIndex,
          flightIndex,
          airline: {
            marketing: pnr?.air?.air?.data?.flights[dataFlightIndex].marketing.airline ?? '',
            operating: pnr?.air?.air?.data?.flights[dataFlightIndex].operating.airline ?? '',
          },
        });
      });
    });

    return itineraryAllAirlines;
  }

  public GetItineraryLegAirlines(sourcePnrId: string, legIndex: number): IItineraryAirline[] {
    return this.GetItineraryAllAirlines(sourcePnrId).filter((item) => item.legIndex === legIndex);
  }

  // Returns the array of departureAirportCode and arrivalAirportCode for Air pnrs
  public GetItineraryAirLegInfo(sourcePnrId: string): ItineraryLegInfo[] {
    const pnr = this.response.pnr.find((currentPnr) => currentPnr.sourcePnrId === sourcePnrId);
    const legs = pnr?.air?.air?.data?.legs;
    const flights = pnr?.air?.air?.data?.flights;

    if (!legs || !flights) {
      return [];
    }

    return legs.reduce(
      (acc, leg) => [
        ...acc,
        {
          departureAirportCode: flights[first(leg.flightIndices) ?? 0].origin.airport,
          arrivalAirportCode: flights[last(leg.flightIndices) ?? 0].destination.airport,
        },
      ],
      [] as ItineraryLegInfo[],
    );
  }

  /* the method is mapping legId to legInfoArrayIndex which is used in the UI for details see SplitAirPnrIfNeeded
     please see SplitAirPnrIfNeeded and there is no legInfoArrayIndex for pnr with 1 leg
  */
  public static getAirLegPnrForPnrLegId(pnr: ITripPnr, legId: string): number | undefined {
    return pnr.air?.legInfo?.length && pnr.air.legInfo.length > 1
      ? pnr.air?.legInfo?.find((legInfo) => legInfo.legId === legId)?.legIndex
      : undefined;
  }

  static GetIsLegFinished(legStatus: UserFacingStatus): boolean {
    return (
      legStatus === UserFacingStatus.COMPLETED_STATUS ||
      legStatus === UserFacingStatus.CANCELLED_STATUS ||
      legStatus === UserFacingStatus.REFUNDED_STATUS ||
      legStatus === UserFacingStatus.VOIDED_STATUS
    );
  }

  static GetAirLegInfoForPax(pnr: ITripPnr, paxIndex: number): PaxInfoFareComponentLegInfo[] {
    return pnr?.air?.air?.itineraries?.[0]?.rateOptions?.[0]?.paxInfo?.[paxIndex]?.fareComponents?.[0]?.legInfo ?? [];
  }

  static shouldExchangeAirBySupport(pnr: ITripPnr, { legsCountHavingSameTicket = 0 }): IPnrChangeUsingSupport {
    if (legsCountHavingSameTicket === 0) {
      return {
        changeBySupport: true,
        reason: {
          type: ChangeBySupportReasonEnum.NO_TICKET_NUMBER_FOUND,
        },
      }; // If no leg has this ticket show the chat widget
    }

    const isPnrSourceOffline = pnr.source === ThirdPartySourceEnum.OFFLINE;

    if (isPnrSourceOffline) {
      return {
        changeBySupport: true,
        reason: {
          type: ChangeBySupportReasonEnum.PNR_SOURCE_OFFLINE,
          metaData: { pnrSource: pnr.source },
        },
      };
    }

    const { ticketForGivenLegIndex, itinSource, legStatus } = this.GetAirPnrCardDetails(pnr);

    const changeableItinSources = [IThirdPartySourceEnum.SABRE, IThirdPartySourceEnum.FARELOGIX_NDC].includes(
      itinSource,
    );

    if (!changeableItinSources) {
      return {
        changeBySupport: true,
        reason: {
          type: ChangeBySupportReasonEnum.NON_CHANGEABLE_ITINERARY_SOURCE,
          metaData: { itinSource },
        },
      };
    }

    const legStatusNotConfirmed = legStatus !== IUserFacingStatusEnum.CONFIRMED_STATUS;

    if (legStatusNotConfirmed) {
      return {
        changeBySupport: true,
        reason: {
          type: ChangeBySupportReasonEnum.LEG_STATUS_NOT_CONFIRMED,
          metaData: { legStatus },
        },
      };
    }

    const isExchangeable = !!ticketForGivenLegIndex?.cancellationPolicy?.exchangePolicy?.isExchangeable;

    if (!isExchangeable) {
      return {
        changeBySupport: true,
        reason: {
          type: ChangeBySupportReasonEnum.NON_EXCHANGEABLE_EXCHANGE_POLICY,
          metaData: { exchangePolicy: ticketForGivenLegIndex?.cancellationPolicy?.exchangePolicy },
        },
      };
    }

    const exchangePolicyInfo = !!ticketForGivenLegIndex?.cancellationPolicy?.exchangePolicy?.isInfoAvailable;

    if (!exchangePolicyInfo) {
      return {
        changeBySupport: true,
        reason: {
          type: ChangeBySupportReasonEnum.EXCHANGE_POLICY_INFO_NOT_AVAILABLE,
          metaData: { exchangePolicy: ticketForGivenLegIndex?.cancellationPolicy?.exchangePolicy },
        },
      };
    }

    return {
      changeBySupport: false,
      reason: {
        type: ChangeBySupportReasonEnum.MANUAL_CHANGE_ALLOWED,
      },
    };
  }

  static isAirPnrVoidable(pnrVoidPolicy: AirPnrVoidPolicy | undefined): boolean {
    const isVoidable = !!pnrVoidPolicy?.isVoidable;
    const voidDurationString = pnrVoidPolicy?.voidPeriod?.iso8601;
    const isInfinitelyVoidable = !voidDurationString;
    const voidDurationMinutes = voidDurationString ? getDurationMinutes(voidDurationString) : 0;
    return isVoidable && (isInfinitelyVoidable || voidDurationMinutes > 0);
  }

  static isAirPnrCanNotBeCancelled(pnr: ITripPnr): boolean {
    const tickets = first(pnr?.air?.travelerInfo)?.tickets ?? [];
    const pnrVoidPolicy = pnr?.air?.voidPolicy;

    const refundableTicketType = this.IsPnrRefundable(tickets);
    const isVoidableFlight = this.isAirPnrVoidable(pnrVoidPolicy);

    return !isVoidableFlight && refundableTicketType === IsRefundableEnum.FALSE;
  }

  static IsHotelPnrChangable(pnr: ITripPnr): boolean {
    const isRateModifiable = !!pnr.hotel?.hotel?.rooms[0]?.rateOptions[0]?.isModifiable;
    // eslint-disable-next-line no-restricted-syntax
    const endDate = V2TripDetailsResponseManager.GetPnrEndDate(pnr);
    const isCheckInInFuture = !!isFutureDate(endDate);
    const isPnrCancelled = this.IsHotelPnrCancelled(pnr.hotel);

    return !isPnrCancelled && isRateModifiable && isCheckInInFuture;
  }

  static GetNumberOfTravelersWithPrimaryTraveler(pnr: ITripHotelPnr): number {
    const hotelGuestsBreakup = first(pnr.hotel?.occupancyDates?.occupancy);
    return !hotelGuestsBreakup ? 0 : hotelGuestsBreakup?.numAdults + hotelGuestsBreakup?.numChildren - 1;
  }

  static IsPnrRefundable(pnrTickets: Ticket[]): IsRefundableEnum {
    if (pnrTickets) {
      if (pnrTickets.length) {
        // if any of the ticket's isInfoAvailable is false : contact us (i.e return IsRefundableEnum.UNRECOGNIZED)
        // if both refundable/non-refundable then show cummulative penalty else, contact support(i.e return IsRefundableEnum.UNRECOGNIZED)
        const allInfoAvailable = pnrTickets.every(
          (ticket) => ticket.cancellationPolicy?.refundPolicy?.isInfoAvailable === true,
        );
        if (!allInfoAvailable) {
          return IsRefundableEnum.UNRECOGNIZED;
        }
        const allRefundable = pnrTickets.every(
          (ticket) => ticket.cancellationPolicy?.refundPolicy?.isRefundable === true,
        );
        if (allRefundable) {
          return IsRefundableEnum.TRUE;
        }
        const allNonRefundable = pnrTickets.every(
          (ticket) => ticket.cancellationPolicy?.refundPolicy?.isRefundable === false,
        );
        if (allNonRefundable) {
          return IsRefundableEnum.FALSE;
        }
        return IsRefundableEnum.UNRECOGNIZED;
      }
    }
    return IsRefundableEnum.UNRECOGNIZED;
  }

  static getAirCancellationWorkflow({ isAgent, pnr, device }: IAirCancellationParameters): ICancellationWorkflow {
    if (!pnr) {
      return {
        cancellationWorkflow: CancellationWorkflow.SUPPORT,
        reason: { type: TripCancelMethodReasonEnum.UNKNOWN },
      };
    }
    // eslint-disable-next-line no-restricted-syntax
    const data = V2TripDetailsResponseManager.GetAirPnrCardDetails(pnr);
    const { itinSource, ticketForGivenLegIndex, status, legStatus } = data;
    const flightIsExchanged = Boolean(pnr?.air?.changed);
    const pnrVoidPolicy = pnr?.air?.voidPolicy;
    // eslint-disable-next-line no-restricted-syntax
    const isVoidableFlight = V2TripDetailsResponseManager.isAirPnrVoidable(pnrVoidPolicy);
    const tickets = first(pnr.air?.travelerInfo)?.tickets ?? [];

    // TODO: Remove V2TripDetailsResponseManager.IsPnrRefundable after  pnrCancellationDetails is deployed on prod
    // eslint-disable-next-line no-restricted-syntax
    const isRefundable = V2TripDetailsResponseManager.IsPnrRefundable(tickets);

    const cancellationInfoAvailable = ticketForGivenLegIndex?.cancellationPolicy?.refundPolicy?.isInfoAvailable;

    const cancellableLegStatus =
      legStatus !== UserFacingStatus.CANCELLED_STATUS && legStatus !== UserFacingStatus.VOIDED_STATUS;
    // If isInfoAvailable is false, that implies the trip has started, however this only holds when the trip is not voidable
    const tripHasStarted = !isVoidableFlight && !cancellationInfoAvailable;

    const isOutsideBooking = pnr.source === ThirdPartySourceEnum.OFFLINE;
    const nonVoidableNorRefundable =
      !isOutsideBooking && !isVoidableFlight && isRefundable === IsRefundableEnum.UNRECOGNIZED;

    const cancellableItinSources = [
      IThirdPartySourceEnum.SABRE,
      IThirdPartySourceEnum.ATPCO_NDC,
      IThirdPartySourceEnum.FARELOGIX_NDC,
    ].includes(itinSource);

    if (!cancellableItinSources) {
      return {
        cancellationWorkflow: CancellationWorkflow.SUPPORT,
        reason: {
          type: TripCancelMethodReasonEnum.NON_CANCELABLE_ITIN_SOURCE,
          metaData: { itinSource },
        },
      };
    }

    const isTripConfirmedOrTicketed = [ITripPnrStatusEnum.CONFIRMED, ITripPnrStatusEnum.TICKETED].includes(status);

    if (!isTripConfirmedOrTicketed) {
      return {
        cancellationWorkflow: CancellationWorkflow.SUPPORT,
        reason: {
          type: TripCancelMethodReasonEnum.TRIP_NOT_CONFIRMED_OR_TICKETED,
          metaData: { pnrStatus: status },
        },
      };
    }

    if (flightIsExchanged) {
      return {
        cancellationWorkflow: CancellationWorkflow.SUPPORT,
        reason: {
          type: TripCancelMethodReasonEnum.FLIGHT_IS_EXCHANGED,
          metaData: flightIsExchanged,
        },
      };
    }

    if (tripHasStarted) {
      return {
        cancellationWorkflow: CancellationWorkflow.SUPPORT,
        reason: {
          type: TripCancelMethodReasonEnum.TRIP_HAS_STARTED,
          metaData: tripHasStarted,
        },
      };
    }

    if (nonVoidableNorRefundable) {
      return {
        cancellationWorkflow: CancellationWorkflow.SUPPORT,
        reason: {
          type: TripCancelMethodReasonEnum.NON_VOIDABLE_NOR_REFUNDABLE,
          metaData: nonVoidableNorRefundable,
        },
      };
    }

    if (!cancellableLegStatus) {
      return {
        cancellationWorkflow: CancellationWorkflow.SUPPORT,
        reason: {
          type: TripCancelMethodReasonEnum.NON_CANCELLABLE_LEG_STATUS,
          metaData: { legStatus },
        },
      };
    }

    if (isOutsideBooking) {
      if (device === DeviceType.MOBILE) {
        return {
          cancellationWorkflow: CancellationWorkflow.SUPPORT,
          reason: {
            type: TripCancelMethodReasonEnum.OUTSIDE_BOOKING_AND_IS_MOBILE_DEVICE,
            metaData: device === DeviceType.MOBILE && isOutsideBooking,
          },
        };
      }

      // This cancellation flow is available only for agents
      if (!isAgent) {
        return {
          cancellationWorkflow: CancellationWorkflow.SUPPORT,
          reason: {
            type: TripCancelMethodReasonEnum.OUTSIDE_BOOKING_AND_NOT_AGENT,
            metaData: !isAgent && isOutsideBooking,
          },
        };
      }

      // The user is an agent and has access
      return isRefundable !== IsRefundableEnum.UNRECOGNIZED
        ? { cancellationWorkflow: CancellationWorkflow.REFUND, reason: { type: TripCancelMethodReasonEnum.UNKNOWN } }
        : { cancellationWorkflow: CancellationWorkflow.VOID, reason: { type: TripCancelMethodReasonEnum.UNKNOWN } };
    }

    if (isVoidableFlight) {
      return {
        cancellationWorkflow: CancellationWorkflow.VOID,
        reason: { type: TripCancelMethodReasonEnum.UNKNOWN },
      };
    }
    if (isRefundable !== IsRefundableEnum.UNRECOGNIZED) {
      return {
        cancellationWorkflow: CancellationWorkflow.REFUND,
        reason: { type: TripCancelMethodReasonEnum.UNKNOWN },
      };
    }

    // In all other cases return support
    return {
      cancellationWorkflow: CancellationWorkflow.SUPPORT,
      reason: { type: TripCancelMethodReasonEnum.UNKNOWN },
    };
  }

  static getCancellationWorkflow({
    isCancellable,
    isAgent,
    isOutsideBooking,
    device,
  }: {
    isCancellable: boolean;
    isAgent: boolean;
    isOutsideBooking: boolean;
    device: DeviceType;
  }): CancellationWorkflow {
    if (!isCancellable) {
      return CancellationWorkflow.VOID;
    }

    if (!isOutsideBooking) {
      return CancellationWorkflow.REFUND;
    }

    if (device === DeviceType.MOBILE) {
      return CancellationWorkflow.SUPPORT;
    }

    if (isAgent) {
      return CancellationWorkflow.REFUND;
    }

    // If outside booking is cancellable and user is not an agent
    return CancellationWorkflow.SUPPORT;
  }

  static IsHotelPnrCancelled(pnr: ITripHotelPnr | undefined): boolean {
    return (
      pnr?.status === ITripPnrStatusEnum.CANCELLED ||
      pnr?.status === ITripPnrStatusEnum.CANCELLED_BY_VENDOR ||
      pnr?.status === ITripPnrStatusEnum.VOIDED
    );
  }

  static getHotelCancellationWorkflow(
    pnr: ITripHotelPnr | undefined,
    { isAgent = false, isOutsideBooking = false }: IHotelCancellationParameters | never,
    device: DeviceType,
  ): CancellationWorkflow {
    if (!pnr) {
      return CancellationWorkflow.SUPPORT;
    }

    const isCancelled = this.IsHotelPnrCancelled(pnr);

    /** If pickup date < today's date, or PNR is already
     * cancelled, then disable the cancellation button
     */
    const isCancellable =
      !isCancelled && !!(isOutsideBooking || isFutureDate(pnr.hotel?.occupancyDates?.checkInDate?.iso8601 ?? ''));

    return this.getCancellationWorkflow({ isCancellable, isAgent, isOutsideBooking, device });
  }

  static GetIsCarPnrCancelled(pnr: ITripCarPnr | undefined): boolean {
    return (
      pnr?.status === ITripPnrStatusEnum.CANCELLED ||
      pnr?.status === ITripPnrStatusEnum.CANCELLED_BY_VENDOR ||
      pnr?.status === ITripPnrStatusEnum.VOIDED
    );
  }

  static getCarCancellationWorkflow(
    pnr: ITripCarPnr | undefined,
    { isAgent = false, isOutsideBooking = false }: ICarCancellationParameters | never,
    device: DeviceType,
  ): CancellationWorkflow {
    const isCancelled = this.GetIsCarPnrCancelled(pnr);

    /** If pickup date < today's date, or PNR is already
     * cancelled, then disable the cancellation button
     */
    const isCancellable = !isCancelled && !!(isOutsideBooking || isFutureDate(pnr?.car?.pickup?.iso8601 ?? ''));

    return this.getCancellationWorkflow({ isCancellable, isAgent, isOutsideBooking, device });
  }

  static getRailCancellationWorkflow(
    pnr: ITripRailPnr | undefined,
    { isAgent = false, isOutsideBooking = false }: ICarCancellationParameters | never,
    device: DeviceType,
  ): CancellationWorkflow {
    if (!pnr) {
      return CancellationWorkflow.SUPPORT;
    }

    const isCancelled = this.IsPnrStatusCancelled(pnr.status);

    /** If pickup date < today's date, or PNR is already
     * cancelled, then disable the cancellation button
     */
    const isCancellable =
      !isCancelled &&
      !!(
        isOutsideBooking ||
        isFutureDate(
          pnr?.itineraryDetails?.outwardJourney?.departAt?.iso8601 ??
            pnr?.itineraryDetails?.inwardJourney?.departAt?.iso8601 ??
            '',
        )
      );

    return this.getCancellationWorkflow({ isCancellable, isAgent, isOutsideBooking, device });
  }

  /**
   * VERY IMPORTANT !!!
   * GetAirUTAsFromPnr function expects split PNR in case of a multi-leg PNR
   * Please use "SplitAirPnrIfNeeded" function to split PNR before using the
   * following API
   */
  static GetAirUTAsFromSplitPnr(pnr: ITripPnr): ILegUTA[] {
    const pnrUtas = pnr.air?.air?.data.utas;
    // eslint-disable-next-line no-restricted-syntax
    const legInfoIndex = V2TripDetailsResponseManager.GetAirLegInfoIndexFromSplitPnr(pnr);
    const utaIndices: number[] =
      pnr.air?.air?.itineraries[0].rateOptions[0].paxInfo[0].fareComponents[0].legInfo?.[legInfoIndex]?.utaIndices ??
      [];

    return pnrUtas
      ? utaIndices.map((utaIndex) => ({
          key: Object.keys(pnrUtas[utaIndex] ?? [])[0] as UtaProps,
          displayText: getDisplayTextUTA(pnrUtas[utaIndex] ?? ''),
          uta: pnrUtas[utaIndex],
        }))
      : [];
  }

  /**
   * VERY IMPORTANT !!!
   * GetAirPnrCardDetails function expects split PNR in case of a multi-leg PNR
   * Please use "SplitAirPnrIfNeeded" function to split PNR before using the
   * following API
   */
  static GetAirPnrCardDetails(pnr: ITripPnr): IAirPnrCard {
    const lastFlightoftheLeg = last(pnr?.air?.air?.data.flights);
    const firstFlightoftheLeg = first(pnr?.air?.air?.data.flights);
    // eslint-disable-next-line no-restricted-syntax
    const legDuration = V2TripDetailsResponseManager.calculateLegDuration(pnr);
    const baseFare = MoneyUtil.parse(pnr.air?.air?.itineraries[0].rateOptions[0]?.totalFare.base);
    const tax = MoneyUtil.parse(pnr.air?.air?.itineraries[0].rateOptions[0]?.totalFare.tax);
    const showRawFareRules = pnr.air?.air?.itineraries[0].rateOptions[0]?.showRawFareRules;

    const totalFare = baseFare.add(tax);
    const totalPaid = MoneyUtil.parse(pnr.totalFare);
    const airline = lastFlightoftheLeg?.marketing.airline ?? '';
    // eslint-disable-next-line no-restricted-syntax
    const legInfoIndex = V2TripDetailsResponseManager.GetAirLegInfoIndexFromSplitPnr(pnr);
    // eslint-disable-next-line no-restricted-syntax
    const legIndex = V2TripDetailsResponseManager.GetAirOriginalLegIndexFromSplitPnr(pnr);

    let itinSource = ThirdPartySourceEnum.UNKNOWN_SOURCE;
    let itinSourcePcc = '';
    let rateOptionAirline = '';
    if (pnr.air?.air) {
      const airManager = new AirSelectedItineraryResponseManager(pnr.air.air);
      itinSource = airManager.GetItinerarySourcePerLegInfo(legIndex, 0);
      rateOptionAirline = airManager.GetRateOptionAirline(0);
      itinSourcePcc = pnr.owningPcc;
    }

    // eslint-disable-next-line no-restricted-syntax
    const travellers: ITripTravellerDetail[] = V2TripDetailsResponseManager.GetFlightTravellerDetails(pnr, legIndex);

    const flightIndices = first(pnr?.air?.air?.data?.legs)?.flightIndices ?? [];

    const airlineReferences = flightIndices.map((flightIndex) => {
      // Instead of directly using flightIndex on flightInfo array, we need find
      // the flightInfo using .flightIndex property because pnr is split. In
      // `SplitAirPnrIfNeeded`, we set the flightInfo array by filtering based
      // on .flightIndex property of its elements.
      const flightIndexFlightInfo = pnr.air?.flightInfo.find((info) => info.flightIndex === flightIndex);
      return flightIndexFlightInfo?.vendorConfirmationId ?? pnr.sourcePnrId;
    });
    const airlineReference = airlineReferences.length > 0 ? [...new Set(airlineReferences)].join(', ') : '';

    const legInfo = pnr.air?.legInfo?.[legInfoIndex];

    const flightDetails: ITripFlightDetail[] = this.GetFlightDetailsWithUpdates(pnr, legInfo?.legUpdate);

    const allTicketsInPnr = pnr.air?.travelerInfo[0].tickets || [];
    const ticketForGivenLegIndex = allTicketsInPnr.find((ticket) => {
      const allFlightCoupons = ticket.flightCoupons;
      return allFlightCoupons.find((flightCoupon) => flightCoupon.legIndex === legIndex);
    });

    const shouldSuggestContactSupportForLegStatus = (legStatus: UserFacingStatus) => {
      switch (legStatus) {
        case IUserFacingStatusEnum.UNCONFIRMED_STATUS:
          return true;
        case IUserFacingStatusEnum.SCHEDULE_CHANGE_STATUS:
          return true;
        case IUserFacingStatusEnum.PAYMENT_DECLINED_STATUS:
          return true; // TODO remove this once UI if to match figma and supports retry payment instead
        default:
      }
      return false;
    };

    const warningForLegStatus = (legStatus: UserFacingStatus) => {
      switch (legStatus) {
        case IUserFacingStatusEnum.AIRLINE_CONTROL_STATUS:
          return defineMessage(
            'A change was made to your original booking from outside of our system. Please refer to the airline website for the most accurate and up to date information about your flight.',
          );
        case IUserFacingStatusEnum.PROCESSING_STATUS:
          return defineMessage(
            'We are waiting for a confirmation from the airline for this booking. Please check back after some time.',
          );
        case IUserFacingStatusEnum.UNCONFIRMED_STATUS:
          return defineMessage(
            'Looks like something has gone wrong with your booking. Please get in touch with our customer support for assistance.',
          );
        case IUserFacingStatusEnum.SCHEDULE_CHANGE_STATUS:
          return defineMessage(
            'Your flight schedule has changed. Please get in touch with customer support to accept or reject this change.',
          );
        case IUserFacingStatusEnum.PAYMENT_DECLINED_STATUS:
          return defineMessage('Looks like the payment for this booking did not go through.');
        default:
      }
      return '';
    };

    const allFlightCouponsForLeg = allTicketsInPnr.flatMap((ticket) => {
      const allFlightCoupons = ticket.flightCoupons;
      return allFlightCoupons.filter((flightCoupon) => flightCoupon.legIndex === legIndex);
    });
    const isAnyFlightCheckedIn = allFlightCouponsForLeg.some(
      (coupon) => coupon.status === TicketFlightCouponStatus.CHECKED_IN,
    );

    const dateOfBooking = localizeDate(dateUtil(pnr.createdAt?.iso8601), 'medium') || '';

    return {
      originAirport: firstFlightoftheLeg?.origin.airportName ?? '',
      originAirportCode: firstFlightoftheLeg?.origin.airport ?? '',
      originCityName: firstFlightoftheLeg?.origin.cityName ?? '',
      destinationAirport: lastFlightoftheLeg?.destination.airportName ?? '',
      destinationAirportCode: lastFlightoftheLeg?.destination.airport ?? '',
      destinationCityName: lastFlightoftheLeg?.destination.cityName ?? '',
      legStatus: legInfo?.legStatus ?? 0,
      legStatusWarning: warningForLegStatus(legInfo?.legStatus ?? 0),
      pnrFreshnessInfo: pnr.freshnessInfo,
      legDuration: minutesToDurationString(legDuration),
      totalFare,
      baseFare,
      tax,
      totalPaid,
      stops: (pnr?.air?.air?.data.flights.length ?? 1) - 1,
      pnrId: pnr.pnrId,
      sourcePnrId: pnr.sourcePnrId,
      dateOfBooking,
      originDepartureTime: firstFlightoftheLeg?.departureDateTime.iso8601 ?? '',
      destinationArrivalTime: lastFlightoftheLeg?.arrivalDateTime.iso8601 ?? '',
      itinSource,
      itinSourcePcc,
      status: pnr.status,
      airLine: airline,
      flightDetails,
      travellers,
      // eslint-disable-next-line no-restricted-syntax
      utas: V2TripDetailsResponseManager.GetAirUTAsFromSplitPnr(pnr),
      showRawFareRules: showRawFareRules ?? false,
      airlineReference,
      currentLegDetailIndex: legIndex,
      ticketForGivenLegIndex,
      contactSupport: pnr.contactSupport || shouldSuggestContactSupportForLegStatus(legInfo?.legStatus ?? 0),
      // eslint-disable-next-line no-restricted-syntax
      preferences: V2TripDetailsResponseManager.GetPreferences(pnr),
      isAnyFlightCheckedIn,
      rateOptionAirline,
    };
  }

  static isLastFlightOvernight(pnrs: ITripPnr[]): boolean {
    const lastPnr = last(pnrs);
    if (!lastPnr?.air) return false;
    const { originDepartureTime, flightDetails } = this.GetAirPnrCardDetails(lastPnr);
    const lastFlight = last(flightDetails);
    if (!lastFlight) return false;
    const { departureDateTime, arrivalDateTime } = lastFlight;
    const arrivalDateChange =
      originDepartureTime && arrivalDateTime ? getDateDiff(departureDateTime, arrivalDateTime) : 0;
    const isOvernight = arrivalDateChange > 0;
    return isOvernight;
  }

  static GetDisruptedFlightDetails(pnr: ITripPnr): FlightDetail[] {
    // eslint-disable-next-line no-restricted-syntax
    const legInfoIndex = V2TripDetailsResponseManager.GetAirLegInfoIndexFromSplitPnr(pnr);

    const airPnr = pnr.air;
    const scheduleChangeInfo = airPnr?.legInfo[legInfoIndex].scheduleChangeInfo;

    if (!scheduleChangeInfo || isEmpty(scheduleChangeInfo)) {
      return [];
    }

    const { disruptedFlightIndices } = scheduleChangeInfo;

    return disruptedFlightIndices.map((index) => airPnr.disruptedFlightDetails[index]);
  }

  static GetPossibleDisruptionActions(pnr: ITripPnr): ScheduleChangeAction[] {
    const airPnr = pnr.air;

    /**
     * Possible actions of a flight disruption is the same within a pnr;
     */
    const actions = airPnr?.travelerInfo[0].tickets[0]?.ticketScheduleChangeInformation?.possibleActions;

    if (!actions) {
      return [];
    }

    return actions;
  }

  // Returns Flight tickets for a given pnr and leg. Will return undefined if only seats or baggage tickets are available.
  public GetTicketFromLegIndexAndPnrId(legIndex: string, pnrId: string): Ticket | undefined {
    const foundPnr = this.response.pnr.find((pnr) => pnr.pnrId === pnrId);
    if (!(foundPnr && foundPnr.air && foundPnr.air.travelerInfo)) {
      return undefined;
    }
    const allFlightTickets = foundPnr.air.travelerInfo[0].tickets.filter(
      (ticket) => ticket.ticketType === TicketType.FLIGHT,
    );
    const foundTickets = allFlightTickets.filter((ticket) => {
      const allFlightCoupons = ticket.flightCoupons;
      return allFlightCoupons.find((flightCoupon) => flightCoupon.legIndex === parseInt(legIndex, 10));
    });
    if (foundTickets.length !== 1) {
      return undefined;
    }
    return foundTickets[0];
  }

  public findAllLegIndexesForGivenTicketNumberInPnr(pnrId: string, ticketNumber: string): number[] {
    const foundPnr = this.response.pnr.find((pnr) => pnr.pnrId === pnrId);
    if (!(foundPnr && foundPnr.air && foundPnr.air.travelerInfo)) {
      return [];
    }

    const flightCouponsInGivenTicket = foundPnr.air.travelerInfo[0].tickets.find(
      (ticket) => ticket.number === ticketNumber,
    )?.flightCoupons;
    const allLegIndices: number[] = [];
    if (!flightCouponsInGivenTicket) {
      return [];
    }
    flightCouponsInGivenTicket.forEach((flightCoupon) => {
      if (!allLegIndices.includes(flightCoupon.legIndex)) {
        allLegIndices.push(flightCoupon.legIndex);
      }
    });
    return allLegIndices;
  }

  static FindPnrForFlightInfo({
    tripDetails,
    pnrId,
    origin,
    destination,
    operatingFlightNumber,
    operatingAirlineCode,
    departureDate,
    legStatusComparator = (): boolean => true,
  }: {
    tripDetails: GetTripDetailsResponse;
    pnrId: string;
    origin: string;
    destination: string;
    operatingFlightNumber: string;
    operatingAirlineCode: string;
    departureDate: string;
    legStatusComparator?: (status: UserFacingStatus) => boolean;
  }): ITripPnr | undefined {
    // eslint-disable-next-line no-restricted-syntax
    const manager = new V2TripDetailsResponseManager(tripDetails);
    const filteredPnrs: ITripPnr[] = manager
      .GetSortedPnrDetails()
      // Splitted into multiple pnrs can have same pnrId
      .filter((pnr) => pnr.pnrId === pnrId)
      .filter((pnr) => pnr.air)
      .filter((pnr) => {
        // eslint-disable-next-line no-restricted-syntax
        const legInfoIndex = V2TripDetailsResponseManager.GetAirLegInfoIndexFromSplitPnr(pnr);
        const legInfo = pnr.air?.legInfo?.[legInfoIndex];
        const legStatus = legInfo?.legStatus ?? 0;

        return legStatusComparator(legStatus);
      });

    const foundPnr = filteredPnrs.find((pnr) => {
      const flightsOfTheLeg = pnr.air?.air?.data.flights ?? [];

      // Search through all flights of the leg
      const foundFlight = flightsOfTheLeg.find((flight) => {
        const originAirport = flight.origin.airport;
        const destinationAirport = flight.destination.airport;
        const originDepartureDateTime = flight.departureDateTime.iso8601;
        const originFlightAirlineCode = flight.operating.airline;
        const originFlightNumber = flight.operating.num;

        return (
          originAirport === origin &&
          destinationAirport === destination &&
          originFlightNumber === operatingFlightNumber &&
          dateUtil(originDepartureDateTime).isSame(dateUtil(departureDate), 'day') &&
          originFlightAirlineCode === operatingAirlineCode
        );
      });

      return !!foundFlight;
    });

    return foundPnr;
  }

  public getPnrFromPnrId(pnrId: string): ITripPnr | undefined {
    return (
      this.response.pnr.find((pnr) => pnr.pnrId === pnrId) ??
      this.response?.pendingShellPnrs?.find((pnr) => pnr.pnrId === pnrId) ??
      this.response?.pendingManualFormPnrs?.find((pnr) => pnr.pnrId === pnrId)
    );
  }

  /**
   * Retrieves the indices of cancelled legs for a given rail PNR.
   * @param {Pnr} pnrId - The PNR ID.
   * @returns {number[]} - An array containing the indices of cancelled legs.
   */
  public getRailCancelledLegs(pnrId: string): number[] {
    const pnr = this.response.pnr.find((item) => item.pnrId === pnrId);
    if (!pnr) {
      return [];
    }
    const cancelledLegsMap: { [legIndex: number]: boolean } = {};
    // Check if the PNR has rail details and itinerary sections
    pnr.rail?.itineraryDetails?.sections
      ?.filter((section) => section.status === SectionInfoSectionStatus.CANCELLED)
      .forEach((section) => {
        section.fares.forEach((fare) => {
          fare.fareLegs.forEach((fareLeg) => {
            cancelledLegsMap[fareLeg.legIndex] = true;
          });
        });
      });

    // Extract the keys (leg indices) from the cancelledLegsMap and return them as an array.
    const cancelledLegs: number[] = Object.keys(cancelledLegsMap).map(Number);
    return cancelledLegs;
  }

  public searchPnrById(pnrId: string): ITripPnr | undefined {
    return (
      this.response.pnr.find((pnr) => pnr.pnrId === pnrId) ??
      this.response.pendingManualFormPnrs.find((pnr) => pnr.pnrId === pnrId) ??
      this.response.pendingShellPnrs.find((pnr) => pnr.pnrId === pnrId)
    );
  }

  public getPnrFromSourcePnrId(sourcePnrId: string): ITripPnr | undefined {
    return this.response.pnr.find((pnr) => pnr.sourcePnrId === sourcePnrId);
  }

  /** @deprecated use getIsMixedCabin from tripAirCabinInfo.ts */
  public static getIsMixedCabin(fareInfo: PaxInfoFareComponentLegInfo): boolean {
    const cabinSet = new Set();
    fareInfo.flightInfo.forEach((flightInfo) => cabinSet.add(flightInfo.cabin));

    return cabinSet.size > 1;
  }

  public getAirTravelers(pnrId: string): AirPnrTravelerInfo[] {
    const pnr = this.getPnrFromPnrId(pnrId);
    return pnr?.air?.travelerInfo ?? [];
  }

  // Get the air -> air -> data flights index when we have the index of legs[legIndex] -> flightIndices
  public getFlightIndexFromLegsFlightIndex(legIndex: number, relativeFlightIndex: number, pnrId: string): number {
    const pnr = this.getPnrFromPnrId(pnrId);
    const dataFlightIndex = pnr?.air?.air?.data?.legs?.[legIndex]?.flightIndices?.[relativeFlightIndex];
    return dataFlightIndex ?? -1;
  }

  // Get the index of legs[legIndex] -> flightIndices when we have the air -> air -> data flights index
  public getRelativeFlightIndexFromFlightIndex(legIndex: number, dataFlightIndex: number, pnrId: string): number {
    const pnr = this.getPnrFromPnrId(pnrId);
    const leg = pnr?.air?.air?.data?.legs?.[legIndex];
    const relativeFlightIndex = leg?.flightIndices.findIndex((flightDataIndex) => flightDataIndex === dataFlightIndex);
    return relativeFlightIndex ?? -1;
  }

  public getTravelerRestrictions(pnrId: string, legInfoIndex: number): AirPnrLegInfoTravelerRestrictions[] {
    const pnr = this.getPnrFromPnrId(pnrId);
    const travelerRestrictions = pnr?.air?.legInfo?.[legInfoIndex].travelerRestrictions;
    return travelerRestrictions ?? [];
  }

  public getFlightRestrictions(pnrId: string): MetadataFlightRestrictions[] {
    const pnr = this.getPnrFromPnrId(pnrId);
    const flightRestrictions = pnr?.air?.air?.metadata?.flightRestrictions;
    return flightRestrictions ?? [];
  }

  // Returns Loyalty type for all pax. Where the index of the array represents the travelerIndex
  static getLoyaltyInfo(pnr: ITripPnr | undefined, travelers: AirPnrTravelerInfo[]): Array<IPaxSelectedLoyalty[]> {
    const data: IPaxSelectedLoyalty[][] = [];

    travelers.forEach((travelerInfo, travelerIndex) => {
      const loyaltyInfo: IPaxSelectedLoyalty[] = [];
      pnr?.air?.air?.data.legs.forEach((leg, legIndex) => {
        leg.flightIndices.forEach((flightIndex, relativeFlightIndex) => {
          const flight = pnr.air?.air?.data.flights[flightIndex];
          const marketingAirline = flight?.marketing.airline;
          const loyaltyInfos = travelerInfo.traveler?.travelerPersonalInfo?.loyaltyInfos || [];
          const blockLoyalty = travelerInfo.traveler?.travelerPersonalInfo?.blockLoyalty || false;
          const loyaltyInfoForFlight = (blockLoyalty ? [] : loyaltyInfos)
            .filter((loyalty) => (marketingAirline ? loyalty.appliedTo.includes(marketingAirline) : true))
            .map((loyalty) => ({
              leg: legIndex,
              flight: relativeFlightIndex,
              loyalty,
              validationStatus: LoyaltyValidityEnum.VALID,
            }));
          loyaltyInfo.push(...loyaltyInfoForFlight);
        });
      });
      data[travelerIndex] = loyaltyInfo;
    });
    return data;
  }

  static GetPreferences(pnr: ITripPnr): Preference[] {
    // eslint-disable-next-line no-restricted-syntax
    const legInfoIndex = V2TripDetailsResponseManager.GetAirLegInfoIndexFromSplitPnr(pnr);
    // eslint-disable-next-line no-restricted-syntax
    const legFareInfo = V2TripDetailsResponseManager.GetAirLegInfoForPax(pnr, 0);

    return legFareInfo[legInfoIndex]?.preferences;
  }

  public GetAirItinSource(sourcePnrId: string) {
    const pnr = this.getPnrFromSourcePnrId(sourcePnrId);
    return pnr?.air?.air?.itineraries[0]?.rateOptions[0]?.source ?? IThirdPartySourceEnum.UNKNOWN_SOURCE;
  }

  public getNormalizedDataFromV2TripDetailsResponseManager = ({
    includePendingManualFormPnrs = false,
  }: {
    includePendingManualFormPnrs?: boolean;
  } = {}): NormalizedTripDetailsResponseData => {
    const allPnrs = this.GetSortedPnrDetails({
      includePendingManualFormPnrs,
    });

    // eslint-disable-next-line no-restricted-syntax
    const nonCancelledPnrs = V2TripDetailsResponseManager.GetNonCancelledPnrs(allPnrs);
    // eslint-disable-next-line no-restricted-syntax
    const totalCancelledPnrs = V2TripDetailsResponseManager.GetTotalCancelledPnrs(allPnrs, nonCancelledPnrs);
    const tripInfo = this.GetTripInfo();

    return {
      allPnrs,
      nonCancelledPnrs,
      totalCancelledPnrs,
      tripInfo,
    };
  };

  static GetPnrType(pnr: ITripPnr): PNRType {
    switch (true) {
      case Boolean(pnr.air):
        return PNRType.AIR;
      case Boolean(pnr.hotel):
        return PNRType.HOTEL;
      case Boolean(pnr.car):
        return PNRType.CAR;
      case Boolean(pnr.rail):
        return PNRType.RAIL;
      case Boolean(pnr.limo):
        return PNRType.LIMO;
      case Boolean(pnr.misc):
        return PNRType.MISC;
      default:
        return PNRType.UNKNOWN_TYPE;
    }
  }

  static GetSimplePnrType(pnr: SimplePnrInfo): PNRType {
    switch (true) {
      case Boolean(pnr?.air):
        return PNRType.AIR;
      // TODO: Add support for other types
      default:
        return PNRType.UNKNOWN_TYPE;
    }
  }

  static IsSimplePnrCancelled(simplePnr: SimplePnrInfo): boolean {
    return (
      simplePnr.pnrStatus === ITripPnrStatusEnum.CANCELLED ||
      simplePnr.pnrStatus === ITripPnrStatusEnum.CANCELLED_BY_VENDOR ||
      simplePnr.pnrStatus === ITripPnrStatusEnum.CANCELLATION_IN_PROGRESS ||
      simplePnr.pnrStatus === ITripPnrStatusEnum.VOIDED
    );
  }

  static GetPnrStatus(pnr: ITripPnr): PnrStatusV1 {
    if (pnr.air) {
      return pnr.status;
    }

    if (pnr.hotel) {
      return pnr.hotel.status;
    }
    if (pnr.car) {
      return pnr.car.status;
    }
    if (pnr.limo) {
      return pnr.limo.status;
    }
    if (pnr.misc) {
      return pnr.misc.status;
    }
    if (pnr.rail) {
      return pnr.rail.status;
    }

    return PnrStatusV1.UNKNOWN;
  }

  static IsPnrStatusCancelled(pnrStatus: PnrStatusV1) {
    return [PnrStatusV1.CANCELLED, PnrStatusV1.CANCELLED_BY_VENDOR, PnrStatusV1.VOIDED].includes(pnrStatus);
  }

  static IsPnrCancelled(pnr: ITripPnr) {
    // eslint-disable-next-line no-restricted-syntax
    const pnrStatus = V2TripDetailsResponseManager.GetPnrStatus(pnr);
    // eslint-disable-next-line no-restricted-syntax
    return V2TripDetailsResponseManager.IsPnrStatusCancelled(pnrStatus);
  }

  public static GetTravelerUserIdsForPnr(pnr: ITripPnr): string[] {
    switch (true) {
      case Boolean(pnr.air):
        return (pnr.air?.travelerInfo
          ?.map((travelerInfo) => travelerInfo.traveler?.userOrgId?.userId?.id)
          .filter(Boolean) ?? []) as string[];
      case Boolean(pnr.hotel):
        return (pnr.hotel?.travelerInfo
          ?.map((travelerInfo) => travelerInfo.traveler?.userOrgId?.userId?.id)
          .filter(Boolean) ?? []) as string[];
      case Boolean(pnr.car):
        return (pnr.car?.travelerInfo
          ?.map((travelerInfo) => travelerInfo.traveler?.userOrgId?.userId?.id)
          .filter(Boolean) ?? []) as string[];
      case Boolean(pnr.rail):
        return (pnr.rail?.itineraryDetails?.passengers
          .map((passenger) => passenger.traveler?.userOrgId?.userId?.id)
          .filter(Boolean) ?? []) as string[];
      case Boolean(pnr.limo):
        return (pnr.limo?.travelerInfo
          ?.map((travelerInfo) => travelerInfo.traveler?.userOrgId?.userId?.id)
          .filter(Boolean) ?? []) as string[];
      case Boolean(pnr.misc):
        return (pnr.misc?.travelerInfo
          ?.map((travelerInfo) => travelerInfo.traveler?.userOrgId?.userId?.id)
          .filter(Boolean) ?? []) as string[];
      default:
        return [];
    }
  }

  public setTravelerUserProfiles(travelerUserProfiles: UserProfile[]): void {
    this.allPnrsTravelerUserProfiles = travelerUserProfiles;
  }

  public getTravelerUserProfilesForPnr(pnr: ITripPnr): UserProfile[] {
    // eslint-disable-next-line no-restricted-syntax
    const travelerUserIdsForPnr = V2TripDetailsResponseManager.GetTravelerUserIdsForPnr(pnr);

    /**
     * we iterate over `travelerUserIdsForPnr` first to keep the order of the travelers
     */
    return travelerUserIdsForPnr
      .map((travelerUserId) => {
        return this.allPnrsTravelerUserProfiles.find((userProfile) => userProfile.id === travelerUserId);
      })
      .filter(Boolean) as UserProfile[];
  }

  public getPrimaryTravelerUserProfile(): UserProfile | undefined {
    return first(this.allPnrsTravelerUserProfiles);
  }

  static getPnrIdsWithSortingPriority(pnrs: ITripPnr[]): PnrSortingData[] {
    const pnrIdsWithSortingPriority = pnrs.map((pnr) => {
      const { pnrId } = pnr;
      // eslint-disable-next-line no-restricted-syntax
      const sortingPriority = V2TripDetailsResponseManager.getPriorityIndexOfPnr(pnr);
      return { pnrId, sortingPriority, isSimplePnr: false };
    });
    return pnrIdsWithSortingPriority;
  }

  static getSimplePnrIdsWithSortingPriority(simplePnrs: SimplePnrInfo[]): PnrSortingData[] {
    const simplePnrIdsWithSortingPriority = simplePnrs.map((simplePnr) => {
      const { pnrId } = simplePnr;
      // eslint-disable-next-line no-restricted-syntax
      const sortingPriority = V2TripDetailsResponseManager.getPriorityIndexOfSimplePnr(simplePnr);
      return { pnrId, sortingPriority, isSimplePnr: true };
    });
    return simplePnrIdsWithSortingPriority;
  }

  public getPnrIdsSortedByPriority(pnrs: ITripPnr[], splittedSimplePnrs: SimplePnrInfo[]): PnrSortingData[] {
    // eslint-disable-next-line no-restricted-syntax
    const pnrIdsWithSortingPriority = V2TripDetailsResponseManager.getPnrIdsWithSortingPriority(pnrs);
    const simplePnrIdsWithSortingPriority =
      // eslint-disable-next-line no-restricted-syntax
      V2TripDetailsResponseManager.getSimplePnrIdsWithSortingPriority(splittedSimplePnrs);

    const pnrIdsSortedByPriority = [...pnrIdsWithSortingPriority, ...simplePnrIdsWithSortingPriority].sort(
      (a, b) => a.sortingPriority - b.sortingPriority,
    );
    return pnrIdsSortedByPriority;
  }

  public getSplitSimplePnrs(): SimplePnrInfo[] {
    return this.response.simplePnrs
      .map((simplePnr) => {
        let splitSimplePnrs: SimplePnrInfo[] = [simplePnr];
        if (simplePnr.air) {
          // eslint-disable-next-line no-restricted-syntax
          splitSimplePnrs = [...V2TripDetailsResponseManager.SplitAirSimplePnrIfNeeded(simplePnr)];
        }
        // TODO: Add for rail
        return splitSimplePnrs;
      })
      .flat();
  }

  // BELOW ARE TEMP CHANGES TO MAKE A MIGRATION TO V3 SMOOTHER
  public GetTripDetailsData({
    includePendingManualFormPnrs = false,
    showCancelledItems,
  }: GetTripDetailsDataParams): TripDetailsData {
    const v2NormalizedData = this.getNormalizedDataFromV2TripDetailsResponseManager({
      includePendingManualFormPnrs,
    });

    const { allPnrs, nonCancelledPnrs, totalCancelledPnrs, tripInfo: tripInfoV2 } = v2NormalizedData;
    const pnrs = showCancelledItems ? [...allPnrs] : [...nonCancelledPnrs];

    // pnrIdsSortedByPriority have pnr ids of both simple and normal pnrs
    const allSimplePnrs = this.getSplitSimplePnrs();
    const nonCancelledSimplePnrs = allSimplePnrs.filter(
      (simplePnr) =>
        // eslint-disable-next-line no-restricted-syntax
        !V2TripDetailsResponseManager.IsPnrStatusCancelled(simplePnr.pnrStatus),
    );
    const totalCancelledSimplePnrs = allSimplePnrs.length - nonCancelledSimplePnrs.length;
    const splittedSimplePnrs = showCancelledItems ? [...allSimplePnrs] : [...nonCancelledSimplePnrs];
    const pnrIdsSortedByPriority = this.getPnrIdsSortedByPriority(pnrs, splittedSimplePnrs);

    const numActivePnrsWithoutShellPnrs = nonCancelledPnrs.filter((pnr) => {
      return pnr.bookingStatus !== UserFacingStatus.APPROVAL_DENIED_STATUS;
    }).length;
    const pnrsForTripActivity = allPnrs.map((pnr) => ({ sourcePnrId: pnr.sourcePnrId, pnrId: pnr.pnrId }));

    const isAirMultipax = pnrs.some((pnr) => pnr.air && pnr.air.travelerInfo.length > 1);
    // Though Hotel multipax is not true multipax since the booking is made under the primary traveler's name
    // Still considering it as such to make it simpler for users
    const isHotelMultipax = pnrs.some(
      (pnr) =>
        pnr.hotel?.hotel?.occupancyDates?.occupancy &&
        HotelDetailsManager.GetTotalTravelersFromOccupancyData(pnr.hotel.hotel.occupancyDates.occupancy) > 1,
    );
    const isMultiPax = isAirMultipax || isHotelMultipax;

    const tripInfo: TripV3Info = {
      id: tripInfoV2.info?.tripId ?? '',
      name: tripInfoV2.info?.name ?? '',
      description: tripInfoV2.info?.description ?? '',
      start: tripInfoV2.start,
      end: tripInfoV2.end,
      status: userFacingStatusV1ToV2(tripInfoV2.status),
      isPersonalTrip: tripInfoV2.isPersonalTrip,
      isAdhocTrip: tripInfoV2.isAdhocTrip,
      applicationContext: tripInfoV2.applicationContext,
      isAdhocUserSaved: tripInfoV2.isAdhocUserSaved,
    };

    // eslint-disable-next-line no-restricted-syntax
    const primaryTravelerFromTrip = V2TripDetailsResponseManager.getPrimaryTravelerFromTrip(this.response);
    const primaryTravelerName = primaryTravelerFromTrip?.user?.name;
    const primaryTravelerEmail = primaryTravelerFromTrip?.user?.email ?? '';
    const profileDisplayText = getProfileDisplayText(primaryTravelerName, primaryTravelerEmail);
    const primaryTravelerUserId = primaryTravelerFromTrip?.userOrgId?.userId?.id;

    const bookingFlow = tripInfo.isAdhocTrip ? BookingFlowEnum.ADHOC : BookingFlowEnum.TRAVELER;

    const showNoResults: boolean =
      !pnrs.length && !this.response.pendingShellPnrs?.length && !this.response.simplePnrs.length;
    const showAddToTrip = Boolean(!tripInfo.isAdhocTrip || tripInfo.isAdhocUserSaved);
    const { eventSummary } = this.response;

    const trackTripDetailsTelemetryData: TripDetailsData['trackTripDetailsTelemetryData'] = {
      totalCancelledPnrs,
      totalPnrs: allPnrs.length,
      tripId: this.GetTripId(),
      tripInfo: {
        start: tripInfo.start,
        end: tripInfo.end,
        name: tripInfo.name,
        status: tripInfo.status,
      },
    };

    return {
      pnrsV2: pnrs,
      pnrsV3: [],

      numActivePnrsWithoutShellPnrs,
      totalCancelledPnrs: totalCancelledSimplePnrs + totalCancelledPnrs,
      pnrsForTripActivity,

      tripInfo,
      isPastTrip: this.isPastTrip(),
      // eslint-disable-next-line no-restricted-syntax
      isLastFlightOvernight: V2TripDetailsResponseManager.isLastFlightOvernight(pnrs),

      profileDisplayText,
      bookingFlow,
      showNoResults,
      showAddToTrip,
      primaryTravelerUserId,
      eventSummary,
      isMultiPax,

      trackTripDetailsTelemetryData,
      splittedSimplePnrs,
      pnrIdsSortedByPriority,
    };
  }

  public GetTripDetailsCardCommonData({ pnr }: { pnr: ITripPnr }): TripDetailsCardCommonData {
    const tripInfo = this.GetTripInfo();

    return {
      tripStartDate: tripInfo.start,
      tripEndDate: tripInfo.end,
      isPastTrip: this.isPastTrip(),
      isPastOrCancelledTrip: this.isPastOrCancelledTrip(),

      // eslint-disable-next-line no-restricted-syntax
      pnrStartDate: V2TripDetailsResponseManager.GetPnrStartDate(pnr),
      // eslint-disable-next-line no-restricted-syntax
      isPnrCancelled: V2TripDetailsResponseManager.IsPnrCancelled(pnr),
      // eslint-disable-next-line no-restricted-syntax
      pnrType: V2TripDetailsResponseManager.GetPnrType(pnr),
      isAdhocTrip: Boolean(tripInfo.isAdhocTrip),
    };
  }

  public getTripLevelEventsData(searchType: string): TripsAddToTripEvent['data'] {
    const tripName = this.GetTripName();
    const tripId = this.GetTripId();
    const start = this.GetTripStartDate();
    const end = this.GetTripEndDate();

    const tripStartDate = start ? convertDateFormat(start, dateFormats.ISO, dateFormats.DAY_DATE_YEAR) : '';

    const tripEndDate = end ? convertDateFormat(end, dateFormats.ISO, dateFormats.DAY_DATE_YEAR) : '';

    // eslint-disable-next-line no-restricted-syntax
    const currentDate = dateUtil().format(dateFormats.DAY_DATE_YEAR);

    // eslint-disable-next-line no-restricted-syntax
    const newEndDate = dateUtil(currentDate).add(1, 'day').format(dateFormats.DAY_DATE_YEAR);

    const isStartDatePassed = dateUtil(tripStartDate).isBefore(currentDate);

    const isEndDatePassed = dateUtil(tripEndDate).isBefore(currentDate);

    const startDate = isStartDatePassed ? currentDate : tripStartDate;
    const endDate = isEndDatePassed ? newEndDate : tripEndDate;

    const pnrInfo: TripsAddToTripEvent['data']['pnrInfo'] = [];

    this.response.pnr?.forEach((pnr) => {
      let pnrDetail = {} as PnrInfoTripAdd;

      if (pnr.air?.air) {
        // eslint-disable-next-line no-restricted-syntax
        const airPnrs = V2TripDetailsResponseManager.SplitAirPnrIfNeeded(pnr);

        airPnrs.forEach((currentPnr) => {
          const airPnr = currentPnr?.air?.air;
          if (airPnr) {
            const firstLegIndex = first(pnr.air?.legInfo)?.legIndex ?? 0;
            const lastLegIndex = last(pnr.air?.legInfo)?.legIndex ?? 0;
            const firstFlightIndex = first(airPnr.data?.legs[firstLegIndex]?.flightIndices) ?? 0;
            const lastFlightIndex = last(airPnr.data?.legs[lastLegIndex]?.flightIndices) ?? 0;

            pnrDetail = {
              ...pnrDetail,
              travelers: getTravelerBasicDetails(currentPnr?.air?.travelerInfo ?? []),
              pnrId: pnr.pnrId,
              sourcePnrId: pnr.sourcePnrId,
              paxNum: pnr.air?.travelerInfo.length ?? 0,
              air: {
                departureDateTime: first(airPnr.data.flights)?.departureDateTime,
                arrivalDateTime: last(airPnr.data.flights)?.arrivalDateTime,
                origin: { airport: airPnr?.data?.flights[firstFlightIndex]?.origin.airport ?? '' },
                destination: { airport: airPnr?.data?.flights[lastFlightIndex]?.destination.airport ?? '' },
                marketing: airPnr?.data?.flights[lastFlightIndex]?.marketing,
                operating: [
                  ...new Set([
                    airPnr?.data?.flights[lastFlightIndex]?.marketing,
                    airPnr?.data?.flights[firstFlightIndex]?.marketing,
                  ]),
                ],
              },
            };
            pnrInfo.push(pnrDetail);
          }
        });
      }

      if (pnr.hotel?.hotel) {
        const hotelPnr = pnr.hotel.hotel;

        pnrDetail = {
          ...pnrDetail,
          travelers: getTravelerBasicDetails(pnr?.hotel?.travelerInfo),
          pnrId: pnr.pnrId,
          sourcePnrId: pnr.sourcePnrId,
          paxNum: pnr.hotel.travelerInfo.length,
          hotel: {
            occupancy: hotelPnr.occupancyDates?.occupancy ?? [],
            numberOfRooms: hotelPnr.rooms.length ?? 0,
            occupancyDates: {
              checkInDate: hotelPnr.occupancyDates?.checkInDate,
              checkOutDate: hotelPnr.occupancyDates?.checkOutDate,
            },
            name: hotelPnr.hotelSpec?.name ?? '',
            coordinates: hotelPnr.hotelSpec?.coordinates,
            address: hotelPnr.hotelSpec?.address,
          },
        };
        pnrInfo.push(pnrDetail);
      }
      if (pnr.car?.car) {
        const carPnr = pnr.car.car;
        const carDetails = carPnr.cars[0];
        pnrDetail = {
          ...pnrDetail,
          travelers: getTravelerBasicDetails(pnr?.car?.travelerInfo),
          pnrId: pnr.pnrId,
          sourcePnrId: pnr.sourcePnrId,
          paxNum: pnr.car.travelerInfo.length,
          car: {
            type: carDetails?.rate?.type,
            vendor: carDetails?.vendor,
            pickupTime: carPnr.pickup,
            dropTime: carPnr.pickup,
            pickupLocation: carDetails?.pickupLocation,
            dropOffLocation: carDetails?.dropOffLocation,
          },
        };
        pnrInfo.push(pnrDetail);
      }
    });

    return {
      startDate,
      endDate,
      tripName,
      searchType,
      tripId,
      eventType: 'Trips - Add To Trip',
      pnrInfo,
    };
  }

  public getTripPnrActionsEventsData({
    pnrId,
    legId,
    pnrType,
    uiEventType,
  }: {
    pnrId: string;
    pnrType: string;
    legId?: string;
  } & UIEvent): TripsPnrCardActionsEvent['data'] {
    const tripId = this.GetTripId();
    const tripName = this.GetTripName();
    const pnr = this.getPnrFromPnrId(pnrId);

    let ticketNumber = '';
    let travelers: Traveler[] = [];
    const externalUrlMetadata = pnr?.externalInfo?.externalUrlMetadata || '';

    if (pnrType === 'air') {
      const legIndex = pnr?.air?.legInfo.find((legInfo) => legInfo.legId === legId)?.legIndex ?? 0;
      ticketNumber = this.GetTicketFromLegIndexAndPnrId(legIndex.toString(), pnrId)?.number ?? '';

      travelers =
        (pnr?.air?.travelerInfo
          .map((travelerInfo) => {
            return {
              email: travelerInfo.traveler?.user?.email ?? '',
              name: travelerInfo.traveler?.user?.name ?? '',
              userOrgId: travelerInfo.traveler?.userOrgId,
            };
          })
          .filter(Boolean) as Traveler[]) || ([] as Traveler);
    }

    if (pnrType === 'hotel') {
      ticketNumber = pnr?.hotel?.hotelInfo?.vendorConfirmationId ?? '';
      travelers =
        (pnr?.hotel?.travelerInfo
          .map((travelerInfo) => {
            return {
              email: travelerInfo.traveler?.user?.email ?? '',
              name: travelerInfo.traveler?.user?.name ?? '',
              userOrgId: travelerInfo.traveler?.userOrgId,
            };
          })
          .filter(Boolean) as Traveler[]) || ([] as Traveler);
    }

    if (pnrType === 'car') {
      ticketNumber = pnr?.car?.carInfo?.vendorConfirmationId ?? '';
      travelers =
        (pnr?.car?.travelerInfo
          .map((travelerInfo) => {
            return {
              email: travelerInfo.traveler?.user?.email ?? '',
              name: travelerInfo.traveler?.user?.name ?? '',
              userOrgId: travelerInfo.traveler?.userOrgId,
            };
          })
          .filter(Boolean) as Traveler[]) || ([] as Traveler);
    }

    return {
      tripName,
      tripId,
      pnrId,
      currentTicketNumber: ticketNumber,
      sourcePnrId: pnr?.sourcePnrId ?? '',
      travelers,
      externalUrlMetadata,
      uiEventType,
    } as TripsPnrCardActionsEvent['data'];
  }

  public getPrimaryTravelerIdFromTripInfo(): string {
    return this.response.tripInfo?.userPid?.id || this.response.tripInfo?.registrarPid?.id || '';
  }

  public getEarlyBirdAddOn(airPnr: AirPnr, legIndex: number) {
    return airPnr.travelerInfo[0].otherAncillaries.filter(
      (ancillary) =>
        ancillary.flightIds.find((flightId) => flightId.legIndex === legIndex) &&
        ancillary.type === AirAncillariesResponseAncillaryAncillaryType.EARLY_BIRD,
    );
  }

  public getAirPnrBookingInfo(pnrId: string) {
    const relevantPnr = this.getPnrFromPnrId(pnrId);
    const tripId = this.GetTripId();
    if (!relevantPnr) {
      return {
        tripId,
        airlineReference: '',
        agencyReference: '',
        dateOfBooking: '',
        totalPaid: MoneyUtil.zeroMoney(),
        totalFareAmount: MoneyUtil.zeroMoney(),
      };
    }
    // eslint-disable-next-line no-restricted-syntax
    const airPnrDetails = V2TripDetailsResponseManager.GetAirPnrCardDetails(relevantPnr);

    const { airlineReference, sourcePnrId, dateOfBooking, totalPaid, totalFare } = airPnrDetails;

    return {
      tripId,
      airlineReference,
      agencyReference: sourcePnrId,
      dateOfBooking,
      totalPaid,
      totalFareAmount: totalFare,
    };
  }

  static shouldShowUndoCheckIn(pnr: ITripPnr) {
    // eslint-disable-next-line no-restricted-syntax
    const { isAnyFlightCheckedIn, itinSource } = this.GetAirPnrCardDetails(pnr);

    const isAlreadyCheckedIn = isSouthwestItin(itinSource) && isAnyFlightCheckedIn;

    return isAlreadyCheckedIn;
  }

  public hasEntireTripFailed(): boolean {
    const { response } = this;
    const hasSimplePnr = response.simplePnrs ? response.simplePnrs.length > 0 : false;
    const hasPnrs =
      response?.pnr?.length > 0 ||
      response?.pendingManualFormPnrs?.length > 0 ||
      response?.pendingShellPnrs?.length > 0;
    return !hasPnrs && hasSimplePnr;
  }
}
