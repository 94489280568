import type { ThirdPartySource } from '../supplier/third_party_info';
import type { DateTime, Duration } from '../../common/date_time';
import type { COGS, CTC, ServiceFee, ItemCTC, PaymentPaymentThirdParty, PaymentDetails } from './payment';
import type { TMCInfo, TmcBasicInfo } from '../../common/tmc';
import type { Money } from '../../common/money';
import type { UserOrgId } from '../common/user_org_id';
import type { EmailAddress } from '../common/email';
import type { Traveler } from '../common/traveler';
import type { ItineraryDetails } from '../rail/rail_common';
import type { Name } from '../../common/name';
import type { PostalAddress } from '../../common/postal_address';
import type { HotelDetailsResponse } from '../hotel/hotel_details_response';
import type { AirSearchResponse, FareType as FareType1, FareAmount } from '../air/air_search_response';
import type { Cabin, PassengerType } from '../air/air_common';
import type { CarSearchResponse } from '../car/car_search_response';
import type { CarSearchInfo } from '../car/car_search_request';
import type { TaxBreakdown } from '../common/tax_breakdown';
import type { Commission } from '../common/commission';
import type { PhoneNumber } from '../../common/phone_number';
import type { CustomField } from '../policy/user_defined_entity';
import type { PolicyInfoRuleResultInfo } from '../common/policy_info';
import type { UtaBaggageItem } from '../air/routehappy';
import type { LimoInfo } from '../../../v2/obt/model/limo-info';
import type { Pcc } from '../../../v2/obt/model/pcc';
import type { HoldStatusEnum } from '../trip/air_create_pnr_request';
import type { Document } from '../document/document_service';
import type { Cost } from './manual_form_common';
import type { ApplicationInfo, ExternalInfo, OtherAncillary } from './pnr_common';
import type { UnusedCreditInfo } from '../air/unused_credits';
import type { HotelSpecialRequests } from '../common/hotel_special_requests';
import type { RateTypeV1 } from '../hotel/hotel_common';
import type { RebookReference } from '../../../v2/obt/model/rebook-reference';
import type { FlightDetail } from '../common/flight_detail';
import type { TicketScheduleChangeInformation } from '../air/air_schedule_change';

/**
 * This enum is returned with create pnr response denoting the status of the
 * created pnr.
 */
export enum CreatePnrStatus {
  SUCCESS = 0,
  APPROVAL_PENDING = 1,
  CC_VERIFICATION_REQUIRED = 2,
  /** PAYMENT_PENDING - When payment is pending for either verification or authorization preventing the completion of PNR */
  PAYMENT_PENDING = 3,
  UNRECOGNIZED = -1,
}

/** Encapsulates the different types of statuses that a Pnr can be in. */
export enum PnrStatusV1 {
  UNKNOWN = 0,
  INITIATED = 1,
  CANCELLED = 2,
  CONFIRMED = 3,
  GROUP_BOOKING_ON_REQUEST = 4,
  WAITLISTED = 5,
  PENDING = 6,
  AIRLINE_UPGRADE = 7,
  WAITLIST_CONFIRMED = 8,
  BOOKING_DENIED_CONTACT_SUPPORT = 9,
  NO_SHOW = 10,
  CONTACT_SUPPORT = 11,
  STATUS_CHANGED_CONTACT_SUPPORT = 12,
  SCHEDULE_CHANGE = 13,
  SEGMENT_REQUEST = 14,
  SCHEDULE_CHANGE_WAITLISTED_BOOKING = 15,
  REQUEST_PENDING = 16,
  WAITLISTED_NOT_CONFIRMED = 17,
  SCHEDULE_CHANGE_NOT_CONFIRMED = 18,
  SCHEDULE_CHANGE_PENDING_STATUS = 19,
  MIS_CONNECTION = 20,
  REQUESTED = 21,
  TICKETED = 22,
  VOIDED = 23,
  CANCELLED_BY_VENDOR = 24,
  CANCELLATION_IN_PROGRESS = 25,
  /** REINSTATED - A cancelled flight has been become active again. */
  REINSTATED = 26,
  /** BOOKING_ON_HOLD - A PNR has been held for x amount of time after which it is autocancelled. */
  BOOKING_ON_HOLD = 27,
  /**
   * AIRLINE_CONTROL - The PNR has been taken over by the airline. Any updates to this PNR will
   * no longer be visible to the TMC.
   */
  AIRLINE_CONTROL = 28,
  UNRECOGNIZED = -1,
}

/**
 * Encapsulates the different types of statuses that would be shown to the end user. These could be
 * applicable for multiple entities like legs, bookings, trips etc.
 */
export enum UserFacingStatus {
  UNKNOWN_STATUS = 0,
  PENDING_STATUS = 1,
  CONFIRMED_STATUS = 2,
  ACTIVE_STATUS = 3,
  COMPLETED_STATUS = 4,
  CANCELLED_STATUS = 5,
  REFUNDED_STATUS = 6,
  VOIDED_STATUS = 7,
  /**
   * PROCESSING_STATUS - This status is used in cases where very little time has elapsed since the
   * user booked the ticket, and we don't have adequate information yet.
   */
  PROCESSING_STATUS = 8,
  /**
   * UNCONFIRMED_STATUS - This status is used in conjunction with `PROCESSING_STATUS`. If a long time
   * has elapsed since the user booked the ticket, and we don't have adequate
   * information yet, we use this status.
   */
  UNCONFIRMED_STATUS = 9,
  AIRLINE_CONTROL_STATUS = 10,
  PAYMENT_DECLINED_STATUS = 11,
  SCHEDULE_CHANGE_STATUS = 12,
  HOLD_STATUS = 13,
  // Approval statuses.
  APPROVAL_REQUESTED_STATUS = 14,
  APPROVAL_DENIED_STATUS = 15,
  CANCELLATION_IN_PROGRESS_STATUS = 16,
  /** INOPERATIVE_STATUS - [Qantas only] Maps to `UN` flight status. */
  INOPERATIVE_STATUS = 17,
  /** FLIGHT_UNCONFIRMED_STATUS - [Qantas only] Maps to `UC` flight status. */
  FLIGHT_UNCONFIRMED_STATUS = 18,
  RAIL_EXCHANGED_STATUS = 19,
  UNRECOGNIZED = -1,
}

/** Whether the PNR is of type 'air', 'hotel', 'car' or 'rail'. */
export enum PNRType {
  UNKNOWN_TYPE = 0,
  AIR = 1,
  HOTEL = 2,
  CAR = 3,
  RAIL = 4,
  LIMO = 5,
  MISC = 6,
  UNRECOGNIZED = -1,
}

export enum TravelerPnrVisibilityStatus {
  VISIBLE = 0,
  HIDDEN = 1,
  UNRECOGNIZED = -1,
}

export enum PnrMetadataShellPnrCreateReasonType {
  /** CONTENT_NOT_AVAILABLE - When particular content is not available */
  CONTENT_NOT_AVAILABLE = 0,
  /** ISSUE_WITH_OBT - Something went wrong with OBT */
  ISSUE_WITH_OBT = 1,
  /** FUNCTIONALITY_NOT_AVAILABLE - Functionality required is not available on OBT */
  FUNCTIONALITY_NOT_AVAILABLE = 2,
  /** OTHER - Other reasons not yet listed */
  OTHER = 3,
  /** MULTI_PAX_BOOKING - Need to perform a multi-pax booking */
  MULTI_PAX_BOOKING = 4,
  /** HOLD_BOOKING - Need to hold a booking */
  HOLD_BOOKING = 5,
  /** LEISURE - Booking for leisure travel */
  LEISURE = 6,
  /** PRICE_ASSURANCE - Booking for price assurance */
  PRICE_ASSURANCE = 7,
  UNRECOGNIZED = -1,
}

export enum AirPnrAlertType {
  UNKNOWN_ALERT_TYPE = 0,
  DEPARTURE_TIME = 1,
  ARRIVAL_TIME = 2,
  BAGGAGE = 3,
  DEPARTURE_GATE = 4,
  ARRIVAL_GATE = 5,
  CHECK_IN_REMINDER = 6,
  DIVERSION = 7,
  CANCELLED = 8,
  REINSTATED = 9,
  ARRIVED = 10,
  DEPARTED = 11,
  ARRIVAL_INFO = 12,
  DEPARTURE_INFO = 13,
}
export enum AirPnrLegAlertType {
  UNKNOWN_LEG_ALERT_TYPE = 0,
  CONNECTION_RISK = 1,
}

export interface TripInfo {
  /** Spotnana trip ID */
  tripId: string;
  /** Name of the trip */
  name: string;
  /** Trip description */
  description: string;
}

/** List of email addresses. */
export interface PnrTravelerEmailConfig {
  /** User Org Id of traveler. */
  userOrgId?: UserOrgId;
  /** Email address to which PNR updates we need to send. */
  emailAddress?: EmailAddress;
  /** Email addresses, where flight stats notifications will be cced. */
  flightStatsNotificationCcEmails: string[];
}

export interface PnrFreshnessInfo {
  latestVersionTime: DateTime;
  returnedVersionTime: DateTime;
}

export interface PnrManualFormPnrInfo {
  /**
   * It contains the PNR visibility status, if marked as HIDDEN should
   * not be displayed to the traveller
   */
  travelerPnrVisibilityStatus: TravelerPnrVisibilityStatus;
  /** Source info for manual form */
  sourceInfo?: PnrManualFormPnrInfoSourceInfo;
}

/** Manual form source info */
export interface PnrManualFormPnrInfoSourceInfo {
  /** The source of booking */
  bookingSource: string;
  /**
   * The third party source of this booking. This is only set if we explicitly
   * know the source, or else 'OFFLINE' source is used.
   */
  thirdParty: ThirdPartySource;
  /**
   * The booking date time at the source, when the PNR was created. This is an
   * optional field. If empty, the current date-time is used as the booking
   * date. The input date time must be in the OffsetDateTime format like
   * "2021-07-07T00:00:00+08:00".
   */
  bookingDateTime?: DateTime;
}

export interface Pnr {
  /** Spotnana ID for this PNR */
  pnrId: string;
  /** Record Locator or other unique trip identifier within the booking source */
  sourcePnrId: string;
  /** @deprecated */
  travelers: Traveler[];
  /** Spotnana trip ID to which the PNR belongs to */
  tripId: string;
  /** booking source identifier - the system with which hte booking was made */
  source: ThirdPartySource;
  /** High level status of the PNR */
  status: PnrStatusV1;
  air?: AirPnr | undefined;
  hotel?: HotelPnr | undefined;
  car?: CarPnr | undefined;
  rail?: RailPnr | undefined;
  limo?: LimoPnr | undefined;
  misc?: MiscPnr | undefined;
  /** The version number associated with the PNR */
  pnrVersion: number;
  /** PCC (security area within the source) that owns this PNR */
  owningPcc: string;
  /** PNR creation date/time from the vendor */
  createdAt?: DateTime;
  /** Date/time the PNR was last updated */
  updatedAt?: DateTime;
  /** User facing status for this booking. */
  bookingStatus: UserFacingStatus;
  /** Trip info to which this PNR belongs */
  tripInfo?: TripInfo;
  /** Payment information about all goods sold */
  costOfGoodsSold?: COGS;
  /** Cost of services, charged to customer */
  costToCustomer?: CTC;
  /** IATA number associated with this pnr */
  iataNumber: string;
  /** Stale PNR info */
  freshnessInfo?: PnrFreshnessInfo;
  /**
   * The service associated with this pnr.
   *
   * @deprecated
   */
  serviceFee?: ServiceFee;
  /** The service fees associated with this pnr. */
  serviceFees: ServiceFee[];

  /**
   * TMC info related to pnr.
   *
   * @deprecated
   */
  tmcInfo?: TMCInfo;
  /**
   * Set to true if there might be an issue in the PNR for which the user should
   * be warned to contact TMC support.
   */
  contactSupport: boolean;
  /** Change Log associated with a PNR/Booking */
  bookingHistory: PnrBookingHistory[];
  /** Owning PCC information. */
  owningPccInfo?: Pcc;
  /** Start time of earlies booking in the PNR. */
  startDate?: DateTime;

  /** Tmc related basic info. */
  tmcBasicInfo?: TmcBasicInfo;
  travelerEmailConfigs: PnrTravelerEmailConfig[];

  /** Whether the booking in the PNR was delay invoiced */
  invoiceDelayedBooking: boolean;
  /** List of all the Custom Fields associated with this Pnr */
  customFields: CustomField[];
  /**
   * The total fare of the PNR inclusive of various components, for example:
   * Flights, Seats, Service fee for an Air Pnr; Hotel rooms, Hotel fee, Service
   * fee for a Hotel PNR.
   */
  totalFare?: Money;
  /** Information related to policies and results of those evaluation on this pnr. */
  policyInfo?: PnrPnrPolicyInfo;
  /** Indicates the source of creation of PNR, like: SHELL, OBT, etc. */
  createdVia: string;
  shellPnrInfo?: PnrShellPnrInfo;

  /** Manual form related info. */
  manualFormPnrInfo?: PnrManualFormPnrInfo;

  cancellationRequestStatus: PnrCancellationRequestStatus;

  /** external pnr info */
  externalInfo?: ExternalInfo;

  /** List of documents associated with this PNR. */
  document: Document[];

  /** Indicates if the Pnr has been finalized */
  isFinalized: boolean;

  /** The warnings in application flow */
  applicationInfo?: ApplicationInfo;
}

/** The state of the cancellation request for this PNR. */
export enum PnrCancellationRequestStatus {
  /** CANCELLATION_STATE_UNKNOWN - No cancellation has been requested yet */
  CANCELLATION_STATE_UNKNOWN = 0,
  /** CANCELLATION_IN_PROGRESS - Cancellation by OBT is in progress */
  CANCELLATION_IN_PROGRESS = 1,
  /** CANCELLATION_BY_AGENT_REQUESTED - Cancellation request sent to agent */
  CANCELLATION_BY_AGENT_REQUESTED = 2,
  UNRECOGNIZED = -1,
}

export interface PnrBookingHistory {
  bookerInfo?: PnrBookingHistoryBookerInfo;
  bookingInfo?: PnrBookingHistoryBookingInfo;
}

export enum PnrBookingHistoryBookerRole {
  UNKNOWN_ROLE = 0,
  /** AGENT - TMC_AGENT */
  AGENT = 1,
  /** TRAVEL_MANAGER - ARRANGER */
  TRAVEL_MANAGER = 2,
  /** TRAVELER - Self Booking */
  TRAVELER = 3,
  UNRECOGNIZED = -1,
}

export enum PnrBookingHistoryBookingStatus {
  UNKNOWN_STATUS = 0,
  /** BOOKED - New Booking */
  BOOKED = 1,
  /** EXCHANGED - Booking Modified */
  EXCHANGED = 2,
  /** CANCELLED - Booking Cancelled */
  CANCELLED = 3,
  /** UPDATED - Booking Updated */
  UPDATED = 4,
  UNRECOGNIZED = -1,
}

export interface PnrBookingHistoryBookerInfo {
  /** For Self or Arranger booking, it will have individual name */
  bookerName: string;
  /** Email address of the booker */
  email: string;
  /** Role of the Booker */
  role: PnrBookingHistoryBookerRole;
  /** Name of the TMC */
  tmcName: string;
}

export interface PnrBookingHistoryBookingInfo {
  /** Datetime when this booking was last created/updated */
  updatedTime?: DateTime;
  /** Current status of this booking */
  status: PnrBookingHistoryBookingStatus;
}

export interface PnrPnrPolicyInfo {
  /** The list of policies which were applied to the PNR. */
  policies: PnrPnrPolicyInfoPolicyId[];
  /** List of rule results. */
  ruleResultInfos: PolicyInfoRuleResultInfo[];
}

export interface PnrPnrPolicyInfoPolicyId {
  id: string;
  version: string;
}

export interface PnrShellPnrInfo {
  /** Reason for creating the shell pnr */
  shellPnrCreateReasonType: string;
  /**
   * If the reason for creating the shell pnr is chosen as OTHER or CONTENT_NOT_AVAILABLE, we expect the
   * agent to explain the reason usinf this free text
   */
  createShellPnrReason: string;
  /**
   * It contains the PNR visibility status, if marked as HIDDEN should
   * not be displayed to the traveller
   */
  travelerPnrVisibilityStatus: TravelerPnrVisibilityStatus;
  /** It will contain the PNR type for different shell PNRs */
  pnrType: string;
}

export interface MiscPnr {
  /** Overall status of the pnr. */
  status: PnrStatusV1;
  miscInfo?: MiscInfo;
  travelerInfo: MiscPnrTravelerInfo[];
  /** This field sets the sorting priority of the MiscPnr to determine its order of display on the trips page */
  sortingPriority: number;
}

export interface MiscPnrTravelerInfo {
  traveler?: Traveler;
}

export interface LimoPnr {
  /** Overall status of the pnr. */
  status: PnrStatusV1;
  limoInfo: LimoInfo;
  travelerInfo: LimoPnrTravelerInfo[];
}

export interface LimoPnrTravelerInfo {
  traveler?: Traveler;
}

export interface RailPnr {
  /**
   * TODO(ST:7089) Add out-of-policy info
   * Details of the complete journey.
   */
  itineraryDetails?: ItineraryDetails;
  bookerDetail?: RailPnrBookerDetail;
  /** Overall status of the pnr. */
  status: PnrStatusV1;
}

/** Details of the booker, currently hardcoded. */
export interface RailPnrBookerDetail {
  name?: Name;
  email: string;
  thirdPartyId: string;
  address?: PostalAddress;
}

export interface HotelPnr {
  status: PnrStatusV1;
  hotel?: HotelDetailsResponse;
  travelerInfo: HotelPnrTravelerInfo[];
  hotelInfo?: HotelPnrHotelInfo;
  /** This field sets the sorting priority of the hotel to determine its order of display on the trips page */
  sortingPriority: number;
  rebookReference?: RebookReference;
}

export interface HotelPnrHotelInfo {
  vendorConfirmationId: string;
  /** Number of rooms booked. */
  numberOfRooms: number;
  /** Rate Code used for the segment. */
  rateCode: string;
  /** Rate type of the room booked. */
  rateType: RateTypeV1;
  /** Some reference (pin/ID) provided by the vendors for aggregator bookings */
  vendorReferenceId: string;
  /** Requested hotel special requests */
  hotelSpecialRequests?: HotelSpecialRequests;
  /** cancellation reference sent by the supplier */
  vendorCancellationId: string;
}

export interface HotelPnrTravelerInfo {
  traveler?: Traveler;
}

export interface HoldInfo {
  holdStatus: HoldStatusEnum;
  holdDateTimeDeadline: DateTime;
}

export interface AirPnr {
  /** @deprecated */
  statuses: PnrStatusV1[];
  air?: AirSearchResponse;
  flightInfo: AirPnrFlightInfo[];
  travelerInfo: AirPnrTravelerInfo[];
  voidPolicy?: AirPnrVoidPolicy;
  /** Information about every 'leg' in this PNR/Booking. */
  legInfo: AirPnrLegInfo[];
  /** Whether the pnr was [ex]changed. */
  changed: boolean;
  /** List of all the Other Service Information associated with the PNR. */
  otherServiceInfos: AirPnrOtherServiceInformation[];
  holdInfo?: HoldInfo;
  /** List of flights that have changed via disruption by the airline. */
  disruptedFlightDetails: FlightDetail[];
}

export interface AirPnrFlightUpdates {
  previousDepartureDateTime: DateTime;
  previousArrivalDateTime: DateTime;
  previousDepartureGate: {
    gate: string;
    terminal: string;
  };
  previousArrivalGate: {
    gate: string;
    terminal: string;
  };
}

export interface ConnectionAlert {
  departingFlightIndex: number;
  minConnectionDuration: {
    iso8601: string;
  };
  previousConnectionDuration: {
    iso8601: string;
  };
  updatedConnectionDuration: {
    iso8601: string;
  };
}
export interface AirPnrLegUpdate {
  connectionAlerts: ConnectionAlert[];
}

/** Encapsulates the information and status for a 'flight'. */
export interface AirPnrFlightInfo {
  /**
   * The index identifying the 'flights' in the 'AirSearchResponse air' message below. The index
   * corresponds to the flight in air->data->flights list.
   */
  flightIndex: number;
  /**
   * The unique confirmation number provided by the airline. This could be empty, if the
   * airline hasn't confirmed this flight yet.
   */
  vendorConfirmationId: string;
  /** The associated flight status. */
  status: PnrStatusV1;
  sourceStatus: string;
  /**
   * Updates to the flight that needs to be communicated to the user. These
   * could be last minute gate changes, delayes etc that we get from
   * FlightStats etc.
   */
  flightUpdates: AirPnrFlightUpdates;
  /** Details about any duplicate flight segment. Cabin code and status of other duplicate segments. */
  otherStatuses: AirPnrFlightInfoOtherStatus[];
}

export interface AirPnrFlightInfoOtherStatus {
  cabin: Cabin;
  status: PnrStatusV1;
}

/** Encapsulates all the changes to the flight like delays, gate changes etc. */
export interface AirPnrFlightInfoFlightUpdates {
  /**
   * New departure time.
   *
   * @deprecated
   */
  updatedDepartureDateTime?: DateTime;
  previousDepartureDateTime?: DateTime;
  /**
   * New arrival time.
   *
   * @deprecated
   */
  updatedArrivalDateTime?: DateTime;
  previousArrivalDateTime?: DateTime;
  /**
   * New departure gate.
   *
   * @deprecated
   */
  updatedDepartureGate?: AirPnrFlightInfoFlightUpdatesGate;
  previousDepartureGate?: AirPnrFlightInfoFlightUpdatesGate;
  /**
   * New arrival gate.
   *
   * @deprecated
   */
  updatedArrivalGate?: AirPnrFlightInfoFlightUpdatesGate;
  previousArrivalGate?: AirPnrFlightInfoFlightUpdatesGate;
}

export interface AirPnrFlightInfoFlightUpdatesGate {
  gate: string;
  terminal: string;
}

/** Encapsulates the information and status for a 'leg'. */
export interface AirPnrLegInfo {
  /**
   * The index identifying the 'leg' in the 'AirSearchResponse air' message below. The index
   * corresponds to the leg in air->data->legs list.
   */
  legIndex: number;
  /** The leg status, which is supposed to be displayed to the end user. */
  legStatus: UserFacingStatus;
  /** Last minute updates and alerts at leg level. */
  legUpdate?: AirPnrLegUpdate;
  /** This field sets the sorting priority of the leg to determine its order of display on the trips page */
  sortingPriority: number;
  /** list of restrictions per traveler */
  travelerRestrictions: AirPnrLegInfoTravelerRestrictions[];
  /** Identifier for leg. */
  legId: string;
  scheduleChangeInfo: AirPnrLegInfoScheduleChangeInfo;
}

/** Encapsulates all the changes to the leg. */
export interface AirPnrLegInfoLegUpdate {
  /** Connection risk alerts. */
  connectionAlerts: AirPnrLegInfoLegUpdateConnectionRiskAlert[];
}

export interface AirPnrLegInfoLegUpdateConnectionRiskAlert {
  /** Index of departing flight in air->data->flights list. */
  departingFlightIndex: number;
  /** @deprecated */
  minConnectionTime: number;
  /** Minimum connection time for this leg. */
  minConnectionDuration?: Duration;
  /** @deprecated */
  previousConnectionTime: number;
  /** Connection time before this alert. */
  previousConnectionDuration?: Duration;
  /** @deprecated */
  updatedConnectionTime: number;
  /** Connection time after this alert. */
  updatedConnectionDuration?: Duration;
}

export interface AirPnrLegInfoTravelerRestrictions {
  /** index of traveler in Pnr > AirPnr > travelerInfo */
  travelerIndex: number;
  /** restrictions such as loyalty, ktn edit etc */
  restrictions: AirPnrLegInfoTravelerRestrictionsRestriction[];
}

export enum AirPnrLegInfoTravelerRestrictionsRestriction {
  NONE = 0,
  SEAT_EDIT_NOT_ALLOWED = 1,
  LOYALTY_EDIT_NOT_ALLOWED = 2,
  KTN_EDIT_NOT_ALLOWED = 3,
  REDRESS_EDIT_NOT_ALLOWED = 4,
  SSR_EDIT_NOT_ALLOWED = 5,
  OSI_EDIT_NOT_ALLOWED = 6,
  /** SEAT_CHANGE_NOT_ALLOWED - If true seat edit is not allowed for flight with seat already assigned */
  SEAT_CHANGE_NOT_ALLOWED = 7,
  UNRECOGNIZED = -1,
}

export interface AirPnrTravelerInfoBaggageInfo {
  /** Index of leg to which this baggage belongs. If -1, belongs to all legs */
  legIndex: number;
  /** Baggage details */
  baggage?: UtaBaggageItem;
}

export interface AirPnrTravelerInfo {
  traveler?: Traveler;
  tickets: Ticket[];
  /** Seats assigned to the traveler in the flights belonging to this PNR. */
  seatInfo: AirPnrTravelerInfoSeatInfo[];
  /** The passenger type for the booking */
  paxType: PassengerType;
  /** Applied credit for the traveler */
  appliedCredits: AirPnrTravelerInfoAppliedCreditInfo[];
  /** List of all the Special Service Requests associated with the traveler. */
  specialServiceRequestInfos: AirPnrTravelerInfoSpecialServiceRequestInfo[];
  /**
   * List of tickets from last confirmed version. These will only be set for
   * cancelled PNRs.
   */
  lastConfirmedTickets: Ticket[];
  baggageInfo: AirPnrTravelerInfoBaggageInfo[];
  /** Ancillaries other than seat and baggage */
  otherAncillaries: OtherAncillary[];
  /** Boarding pass information. */
  boardingPass: AirPnrTravelerInfoBoardingPass[];
}

/** Message representing the assigned seat to a traveler. */
export interface AirPnrTravelerInfoSeatInfo {
  /** The index of the flight to which this seat belongs. */
  flightIndex: number;
  /** The assigned seat number. */
  number: string;
  /** Whether the assigned seat has been confirmed or not. */
  status: AirPnrTravelerInfoSeatInfoSeatStatus;
  /** The price associated with this seat, if any. */
  amount?: Money;
  /** Seat status code */
  sourceStatus: string;
}

export enum AirPnrTravelerInfoSeatInfoSeatStatus {
  UNKNOWN = 0,
  CONFIRMED = 1,
  PENDING = 2,
  CANCELLED = 3,
  UNRECOGNIZED = -1,
}

/** Boarding Pass Information */
export interface AirPnrTravelerInfoBoardingPass {
  /** The index of the flight to which this boarding pass belongs. */
  flightIndex: number;
  /** Boarding Priority (Applicable for Southwest) */
  boardingPriority: string;
  /** Boarding zone (Applicable for Southwest) */
  boardingZone: string;
}

/** Special Service Request Info. */
export interface AirPnrTravelerInfoSpecialServiceRequestInfo {
  /** 4 letter Special Service Request code. */
  code: string;
  /** A few special service requests need a free text string input (note: special characters are not allowed here). */
  customText: string;
  /** Index of flight (in pnr->air->air->data->flights) mapping with the special service request, starts with 0. */
  flightIndex: number;
  /** Status of the Special Service Request. */
  status: AirPnrTravelerInfoSpecialServiceRequestInfoSpecialServiceRequestStatus;
}

export enum AirPnrTravelerInfoSpecialServiceRequestInfoSpecialServiceRequestStatus {
  SPECIAL_SERVICE_REQUEST_STATUS_UNKNOWN = 0,
  CONFIRMED = 1,
  REQUESTED = 2,
  UNABLE_TO_CONFIRM = 3,
  DENIED = 4,
  UNRECOGNIZED = -1,
}

export interface AirPnrLegInfoScheduleChangeInfo {
  /** This will point to Data -> Flights. */
  flightIndices: number[];
  /**
   * This will be the old confirmed flights, no schedule change, points to
   * AirPnr -> DisruptedFlightIndices
   */
  disruptedFlightIndices: number[];
}

export interface AirPnrVoidPolicy {
  /** Set to true if either PNR is not ticketed or the PNR can be voided based on all the pre validation checks */
  isVoidable: boolean;
  /**
   * Duration up to which free cancelling is allowed. The field is not set if is_voidable is false,
   * or when the PNR can be cancelled indefinitely (like when PNR hasn't been ticket yet, but is in the confirmed state)
   */
  voidPeriod?: Duration;
  /** If false, void policy is not available for the PNR */
  isInfoAvailable: boolean;
}

export interface AirPnrTravelerInfoAppliedCreditInfo {
  /** unused ticket number */
  creditId: string;
  /** credit amount */
  creditAmount?: FareAmount;
  /** Add collect amount i.e extra amount to be collected. It will be -ve if refund is issued */
  fareDifference?: Money;
  /** Any penalty for applying credit */
  penalty?: Money;
}

/** Other Service Information details. */
export interface AirPnrOtherServiceInformation {
  /** The special instruction is given in the form of text (note: special characters are not allowed). */
  customText: string;
  /** Index of flight (in pnr->air->air->data->flights) mapping with the other service information, starts with 0. One OSI belong to one airline, so all the segments of an airline will have the same OSI. */
  flightIndexes: number[];
}

export interface CarPnr {
  status: PnrStatusV1;
  car?: CarSearchResponse;
  travelerInfo: CarPnrTravelerInfo[];
  carInfo?: CarPnrCarInfo;
  /** This field sets the sorting priority of the car to determine its order of display on the trips page */
  sortingPriority: number;
  /** Source status code for this pnr */
  sourceStatus: string;
  /** Original car search information to be used for the modify car search. */
  originalCarSearchInfo?: CarSearchInfo;
}

export interface CarPnrCarInfo {
  vendorConfirmationId: string;
  carTypeCode: string;
  /** @deprecated */
  pickupLocationCode: string;
  /** @deprecated */
  dropoffLocationCode: string;
  /**
   * Number of cars booked of this type in the current segment. Currently we support only one segment.
   * Once we start supporting multiple segments, CarInfo will be repeated(one for each segment)
   */
  numberOfCars: number;
  /** Corporate Id applied while booking. */
  corporateId: string;
  /** cancellation reference sent by the supplier. */
  vendorCancellationId: string;
}

export interface CarPnrTravelerInfo {
  traveler?: Traveler;
}

export interface MiscInfo {
  /** Description of the miscellaneous segment. */
  description: string;
  /** Start date and time. */
  startDateTime?: DateTime;
  /** End date and time. */
  endDateTime?: DateTime;
  /** Miscellaneous segment confirmation number. */
  vendorConfirmationNumber: string;
  /** Miscellaneous segment rate. */
  rate?: Cost;
  /** Pnr status. */
  pnrStatus: PnrStatusV1;
  /**
   * Sorting priority: This field sets the sorting priority of the misc pnr to
   * determine its order of display on the trips page
   */
  sortingPriority: number;
  /** Source status code for this pnr */
  sourceStatus: string;
}

export interface Ticket {
  /** Ticket number. */
  number: string;
  /**
   * When there are many coupons in a PNR, the GDS might break it into multiple
   * conjunction tickets, for example, 0017570774136/37. In such cases, the main
   * ticket number would be 0017570774136 and /37 will be stored as conjunction
   * ticket suffix.
   */
  conjunctionTicketSuffix: string;
  /** Ticket type to determine if it's a flight, ancillary ticket etc. */
  ticketType: TicketType;
  /** Ticket issue date. */
  issuedDate?: DateTime;
  /** Spotnana ticket status. */
  status: TicketStatus;
  /** Raw ticket status from the GDS. */
  sourceStatus: string;
  /** Face value of the ticket. */
  amount?: FareAmount;
  /**
   * If the ticket was created as part of an exchange, the original ticket that
   * was exchanged to create this ticket.
   */
  originalTicketNumber: string;
  originalTicketDetails?: TicketOriginalTicketDetails;
  /**
   * Extra amount the client was charged if the ticket was created as part of an
   * exchange.
   * Deprecated, see addCollectAmount
   *
   * @deprecated
   */
  addCollect?: Money;
  /** The amount refunded back to the client if the ticket was refunded. */
  refundAmount?: FareAmount;
  /** Flight coupons present on the ticket. */
  flightCoupons: TicketFlightCoupon[];
  /** Ticket exchange and refund policy. */
  cancellationPolicy?: TicketCancellationPolicy;
  /** Ancillaries present in the ticket. */
  ancillaries: TicketAncillary[];
  /** The airline that issued this ticket. */
  validatingAirline: string;
  /**
   * If this ticket was created as part of an exchange, the extra amount the user was charged
   * for the transaction.
   */
  addCollectAmount?: FareAmount;
  /** Breakdown of the taxes incurred on this ticket. */
  taxBreakdown?: TaxBreakdown;
  /** Commission for Air is per Ticket */
  commission?: Commission;
  /** IATA number found on the E-ticket */
  iataNumber: string;
  /** Fare calculation line found on the E-ticket. */
  fareCalculation: string;
  /** Ticket refund or voided date. */
  refundVoidedDate?: DateTime;
  /** A ticket can have multiple forms of payments. */
  paymentDetails: PaymentDetails[];
  ticketSettlement: TicketTicketSettlement;
  /** A ticket can have multiple reasons to be incomplete in future */
  ticketIncompleteReasons: TicketTicketIncompleteReason[];
  /**
   * The cost (if any) charged to the customer for this ticket by Spotnana as
   * a merchant in the transaction.
   */
  ticketCtc?: ItemCTC;
  /** Merchant fee charged on this ticket */
  merchantFee?: Money;
  /** Void policy of ticket */
  voidPolicy?: TicketTicketVoidPolicy;
  /** Moving this field from air pnr level to a ticket. */
  changed: boolean;
  /** Fare type for this ticket */
  fareType: FareType1;
  /** Third party for processing payment for this ticket */
  ctcPaymentThirdParty: PaymentPaymentThirdParty;
  /** Plus up applied on this ticket for markup */
  plusUp: number;
  /** Unused credit that was created by cancelling this ticket. */
  createdUnusedCredit?: UnusedCreditInfo;
  /** Schedule change information for this ticket */
  ticketScheduleChangeInformation?: TicketScheduleChangeInformation;
  /** cancellation reference sent by the supplier */
  vendorCancellationId?: string;
}

export enum TicketType {
  UNKNOWN_TICKET_TYPE = 0,
  FLIGHT = 1,
  ANCILLARY = 2,
  MIXED = 3,
  UNRECOGNIZED = -1,
}

export enum TicketStatus {
  UNKNOWN = 0,
  ISSUED = 1,
  VOIDED = 2,
  /** REFUNDED_EXCHANGED - Ticket is refunded (This enum has nothing to do with exchanges) */
  REFUNDED_EXCHANGED = 3,
  /** EXCHANGED - Ticket is exchanged */
  EXCHANGED = 4,
  UNRECOGNIZED = -1,
}

/** Indicates whether the ticket is booked in ARC/BSP or not. */
export enum TicketTicketSettlement {
  UNKNOWN_TICKET_SETTLEMENT = 0,
  ARC_TICKET = 1,
  BSP_TICKET = 2,
  NON_ARC_BSP_TICKET = 3,
  UNRECOGNIZED = -1,
}

/**
 * There could be cases when some information is missing in the tickets
 * Based on the reason, downstream consumers can act accordingly
 */
export enum TicketTicketIncompleteReason {
  UNKNOWN_REASON = 0,
  /**
   * SYS_TICKET - Airline controlled tickets (Sys Tickets) do not have any amount information
   * present as the viewership is restricted for the Travel Agency
   */
  SYS_TICKET = 1,
  /**
   * MISSING_MARKUP_INFO - If markup info for a ticket is not available, all fares from the ticket
   * is removed to ensure that the COGS amount is not exposed
   */
  MISSING_MARKUP_INFO = 2,
  /** DISABLED_PCC - When ticketing PCC is a disabled PCC, we miss the ticket amount etc as we don't have access to the PCC. */
  DISABLED_PCC = 3,
  UNRECOGNIZED = -1,
}

export interface TicketFlightCoupon {
  flightIndex: number;
  status: TicketFlightCouponStatus;
  sourceStatus: string;
  /** @deprecated */
  seat: string;
  legIndex: number;
}

export enum TicketFlightCouponStatus {
  UNKNOWN = 0,
  AIRPORT_CONTROL = 1,
  LIFTED = 2,
  CHECKED_IN = 3,
  EXCHANGED = 4,
  FLOWN = 5,
  NOT_FLOWN = 6,
  REFUNDED = 7,
  VOIDED = 8,
  PRINTED = 9,
  IRREGULAR_OPERATIONS = 10,
  PRINT_EXCHANGE = 11,
  PAPER_TICKET = 12,
  SUSPENDED = 13,
  UNRECOGNIZED = -1,
}

export interface TicketAncillary {
  /** The type of ancillary. */
  ancillaryType: TicketAncillaryAncillaryType;
  /** Base price for the ancillary. */
  basePrice?: Money;
  /** Total price for the ancillary. */
  totalPrice?: Money;
  /** Leg index in case of leg level ancillary. */
  legIndex: number | undefined;
  /** flight index in case of flight level ancillary. */
  flightIndex: number | undefined;
  /**
   * [Pax]BaggageInfo#Baggage[s]#Item for leg_index. Note each baggage
   * item in [Pax]BaggageInfo is mapped to a separate ancillary.
   */
  baggage?: UtaBaggageItem | undefined;
}

export enum TicketAncillaryAncillaryType {
  UNKNOWN = 0,
  SEAT = 1,
  BAGGAGE = 2,
  UNRECOGNIZED = -1,
}

export interface TicketCancellationPolicy {
  /** @deprecated */
  refundPolicy?: TicketCancellationPolicyRefundPolicy;
  exchangePolicy?: TicketCancellationPolicyExchangePolicy;
}

export interface TicketCancellationPolicyRefundPolicy {
  /** Set to true if PNR is refundable */
  isRefundable: boolean;
  /** Refund penalty is populated when ticket is refundable or else is null */
  refundPenalty?: Money;
  /** Set to true, if refund info is available. */
  isInfoAvailable: boolean;
  /** Amount that will be refunded to the traveler if he cancels the ticket */
  refundedAmount?: Money;
  /** True, if ticket is refundable by OBT, else false */
  isRefundableByObt: boolean;
}

export interface TicketCancellationPolicyExchangePolicy {
  /** Set to true if PNR is exchangeable */
  isExchangeable: boolean;
  /** Exchange penalty is populated when ticket is changeable or else is null */
  exchangePenalty?: Money;
  /** Set to true, if exchange info is available. */
  isInfoAvailable: boolean;
  /**
   * This is the amount that will not be refunded but could be stored as
   * unused credits for a traveler and used for future bookings.
   */
  creditAmount?: Money;
}

export interface TicketTicketVoidPolicy {
  /**
   * Expiry date time of void policy in UTC timezone. This will be the void
   * policy expiry date time irrespective of current status of ticket. This
   * field should not be set if ticketing date time is not known.
   */
  voidExpiryDateTime?: DateTime;
  /**
   * Ticketing date time in UTC timezone. This field should not be set if void
   * expiry date time is not known.
   */
  ticketingDateTime?: DateTime;
}

export interface TicketOriginalTicketDetails {
  /** Ticket number of the original ticket. */
  ticketNumber: string;
  /** Tax breakdown of the original ticket. */
  taxBreakdown?: TaxBreakdown;
  /**
   * When there are many coupons in a PNR, the GDS might break it into multiple
   * conjunction tickets, for example, 0017570774136/37. In such cases, the main
   * ticket number would be 0017570774136 and /37 will be stored as conjunction
   * ticket suffix. This would be conjunction_ticket_suffix of the original ticket
   * prior to the exchange.
   */
  conjunctionTicketSuffix: string;
}

export enum LimoInfoPaymentType {
  UNKNOWN_PAYMENT_TYPE = 0,
  PAY_AT_VENDOR = 1,
  PREPAID = 2,
  UNRECOGNIZED = -1,
}

export interface LimoInfoLimoDetails {
  /** Vendor name of the limo. */
  vendorName: string;
  /** Limo type. */
  carType: LimoInfoLimoDetailsCarType;
}

export enum LimoInfoLimoDetailsCarType {
  UNKNOWN_CAR_TYPE = 0,
  STANDARD_CAR = 1,
  EXECUTIVE_CAR = 2,
  BUSINESS_VAN = 3,
  FIRST_CLASS = 4,
  SUV = 5,
  UNRECOGNIZED = -1,
}

/** Driver details of the limo. */
export interface LimoInfoDriver {
  /** Driver name. */
  name: string;
  /** Driver phone number. */
  phone?: PhoneNumber;
}

export interface LimoInfoLegInfo {
  /** Pick up date and time. */
  pickupDateTime?: DateTime;
  /** Drop off date and time. */
  dropOffDateTime?: DateTime;
  /** Pick up notes for the driver. */
  pickupNotes: string;
  /** This field sets the sorting priority of the leg of the limo to determine its order of display on the trips page */
  sortingPriority: number;
  /** Drop off notes for the driver. */
  dropOffNotes: string;
}

export interface CheckPnrStatusRequest {
  pnrId: string;
}

export interface CheckPnrStatusResponse {
  pnrNumber: string;
  pnrStatus: CreatePnrStatus;
}
