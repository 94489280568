import type { IApiRequestTypes } from './apiTypes';

export const endPoints: { [k in IApiRequestTypes]: string } = {
  base: '',
  addEmployee: 'v1/employee/create',
  addEventTravelers: 'v2/events',
  // FCM: Firebase Cloud Messaging
  addFCMToken: 'v2/notification/device/add',
  agent: '/v2/agent',
  agentGroups: '/v2/agent/groups',
  agentTasks: '/v2/agent/tasks',
  agentCompanion: 'v2/agent/companion',
  agentTaskTypes: '/v2/agent/tasks/types',
  airAncillaries: 'v1/air-ancillaries',
  airAutocomplete: 'v1/air-autocomplete',
  airCancelPnr: 'v1/air-cancel-pnr',
  airCreatePNR: 'v1/air-create-pnr',
  airInitiateBooking: 'v1/air-initiate-booking',
  airLoyaltyPrograms: '/v2/air/loyalty-programs',
  airModifyPNR: '/v2/air-modify-book',
  airModifySearch: 'v2/air-modify-search',
  airPnrs: '/v2/air/pnrs',
  airRawFares: 'v2/air/fare-rules/fetch',
  airRetryPayment: '/v2/air/pnrs',
  airRevalidateItenary: 'v1/air-revalidate-itin',
  airRouteHappy: 'v1/air-route-happy',
  airSearch: 'v1/air-search',
  airSearchResultsFeatures: 'v2/companies',
  airSelectedItinerary: 'v1/air-selected-itinerary',
  cancelPnr: 'v1/cancel-pnr',
  carAutocomplete: 'v1/car-autocomplete',
  carCreatePnr: 'v1/car-create-pnr',
  carRateDetails: '/v2/car/rate-details',
  carLoyaltyPrograms: '/v2/car/loyalty-programs',
  carModifySearch: '/v2/car/pnrs',
  carSearch: 'v1/car-search',
  carPnrs: 'v2/car/pnrs',
  carVendorAutocomplete: '/v2/car-vendor-autocomplete',
  checkLoyaltyEnrollEligibility: '/v1/air/check-loyalty-enroll-eligibility',
  checkRestrictions: '/v2/check-restrictions',
  checkoutFeatures: '/v2/obt/checkout-features',
  checkPnrStatus: '/v1/check-pnr-status',
  companyBaseUrl: '/v2/companies',
  companyBaseUrlV3: '/v3/companies',
  companyId: '/v2/companies/id',
  confirmHold: '/v2/air/pnrs',
  createCostcenter: 'v1/cost-center/create',
  createDepartment: 'v1/department/create',
  createEvent: 'v2/events',
  createManualForm: '/v2/manual-form/create',
  createOffice: 'v1/office/create',
  createOrganization: 'v2/company',
  createPartnerTrip: 'v2/partner-trips',
  createPolicy: 'v1/create-policy',
  createShellPnr: 'v1/create-shell-pnr',
  createTraveler: '/v1/traveler/create',
  createTrip: 'v1/create-trip',
  createUserDefinedEntities: 'v1/create-user-defined-entities',
  createVendorProgram: '/v2/organization/vendor-program/create',
  creditCardDelete: 'v1/credit-card-delete',
  // deImpersonateUser: 'v2/deimpersonate-user',
  deleteCostcenter: 'v1/cost-center/delete',
  deleteDepartment: 'v1/department/delete',
  deleteEmployee: 'v1/user/delete',
  deleteFCMToken: 'v2/notification/device/remove',
  deleteOffice: 'v1/office/delete',
  deletePolicy: 'v1/delete-policy',
  deleteUserDefinedEntities: 'v1/delete-user-defined-entities',
  deleteVendorProgram: '/v2/vendor-program/delete',
  documents: '/v2/documents',
  durationMarkers: '/v2/analytics/doc/durationmarkers',
  durationRecords: '/v2/analytics/doc/durationrecords',
  editPnr: '/v2/air/pnrs',
  enrollAirlineLoyalty: '/v1/traveler/enroll-airline-loyalty',
  ensRequest: 'ens/email',
  entityAutocomplete: 'v2/entity-autocomplete',
  eventBase: 'v2/events',
  eventDetails: 'v2/events',
  eventEdit: 'v2/events',
  eventPublish: 'v2/events',
  eventTrip: 'v2/events',
  fetchRelatedProfiles: '/v1/fetch-related-profiles',
  getAirSeatMaps: 'v1/air-seat-map',
  getApplication: 'v2/applications',
  getAsset: '/v1/asset',
  getAuthConfig: 'v1/auth-config',
  getAuthToken: '/v2/auth/token/companies',
  getCustomFieldsDetails: '/v2/custom-fields/details',
  getExchangeRates: 'get-exchange-rates',
  getLoggedInUserBasicInfo: 'v1/me',
  getPaymentOptionBalance: '/v2/payment/points/balance',
  getPersonalProfile: '/v1/fetch-personal-profile',
  getServiceFee: 'v2/service-charge/calculate',
  getTripInvoice: '/reports/trips/itinerary',
  getUserRoles: 'v1/roles/get',
  groupBookings: '/v2/group-bookings',
  hardApproval: '/v1/processApproval',
  hotelAutocomplete: 'v1/hotel-autocomplete',
  hotelCreatePnr: 'v1/hotel-create-pnr',
  hotelDetails: 'v1/hotel-details',
  hotelChainsInfo: '/v2/hotel/hotel-chains-info',
  hotelLoyaltyPrograms: '/v2/hotel/loyalty-programs',
  hotelModifyBooking: '/v1/hotel-modify-booking',
  hotelModifyDetails: '/v1/hotel-modify-details',
  hotelPriceCheck: 'v1/hotel-price-check',
  hotelSearch: 'v1/hotel-search',
  hotelSupplierAutocomplete: 'v2/hotel-supplier-autocomplete',
  hotelValidateRebooking: '/v2/hotel/validate-rebooking',
  impersonateUser: 'v2/impersonate-user',
  licenseCompany: '/v2/license/company',
  listBookingPaymentSources: '/v2/payment/booking-payment-sources',
  listEmployees: 'v2/employee/list',
  listEvents: 'v2/events/list',
  listGroupBookings: '/v2/group-bookings/list',
  listOrganization: 'v1/organization/list',
  listPaymentInfos: '/v2/card/list',
  listPolicies: 'v1/list-policies',
  listRings: '/v2/rings',
  listRoles: 'v1/roles/list',
  listTrips: 'v1/trips/list',
  listTripSummariesV1: 'v1/trip-summaries/list',
  listTripSummariesV2: '/v2/trips/summaries',
  listUserRings: '/v2/user/rings',
  listVendorPrograms: '/v2/organization/vendor-program/list',
  liveMarkers: '/v2/analytics/doc/livemarkers',
  liveRecords: '/v2/analytics/doc/liverecords',
  llfSearch: '/v1/llf-search',
  manualFormHotelAutoComplete: 'v2/manual-form/hotel-autocomplete',
  manualFormRailAutoComplete: 'v2/manual-form/rail-autocomplete',
  moveUserToRing: '/v2/user/ring',
  paymentSetup: '/v2/payment/setup',
  paymentSources: '/v2/payment/payment-sources',
  pcc: '/v2/pcc',
  pccList: '/v2/pcc/list',
  placeAutocomplete: '/v2/place-autocomplete',
  placeDetails: 'v1/place-details',
  pnrSearch: 'v1/pnr-search',
  pnrShared: '/v2/pnrs',
  policyAutocomplete: 'v1/policy-autocomplete',
  preBookQuestions: 'v1/get-pre-book-questions',
  preferredVendorAutocompleteSearch: '/v2/preferred-vendor/autocomplete',
  preSearchQuestions: 'v1/read-user-defined-entities',
  railAutocomplete: 'v2/rail-autocomplete',
  railBookItinerary: 'v1/rail-book-itinerary',
  railCardList: 'v2/rail-cards',
  railCreatePnr: 'v1/rail-create-pnr',
  railInwardSearch: 'v1/rail-search/inward',
  railNextSearch: 'v1/rail-search/next',
  railOutwardSearch: 'v1/rail-search/outward',
  railPNRs: '/v2/rail/pnrs',
  railPreviousSearch: 'v1/rail-search/previous',
  railReserveItinerary: 'v1/rail-reserve-itinerary',
  railSeatMap: '/v2/rail/seat-map',
  readEmployee: 'v1/employee/read',
  readLegalEntityBasicInfo: '/v1/legal-entity/read-basic-info',
  readLegalEntityThirdPartyInfo: '/v1/legal-entity/third-party-info/read',
  readManualForm: '/v2/manual-form/read',
  readOrganization: 'v1/organization/read',
  readOrganizationBasicInfo: 'v1/organization/read-basic-info',
  readOrgBasicInfo: '/v1/organization/read-basic-info',
  readOrgPreferences: 'v2/organization/preference/read',
  readPolicy: 'v1/read-policy',
  readMergedPolicy: 'v1/merged-policy',
  readRelative: '/v1/relative/read',
  readThirdPartyInfo: 'v1/organization/third-party-info/read',
  readTraveler: 'v1/traveler/read',
  readUserDefinedEntities: 'v1/read-user-defined-entities',
  reportHighFares: '/v2/report-issue',
  reportingAutocomplete: 'v2/reporting/autocomplete',
  revalidateHold: '/v2/air/pnrs',
  searchTraveler: 'v1/traveler/search',
  selfSignUp: 'v1/traveler/self-sign-up',
  serviceChargeCompany: '/v2/service-charge/companies',
  sharedCompany: '/v2/company',
  specialServiceRequests: '/v2/air/special-service-requests',
  spendLimits: '/v2/spend-limits',
  supportConfig: '/v2/support-config',
  supportedLanguages: '/v2/global/languages',
  supplierBaseURLV3: '/v3/supplier/companies',
  tmcBaseURLV3: 'v3/supplier/tmcs',
  thoughtspotAuthToken: '/v2/analytics/ts/token',
  travelerUnusedCredits: 'v1/air/fetch-traveler-unused-credits',
  travelerUpdate: 'v1/traveler/update',
  tripDetails: 'v1/trip-details/get',
  trips: '/v2/trips',
  tripShared: '/v2/trips',
  tripsV3: '/v3/trips',
  unauthWhiteLabelConfig: '/v2/white-label-config',
  updateCostcenter: 'v1/cost-center/update',
  updateDepartment: 'v1/department/update',
  updateEmployee: 'v1/employee/update',
  updateLegalEntity: 'v1/legal-entity/update',
  updateLegalEntityThirdPartyInfo: '/v1/legal-entity/third-party-info/update',
  updateManualForm: '/v2/manual-form/update',
  updateOffice: 'v1/office/update',
  updateOrganization: 'v1/organization/update',
  updateOrgPreferences: 'v2/organization/preference/update',
  updatePolicy: 'v1/update-policy',
  updateRole: 'v1/roles/update',
  updateThirdPartyInfo: 'v1/organization/third-party-info/update',
  updateTripDetails: 'v1/update-trip',
  updateUserDefinedEntities: 'v1/update-user-defined-entities',
  userBaseUrl: '/v2/users',
  userUpload: 'v1/hrfeed/upload',
  zendeskChatToken: 'v2/auth',
  siteMessages: 'v2/site-messages',
  workflowTraces: 'v2/debug/supplier',
  debugToolData: 'v2/debug/traces',
  agentContactFee: '/v2/service-charge/charge',
  agentTaskEvents: '/v2/agent/task-events',
  validateLoyaltyProgram: '/v2/loyalty-program/validate',
  listTripServiceCharges: '/v2/service-charge',
  chatAssistant: '/v2/chat-assistant/chat',
  agentAnnouncements: '/v2/agent/announcements',
  agentDesktopAgents: '/v2/agent-desktop/agents',
  agentDesktopTMCs: '/v2/agent-desktop/tmcs',
  approvals: '/v2/approvals',
  updateUnusedCredits: 'v2/air/unused-tickets/update',
  acceptChanges: 'v2/air/accept-change',
  cerebroStructure: '/v2/cerebro/structure',
  agentQueueDashboard: 'v2/queue-dashboard/tmcs',
};

export const publicEndpoints = [endPoints.getAuthConfig, endPoints.unauthWhiteLabelConfig, endPoints.companyId];
export const publicEndpointsRegex = [/^\/v2\/auth\/token\/companies\//, /^\/v2\/companies\/.*\/enrollments/];

export default (type: IApiRequestTypes, getParam?: string): string => endPoints[type] + (getParam || '');
