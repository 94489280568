/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Tier of User
 * @export
 * @enum {string}
 */

export const Tier = {
    BASIC: 'BASIC',
    SEAT1A: 'SEAT1A'
} as const;

export type Tier = typeof Tier[keyof typeof Tier];



