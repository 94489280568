import type { TFunction } from 'i18next';
import first from 'lodash/first';

import type { Preference } from '../types/api/v1/obt/common/common';
import { BookingType } from '../types/api/v2/obt/model/booking-type';
import { ActionDisplayText } from '../types/api/v1/obt/policy/policy_common';
import { RateOptionTicketType } from '../types/api/v1/obt/air/air_search_response';
import type { PolicyInfo } from '../types/api/v1/obt/common/policy_info';
import { getViolationStringV2 } from '../utils/policies';
import { PreferredType } from '../types/api/v1/obt/common/common';

import { isEmptyHtmlString } from '../utils/common';

export class FarePolicyManager {
  readonly policyInfo: PolicyInfo;

  readonly bookingType: BookingType;

  readonly ticketType?: RateOptionTicketType;

  readonly preferences?: Preference[];

  readonly locationRestriction?: {
    prevent: boolean;
    reason: string;
  };

  constructor({
    policyInfo,
    bookingType,
    ticketType,
    preferences,
    locationRestriction,
  }: {
    readonly policyInfo: PolicyInfo;
    readonly bookingType: BookingType;
    readonly ticketType?: RateOptionTicketType;
    readonly preferences?: Preference[];
    readonly locationRestriction?: {
      prevent: boolean;
      reason: string;
    };
  }) {
    this.bookingType = bookingType;
    this.policyInfo = policyInfo;
    this.ticketType = ticketType;
    this.preferences = preferences;
    this.locationRestriction = locationRestriction;
  }

  private getAllRuleResultInfos(): PolicyInfo['ruleResultInfos'] {
    return this.policyInfo?.ruleResultInfos || [];
  }

  getOutOfPolicyRuleResultInfos(): PolicyInfo['ruleResultInfos'] {
    return this.getAllRuleResultInfos().filter(
      (rule) =>
        rule.violationInfos.length > 0 &&
        !rule.actions.some((action) => action.preventBooking && action.preventBooking.prevent),
    );
  }

  getNotAllowedRuleResultInfos(): PolicyInfo['ruleResultInfos'] {
    return this.getAllRuleResultInfos().filter((rule) =>
      rule.actions.some((action) => action.preventBooking && action.preventBooking.prevent),
    );
  }

  checkFareOutOfPolicy(): boolean {
    if (this.bookingType === BookingType.Air) {
      return this.ticketType === RateOptionTicketType.MULTI || this.getOutOfPolicyRuleResultInfos().length > 0;
    }

    return this.getOutOfPolicyRuleResultInfos().length > 0;
  }

  checkFareNotAllowed(): boolean {
    const fareNotAllowedByPolicy = this.getNotAllowedRuleResultInfos().length > 0;
    const fareNotAllowedByPreferences =
      this.preferences &&
      this.preferences.some((preference) => preference.preferredType === PreferredType.COMPANY_BLOCKED);
    const fareNotAllowedByLocationRestriction = this.locationRestriction?.prevent;

    return fareNotAllowedByLocationRestriction || fareNotAllowedByPolicy || !!fareNotAllowedByPreferences;
  }

  private getActionWithDisplayText(): ActionDisplayText | undefined {
    const notAllowedRules = this.getNotAllowedRuleResultInfos();
    const displayTexts = notAllowedRules
      .flatMap((rule) => rule.actions.map((action) => action.preventBooking && action.preventBooking.displayText))
      .filter((displayText) => displayText !== undefined);

    return first(displayTexts);
  }

  private getNotAllowedLabelText(tt: TFunction): string {
    const displayText = this.getActionWithDisplayText();
    if (displayText === undefined) return tt('Not allowed');
    switch (displayText) {
      case ActionDisplayText.OVER_BUDGET:
        return tt('Over budget');
      case ActionDisplayText.NOT_ALLOWED:
        return tt('Not allowed');
      case ActionDisplayText.INSUFFICIENT_POINTS:
        return tt('Insufficient points');
      default:
        return '';
    }
  }

  // For the label text
  getPolicyViolationLabelText(tt: TFunction): string {
    const isOutOfPolicy = this.getOutOfPolicyRuleResultInfos().length > 0;
    const isNotAllowed = this.checkFareNotAllowed();

    // AIR NOt ALLOWED LABEL TEXT
    if (isNotAllowed) {
      return this.getNotAllowedLabelText(tt);
    }

    // AIR OUT OF POLICY LABEL TEXT
    const isMultiTicket = this.ticketType === RateOptionTicketType.MULTI;

    if (isMultiTicket && !isOutOfPolicy) {
      return tt('Separate Tickets');
    }
    if (isOutOfPolicy && isMultiTicket) {
      return tt('Out of policy, 1 more');
    }
    return tt('Out of policy');
  }

  private getPreventBookingLocationRestrictionText(): string | undefined {
    return this.locationRestriction?.reason;
  }

  private getPreventBookingPreferenceText(): string | undefined {
    if (!this.preferences) {
      return undefined;
    }
    const preference = this.preferences.find((p) => p.preferredType === PreferredType.COMPANY_BLOCKED);
    return preference?.blockedReason;
  }

  // Get Prevent Booking Reason Text
  private getPreventBookingReason(): string | undefined {
    const notAllowedRules = this.getNotAllowedRuleResultInfos();
    const reasons = notAllowedRules
      .flatMap((rule) => rule.actions.map((action) => action.preventBooking && action.preventBooking.reason))
      .filter((reason) => reason !== undefined && !isEmptyHtmlString(reason));
    return first(reasons);
  }

  private getPreventBookingViolationText(tt: TFunction): string | undefined {
    const notAllowedRules = this.getNotAllowedRuleResultInfos();
    const violationInfo = first(
      notAllowedRules.flatMap((rule) => rule.violationInfos).filter((violation) => violation),
    );

    if (!violationInfo) return undefined;

    let policyViolationText = '';
    const { predicateString } = violationInfo;
    const expectedValueString = getViolationStringV2({
      value: violationInfo.expectedValue,
      t: tt,
    });

    const actualValueString = getViolationStringV2({
      value: violationInfo.actualValue,
      t: tt,
    });

    if (predicateString) {
      policyViolationText += !isEmptyHtmlString(violationInfo?.predicateString) ? violationInfo?.predicateString : '';
    }
    if (expectedValueString) {
      policyViolationText += ` : <b>${expectedValueString}</b>`;
    }
    if (actualValueString) {
      policyViolationText += tt(` (This booking: {{actualValueString}})`, { actualValueString });
    }
    return policyViolationText;
  }

  private getDefaultPreventBookingText(tt: TFunction): string {
    switch (this.bookingType) {
      case BookingType.Air:
        return tt('Your company policy does not allow you to select this fare.');
      case BookingType.Car:
      case BookingType.Hotel:
      default:
        return tt('Your company policy does not allow you to select this rate.');
    }
  }

  // For the tooltip
  getPolicyViolationTooltipText(tt: TFunction): string {
    const isOutOfPolicy = this.checkFareOutOfPolicy();
    const isNotAllowed = this.checkFareNotAllowed();

    const preventBookingLocationRestrictionText = this.getPreventBookingLocationRestrictionText();
    const preventBookingPreferenceText = this.getPreventBookingPreferenceText();
    const preventBookingReason = this.getPreventBookingReason();
    const preventBookingViolationText = this.getPreventBookingViolationText(tt);
    const preventBookingDefaultText = this.getDefaultPreventBookingText(tt);

    if (isNotAllowed) {
      return (
        preventBookingLocationRestrictionText ||
        preventBookingReason ||
        preventBookingViolationText ||
        preventBookingPreferenceText ||
        preventBookingDefaultText
      );
    }
    if (isOutOfPolicy) {
      return tt('Click to learn more');
    }

    return '';
  }
}
