/* eslint-disable */
import type { PaymentSourceInfoType } from '../../../v2/obt/profile/payments/payment_sources';
import type { CarExtraType, CarType, Vendor } from '../../../v1/obt/car/car_common';
import type { Int32Range, MoneyRange } from '../../../v1/common/range';
import type { Latlng } from '../../../v1/common/latlng';
import type { DateTime } from '../../../v1/common/date_time';
import type { NonProfileUserInfo, UserOrgId } from '../../../v1/obt/common/user_org_id';
import type { CorporateInfo } from '../policy/policy_common';
import type { Preference } from '../profile/preferences';
import { CarSortBy } from '../../../../api/v2/obt/model/car-sort-by';
import { CarSortOrder } from '../../../../api/v2/obt/model/car-sort-order';
import { EngineType, TransmissionSearchFilter } from '../../../../api/v2/obt/model';

export interface SortOption {
  sortBy: SortOptionSortBy;
  sortOrder: SortOptionSortOrder;
}

/**
 * @deprecated
 */
export interface Filters {
  type: CarType[];
  numPassengers?: Int32Range;
  vendors: Vendor[];
  excludeTransmissions: FiltersTransmissionType[];
  preferredOnly: boolean;
}
export interface FiltersV2 {
  type: CarType[];
  numPassengers?: Int32Range;
  vendors: Vendor[];
  excludeTransmissions: FiltersTransmissionType[];
  hasAirConditioning: boolean;
  hasUnlimitedMileage: boolean;
  price?: MoneyRange;
  engineType: CarFiltersEngineTypeEnum[];
  /** Include cars that are from preferred vendors. */
  preferredOnly: boolean;
  /** Filter based on car extras */
  carExtraType: CarExtraType[];
  /**
   * We filter out the results for which only a certain payment type can be
   * applied. So if the list contains VIRTUAL_CARD & REWARDS_PROGRAM,
   * we will return results for which only VIRTUAL_CARD can be applied
   * or results for which only REWARDS_PROGRAM can be applied.
   */
  paymentTypes: PaymentSourceInfoType[];
}

export interface Airport {
  /** IATA airport code. */
  airportCode: string;
  /** Coordinates of the airport. */
  coordinates?: Latlng;
}

export interface City {
  /** City Code. */
  cityCode: string;
}

export interface SearchParams {
  airport?: Airport;
  coordinates?: Latlng;
  city?: City;
  dateTime?: DateTime;
  /**
   * Search query provided by the user. This is to capture the input given by the user to auto complete.
   * For recording purposes only.
   */
  searchQuery: string;
}

export interface CarSearchInfo {
  /** Pickup location and date time. */
  pickup?: SearchParams;
  /** Drop off location and date time. */
  dropOff?: SearchParams;
}

/**
 * @deprecated
 */
export interface CarSearchRequest {
  /**
   * This is for UI to pass in case of non-profile user.
   * Note: we shouldn't populate the user org id object.
   */
  nonProfileUserInfos?: NonProfileUserInfo[];
  /**
   * Can be empty for adhoc/guest bookings
   */
  userOrgIds?: UserOrgId[];
  pickup?: SearchParams;
  dropOff?: SearchParams;
  sortOption?: SortOption;
  filters?: Filters;
  renterAge?: number;
  preferences?: Preference;
  corporateInfo?: CorporateInfo;
  /** Internal ID of the trip. */
  tripId?: string;
}

export interface CarSearchRequestV2 {
  /**
   * This is for UI to pass in case of non-profile user.
   * Note: we shouldn't populate the user org id object.
   */
  nonProfileUserInfos?: NonProfileUserInfo[];
  /**
   * Can be empty for adhoc/guest bookings
   */
  userOrgIds?: UserOrgId[];
  pickup?: SearchParams;
  dropOff?: SearchParams;
  sortOption?: SortOption;
  filters?: FiltersV2;
  renterAge?: number;
  preferences?: Preference;
  corporateInfo?: CorporateInfo;
  /** Internal ID of the trip. */
  tripId?: string;
}

export enum SortOptionSortByEnum {
  PRICE = 0,
  DISTANCE = 1,
  UNRECOGNIZED = -1,
}

export type SortOptionSortBy = 0 | 1 | -1;

export const mapSortOptionSortByV1toV2: Partial<Record<SortOptionSortByEnum, CarSortBy>> = {
  [SortOptionSortByEnum.PRICE]: CarSortBy.Price,
  [SortOptionSortByEnum.DISTANCE]: CarSortBy.Distance,
};

export enum SortOptionSortOrderEnum {
  ASCENDING = 0,
  DESCENDING = 1,
  UNRECOGNIZED = -1,
}

export type SortOptionSortOrder = 0 | 1 | -1;

export const mapSortOptionSortOrderV1toV2: Partial<Record<SortOptionSortOrderEnum, CarSortOrder>> = {
  [SortOptionSortOrderEnum.ASCENDING]: CarSortOrder.Ascending,
  [SortOptionSortOrderEnum.DESCENDING]: CarSortOrder.Descending,
};

export enum FiltersTransmissionTypeEnum {
  MANUAL = 0,
  AUTOMATIC = 1,
}

export const mapFiltersTransmissionTypeEnumV1toV2: Record<FiltersTransmissionTypeEnum, TransmissionSearchFilter> = {
  [FiltersTransmissionTypeEnum.MANUAL]: TransmissionSearchFilter.Manual,
  [FiltersTransmissionTypeEnum.AUTOMATIC]: TransmissionSearchFilter.Automatic,
};

export enum CarFiltersEngineTypeEnum {
  UNSPECIFIED = 0,
  PETROL = 1,
  DIESEL = 2,
  ELECTRIC = 3,
  ALTERNATIVE = 4,
  HYBRID = 5,
}

export const mapCarFiltersEngineTypeEnumV1toV2: Record<CarFiltersEngineTypeEnum, EngineType> = {
  [CarFiltersEngineTypeEnum.UNSPECIFIED]: EngineType.UnknownEngine,
  [CarFiltersEngineTypeEnum.PETROL]: EngineType.Petrol,
  [CarFiltersEngineTypeEnum.DIESEL]: EngineType.Diesel,
  [CarFiltersEngineTypeEnum.ELECTRIC]: EngineType.Electric,
  [CarFiltersEngineTypeEnum.ALTERNATIVE]: EngineType.Hydrogen,
  [CarFiltersEngineTypeEnum.HYBRID]: EngineType.Hybrid,
};

export type FiltersTransmissionType = 0 | 1;
