/* eslint-disable no-nested-ternary */
import React, { HTMLAttributes, useId, useCallback, useMemo, useRef } from 'react';

import { ComboboxStore, SelectItemProps, SelectLabel, SelectPopoverProps, SelectStore } from '@ariakit/react';

import { useTranslation } from 'react-i18next';
import { select_wrapper } from '../SelectRoot/SelectRoot.styles';
import { Typography } from '../Typography';
import { TOptionBase } from '../utils/select';

import type { TInputStates } from '../Input';
import { TDropdownItemSlots } from '../SelectRoot/types';

import { TSelectSizes, selectRootLocalItems } from '../SelectRoot';
import { ComboboxMultiItem, SelectMultiItem } from '../SelectRoot/defaultItemRenderers/multiItemRenderers';
import { ComboboxSingleItem, SelectSingleItem } from '../SelectRoot/defaultItemRenderers/singleItemRenderers';
import { ComboboxListWrapper } from '../SelectRoot/defaultListWrappers/ComboboxListWrapper';
import { SelectListWrapper } from '../SelectRoot/defaultListWrappers/SelectListWrapper';
import { SelectSearchListWrapper } from '../SelectRoot/defaultListWrappers/SelectSearchListWrapper';
import { SelectMultiValue } from '../SelectRoot/defaultValueRenderers/multiValueRenderers';
import { SelectSingleValue } from '../SelectRoot/defaultValueRenderers/singleValueRenderers';
import { SelectConfigContext, SelectVirtualProps, useSelectConfig } from '../SelectRoot/SelectConfigContext';
import { mergeRefs } from '../utils/mergeRef';
import { ItemList as Ilo, ItemListVirtual as Ilv } from './RootItemList';
import { TBaseSelectSlots, TSelectListSlots } from './slots';
import type { OptionWithStringifiedValue } from './storeUtils';
import { useIsInsideModal } from '../Modal/ModalContext';

export type TSelectStore<Variant extends 'single' | 'multiple', TItem extends TSelectOption> = SelectStore<
  Variant extends 'single' ? string : Variant extends 'multiple' ? string[] : never
> & {
  getSelection: (
    value: Variant extends 'single' ? string : string[],
  ) => Variant extends 'single' ? TItem | null : [TItem[], Set<string>];
  clearSelection?: () => void;
};
export type TSelectOptionTypes = 'STANDALONE' | 'PARENT' | 'CHILD' | 'TITLE';
export type TSelectOption = TOptionBase & {
  type?: TSelectOptionTypes;
};

/**
 * Flat list of options
 */

export type TTitleOptionGroup<TItem extends TSelectOption = TSelectOption> = TItem & {
  type: 'TITLE';
  list: TItem[];
};

export type TParentOptionGroup<TItem extends TSelectOption = TSelectOption> = TItem & {
  type: 'PARENT';
  list: TItem[];
};

export type TSelectOptionList<TItem extends TSelectOption = TSelectOption> = Array<
  TItem | TTitleOptionGroup<TItem> | TParentOptionGroup<TItem>
>;

export type TSelectSlots<TItem extends TSelectOption = TSelectOption> = TBaseSelectSlots<TItem> &
  TSelectListSlots &
  TDropdownItemSlots<TItem>;

export type TSelectComboboxRendererProps<
  ComponentType extends 'select' | 'combobox' = 'select',
  Variant extends 'single' | 'multiple' = 'single',
  TItem extends TSelectOption = TSelectOption,
> = {
  wrapperRef?: React.Ref<HTMLElement> | React.ForwardedRef<HTMLElement>;
  triggerRef?: React.Ref<HTMLElement> | React.ForwardedRef<HTMLElement>;
  componentType: ComponentType;
  kind?: Variant extends 'single' ? 'persist' : never;
  placeholder?: string;
  size?: TSelectSizes;
  variant: Variant;
  label: string;
  required?: boolean;
  selectStore: TSelectStore<Variant, TItem>;
  options: TSelectOptionList<OptionWithStringifiedValue<TItem>>;
  emptyContent?: NonNullable<React.ReactNode>;
  showLoader?: boolean;
  showClearButton?: ComponentType extends 'select' ? boolean : never;
  portal?: boolean;
  popoverProps?: SelectPopoverProps;
  /**
   * When true, the popover will have the sameWidth as the anchor element
   */
  sameWidth?: boolean;
  /**
   * When true, the list of options is rendered in place instead of inside a popover.
   * This makes the options always visible.
   */
  renderInPlace?: boolean;
  onItemClick?: (item: TItem) => void;
  setValueOnClick?: SelectItemProps['setValueOnClick'];
  disabled?: boolean;
  slots?: TSelectSlots<TItem>;
  // props to help with rendering messages, errors, etc.
  /** Error state or success state */
  state?: TInputStates;
  /** the text for showing a hint, error, etc. use in tandem with `state` prop */
  helperText?: string;

  ValueRenderer?: (props: { option: TItem }) => JSX.Element;
  hideLabel?: boolean;

  virtual?: SelectVirtualProps;
} & HTMLAttributes<HTMLDivElement> &
  (ComponentType extends 'select'
    ? {
        comboboxStore?: ComboboxStore & { placeholder?: string };
      }
    : {
        comboboxStore: ComboboxStore;
      });

/**
 * This nonsensical dummy title lets us express flat lists the same as nested lists, making our code free of unnecessary conditional branching
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export type TComboboxStore = ComboboxStore & { placeholder?: string };

const { SelectItemGroup, ValueWrapper } = selectRootLocalItems;

const defaultSlots: Required<TBaseSelectSlots> = {
  ItemGroup: SelectItemGroup,
  SingleItem: SelectSingleItem,
  MultiItem: SelectMultiItem,
  SingleValue: SelectSingleValue,
  MultiValue: SelectMultiValue,
  ListWrapper: SelectListWrapper,
};

/**
 * Ariakit components need to be organised a different way if you have a Select with a Search field in the dropdown
 */
const selectSearchSlots: Required<TBaseSelectSlots> = {
  ItemGroup: SelectItemGroup,
  SingleItem: ComboboxSingleItem,
  MultiItem: ComboboxMultiItem,
  SingleValue: SelectSingleValue,
  MultiValue: SelectMultiValue,
  ListWrapper: SelectSearchListWrapper as typeof defaultSlots.ListWrapper,
};

/**
 * Ariakit components need to be organised a different way if you have no Select, only top-level Search field
 */
const autocompleteSlots: Required<TBaseSelectSlots> = {
  ItemGroup: SelectItemGroup,
  SingleItem: ComboboxSingleItem,
  MultiItem: ComboboxMultiItem,
  SingleValue: SelectSingleValue,
  MultiValue: SelectMultiValue,
  ListWrapper: ComboboxListWrapper as typeof defaultSlots.ListWrapper,
};

function getSlots<TItem extends TSelectOption = TSelectOption>(
  slots: TSelectSlots<TItem> = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentType: 'select' | 'combobox',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  comboboxStore?: ReturnType<typeof useSelectConfig>['comboboxStore'],
): TSelectSlots<TItem> & Required<TBaseSelectSlots<TItem>> {
  return {
    ...defaultSlots,
    // if component type is select, and has a comboboxStore, it's a Select with Search in Dropdown
    // if component type is autocomplete, we only have a Search field
    // eslint-disable-next-line no-nested-ternary
    ...(comboboxStore
      ? /* istanbul ignore next */ componentType === 'select'
        ? /* istanbul ignore next */ selectSearchSlots
        : /* istanbul ignore next */ autocompleteSlots
      : {}),
    // currently istanbul ignores are added as these conditions can only be covered once
    // we use this base component inside the new Autocomplete component
    ...slots,
  } as TSelectSlots<TItem> & Required<TBaseSelectSlots<TItem>>;
}

/* eslint-disable no-underscore-dangle */
const SelectComboboxRenderer_ = <
  ComponentType extends 'select' | 'combobox' = 'select',
  Variant extends 'single' | 'multiple' = 'single',
  TItem extends TSelectOption = TSelectOption,
>({
  placeholder,
  size = 'standard',
  label,
  required,
  componentType,
  selectStore,
  options,
  variant,
  kind,
  sameWidth = true,
  renderInPlace,
  onItemClick,
  setValueOnClick,
  slots: userSlots,
  comboboxStore,
  emptyContent,
  ValueRenderer,
  showLoader,
  showClearButton,
  disabled,
  state,
  helperText,
  wrapperRef,
  triggerRef,
  hideLabel,
  portal,
  popoverProps,
  virtual,
  'aria-labelledby': ariaLabelledBy,
  ...props
}: TSelectComboboxRendererProps<ComponentType, Variant, TItem>): JSX.Element => {
  const { t: tt } = useTranslation('WEB');

  const containerRef = useRef<HTMLDivElement>(null);

  const slots = useMemo(
    () => getSlots(userSlots, componentType, comboboxStore),
    [userSlots, componentType, comboboxStore],
  );
  const { SingleValue, MultiValue } = slots;

  const labelId = useId();
  const descriptionId = `${labelId}-description`;

  const onClose = useCallback(() => selectStore.setOpen(false), [selectStore]);
  const isInsideModal = useIsInsideModal();

  const ctxVal = useMemo(
    () => ({
      placeholder,
      size,
      kind,
      variant,
      options,
      selectStore,
      onItemClick,
      setValueOnClick,
      slots,
      comboboxStore,
      componentType,
      labelId,
      emptyContent,
      ValueRenderer,
      showLoader,
      showClearButton,
      disabled,
      state,
      helperText,
      required,
      // respect passed portal value, else if inside Modal portal is false, in regular case portal is true
      portal: portal ?? !isInsideModal,
      sameWidth,
      popoverProps,
      ariaLabelledBy,
      label,
      virtual,
      onClose,
      triggerRef,
      containerRef,
    }),
    [
      slots,
      placeholder,
      size,
      kind,
      variant,
      options,
      selectStore,
      onItemClick,
      setValueOnClick,
      comboboxStore,
      componentType,
      labelId,
      emptyContent,
      ValueRenderer,
      showLoader,
      showClearButton,
      disabled,
      state,
      helperText,
      required,
      portal,
      sameWidth,
      popoverProps,
      ariaLabelledBy,
      label,
      virtual,
      onClose,
      triggerRef,
      isInsideModal,
    ],
  );

  const isEmpty = useMemo(() => {
    if (options.length === 0) return true;

    // if non empty list, we might still have no items (Grouped list)
    // let isE = false;
    const hasSomeItems = options.some(
      /* istanbul ignore next: base case which will never be reached */
      (option) => {
        // we find a group with children
        if ('list' in option && option.list.length) return true;
        // we find a non-group in the list
        if (!('list' in option) && 'value' in option) return true;

        return false;
      },
    );

    return !hasSomeItems;
  }, [options]);

  const ItemList = virtual?.enabled ? Ilv : Ilo;

  return (
    <SelectConfigContext.Provider value={ctxVal}>
      <div
        data-testid={`${label}-select-wrapper`}
        css={select_wrapper}
        {...props}
        ref={mergeRefs(containerRef, wrapperRef)}
      >
        {!hideLabel && label && componentType === 'select' && (
          <SelectLabel
            render={
              <Typography id={labelId} variant="body2" kind="medium" color="secondary">
                {label}
                {required && (
                  <Typography aria-hidden as="span" color="error" variant="body2" kind="medium">
                    &nbsp;*
                  </Typography>
                )}
              </Typography>
            }
            id={labelId}
            store={selectStore as TSelectStore<Variant, TItem>}
          />
        )}

        {/**
         * intentionally commented as this can't be covered in unit tests until
         * future work of using SelectComboboxRenderer in Autocomplete is done
         */}

        {/* {componentType === 'combobox' && (
          <ValueWrapper state={state} helperText={helperText}>
            <Typography
              id={labelId}
              variant="body2"
              kind="medium"
              color="secondary"
              as="label"
              css={[flex.init, flex.column, { gap: unit(0.25) }]}
            >
              {required ? `${label} *` : label}
              <ComboboxTrigger state={state} />
            </Typography>
          </ValueWrapper>
        )} */}

        {componentType === 'select' && (
          <ValueWrapper id={descriptionId} state={state} helperText={helperText}>
            {!renderInPlace && variant === 'single' && <SingleValue placeholder={placeholder} size={size} />}
            {!renderInPlace && variant === 'multiple' && <MultiValue placeholder={placeholder} size={size} />}
          </ValueWrapper>
        )}

        <ItemList
          list={options}
          renderInPlace={renderInPlace}
          label={label}
          emptyContent={isEmpty ? emptyContent ?? tt('No results found') : null}
          showLoader={showLoader}
        />
      </div>
    </SelectConfigContext.Provider>
  );
};

export const SelectComboboxRenderer = React.memo(SelectComboboxRenderer_) as unknown as typeof SelectComboboxRenderer_;
