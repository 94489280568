import { PropsWithChildren, ReactElement, useLayoutEffect, useEffect } from 'react';
import { TokenExchangeTypes, EmbedEventTypes } from '@spotnana-tech/embed-helpers';

import {
  useAuth,
  tokenExchange,
  safeJsonStringify,
  useFeatureFlag,
  webSessionStorage,
  StorageKeys,
  logger,
  storage,
} from 'obt-common';
import { AxiosError } from 'axios';
import { collectOrgId } from 'src/utils/orgId/orgId';

export const TokenExchange = ({ children }: PropsWithChildren): ReactElement => {
  const { authenticateUserByTokens } = useAuth();
  const isStoreRedirectOnErrorEnabled = useFeatureFlag('FE_CORP_EMBED_STORE_REDIRECT_ON_ERROR') || false;
  const isClearStoreOnTokenExchangeFlowEnabled = useFeatureFlag('FE_CORP_CLEAR_STORE_ON_TOKEN_EXCHANGE_FLOW') || false;
  const isPreventDuplicatedTokenExchangeLoginEnabled =
    useFeatureFlag('FE_CORP_PREVENT_DUPLICATED_TOKEN_EXCHANGE_LOGIN') || false;
  // Using URLSearchParams because we are outside of BrowserRouter.
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const idp = params.get('idp');

  const loginViaTokens = async () => {
    try {
      // Clear the ordId and travelerId  everytime when the token exchange flow is initialized to remove all leftovers from
      // previous session (ex: organizationID)
      // TODO: remove this logic once we implement clearing the entire app before starting the token exchange flow
      if (isClearStoreOnTokenExchangeFlowEnabled) {
        await storage.removeItem(StorageKeys.PRIMARY_TRAVELER_ID);
        await storage.removeItem(StorageKeys.ORG_ID);
        await collectOrgId();
      }

      if (isPreventDuplicatedTokenExchangeLoginEnabled) {
        // delete the idp query param from the URL before starting token exchange flow to reset the loader which
        // is relied on the idp query param and since the page is not reloaded, the loader will not be reset in App.tsx
        params.delete('idp');
        // use replaceState to prevent the browser for reloading the page
        window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
        await authenticateUserByTokens();
      } else {
        await authenticateUserByTokens();
        params.delete('idp');
        window.location.replace(`${window.location.pathname}?${params.toString()}`);
      }
    } catch (err) {
      logger.error(new Error(`TOKEN_EXCHANGE_ERROR: ${err.message}`, { cause: err }));
      window.parent.postMessage(
        {
          from: 'spotnana-embed',
          type: TokenExchangeTypes.TOKEN_EXCHANGE_ERROR,
          payload: {
            errorCode: EmbedEventTypes.INVALID_TOKEN,
            errorMessage: err?.message,
            errorStack: err?.stack,
            data: safeJsonStringify((err as AxiosError)?.response?.data, ''),
            url: (err as AxiosError)?.request?.responseURL || (err as AxiosError)?.response?.config?.url,
          },
        },
        '*',
      );
    }
  };

  useLayoutEffect(() => {
    // Authenticate user if idp is present, even if user is already loggedin
    if (idp && tokenExchange.includes(idp)) {
      // Store the idp in session storage so we can use it to retrigger the token exchange flow later.
      webSessionStorage.setItem(StorageKeys.TOKEN_EXCHANGE_IDP, idp);
      loginViaTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shouldHandleError =
      !!webSessionStorage.getItem(StorageKeys.TOKEN_EXCHANGE_IDP) && isStoreRedirectOnErrorEnabled;

    // Harness values are refetched when auth state changes. Since this feature is related to auth
    // state changes, it may flip-flop, but we only care if it was true at least once for the flow.
    // After this feature is stable, we can remove this storage key in favor of simply checking if TOKEN_EXCHANGE_IDP is set.
    if (shouldHandleError) {
      webSessionStorage.setItem(StorageKeys.TOKEN_EXCHANGE_ERROR_HANDLING, 'true');
    }
  }, [isStoreRedirectOnErrorEnabled]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
