import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import type { AxiosError } from 'axios';
import api from '../../api';
import { StorageKeys } from '../../types';
import { delay, getSessionId } from '../../utils';
import { webSessionStorage } from '../../utils/SessionStorage';

import { SpotnanaError } from '../../api/SpotnanaError';
import { fetchUserAuthConfig } from '../../queries/users';
import type { ReadSupportConfigResponse } from '../../types/api/v2/obt/model/read-support-config-response';
import type { IAuthenticatedUserBasicInfo } from '../../types/auth';
import Config from '../../utils/Config';
import { isGenesysAuthCloudConfigValid } from '../../utils/genesys/isGenesysAuthCloudConfigValid';
import { storage } from '../../utils/Storage';
import type { IClientIdUserPoolId } from './types';

export const getAccessTokenFromStorage = async (): Promise<string | null | undefined> => {
  const allItems = await storage.getAllItems();
  const accessTokenKey = Object.keys(allItems).find((key) => key.includes('accessToken'));
  return accessTokenKey && allItems[accessTokenKey] ? allItems[accessTokenKey] : null;
};

export const getRefreshTokenFromStorage = async (): Promise<string | null | undefined> => {
  const allItems = await storage.getAllItems();
  const refreshTokenKey = Object.keys(allItems).find((key) => key.includes('refreshToken'));
  return refreshTokenKey && allItems[refreshTokenKey] ? allItems[refreshTokenKey] : null;
};

export const getClientIdAndUserPoolIdFromAccessToken = (accessToken: CognitoAccessToken): IClientIdUserPoolId => {
  const userPoolId = accessToken.payload.iss.split('amazonaws.com/')[1];
  const clientId = accessToken.payload.client_id;

  return { clientId, userPoolId };
};

export const storeTokensInStorage = async (
  accessToken: string,
  refreshToken: string,
  idToken: string,
  storage: any,
): Promise<void> => {
  const AccessToken = new CognitoAccessToken({ AccessToken: accessToken });

  const { clientId, userPoolId } = getClientIdAndUserPoolIdFromAccessToken(AccessToken);

  const user = new CognitoUser({
    Storage: storage,
    Username: AccessToken.payload.username,
    Pool: new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    }),
  }) as CognitoUser & { deviceKey: string; cacheDeviceKeyAndPassword(): void };

  // Enable this to handle MFA
  // user.deviceKey = AccessToken.payload.device_key;
  // user.cacheDeviceKeyAndPassword();

  const IdToken = new CognitoIdToken({ IdToken: idToken });
  const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
  const sessionData = { IdToken, AccessToken, RefreshToken };
  const session = new CognitoUserSession(sessionData);

  // Cache access & refresh tokens
  // That will be used by `Auth` to initiate a new session
  user.setSignInUserSession(session);

  // This is added to make sure that on react-native values are stored inside AsyncStorage
  await delay(1);
};

/**
 * Builds Custom State object that is passed to OAuth Login
 */
export const buildCustomStateForOAuthLogin = () => {
  return {
    sessionId: getSessionId(),
  };
};

export async function shouldGenesysRedirect(): Promise<boolean> {
  if (!window?.location?.origin) {
    // It is in mobile, redirect is handled seperately.
    return false;
  }
  if (window.genesysAuthCode) {
    return false;
  }
  const currentUserInfo = (await api('GET', 'getLoggedInUserBasicInfo')) as IAuthenticatedUserBasicInfo;
  const tmcId = currentUserInfo.existingUser?.userOrgId?.tmcBasicInfo?.contractingTmc?.id?.id;
  if (currentUserInfo.isUserRegistered && tmcId) {
    try {
      const res = await api('GET', 'sharedCompany', {
        urlParam: `/${tmcId}/support-config`,
      });
      const supportConfig = res as ReadSupportConfigResponse;
      const isValid = isGenesysAuthCloudConfigValid(supportConfig.supportConfig);
      return isValid;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return false;
}

/**
 * Redirec to IDP url for Genesys auth.
 * Please refer to: https://help.mypurecloud.com/articles/authenticated-web-messaging-overview/
 */
export async function genesysAuthRedirect() {
  const { VITE_GENESYS_CHAT_CLIENT_ID, VITE_SPOTNANA_IDP_URL } = Config;
  const { origin, href } = window.location;
  const encodedOrigin = origin;

  const currentUserInfo = (await api('GET', 'getLoggedInUserBasicInfo')) as IAuthenticatedUserBasicInfo;
  /* istanbul ignore next: For not bloating the unit test cases, ignoring low risk statement */
  const userId = currentUserInfo.existingUser?.userOrgId?.userId?.id || '';
  const userAuthConfig = await fetchUserAuthConfig(userId);

  const urlParams: { [key in string]: string } = {
    redirect_uri: encodedOrigin,
    state: 'genesys',
    response_type: 'code',
    client_id: VITE_GENESYS_CHAT_CLIENT_ID as string,
    scope: 'email openid',
  };
  /* istanbul ignore next: For not bloating the unit test cases, ignoring low risk statement */
  if (userAuthConfig.authProviders?.length) {
    /* istanbul ignore next: For not bloating the unit test cases, ignoring low risk statement */
    const identityProvider = userAuthConfig.authProviders[0].metadata?.azure?.id || '';
    if (identityProvider) {
      urlParams.identity_provider = identityProvider;
    }
  }

  const stringifiedUrlParams = new URLSearchParams(urlParams).toString();
  const urlStr = `${VITE_SPOTNANA_IDP_URL}/oauth2/authorize?${stringifiedUrlParams}`;
  const path = href.substring(origin.length);
  webSessionStorage.setItem(StorageKeys.URL_BEFORE_GENESYS_REDIRECT, path);
  window.location.href = urlStr;
}

export function genesysAuthCodeCatch() {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const authCode = params.get('code');
  const stateVal = params.get('state');
  if (authCode && stateVal === 'genesys') {
    // only handle Genesys auth, if not it must do nothing to delegate the handling to Amplify
    window.genesysAuthCode = authCode;

    // remove 'code' from query string
    const url = window.location.href;
    // Create a URL object
    const urlObject = new URL(url);
    urlObject.searchParams.delete('code');
    urlObject.searchParams.delete('state');
    window.history.replaceState({}, document.title, urlObject.toString());
  }
}

/**
 * Adapt the `response` to `AxiosError`
 * to collect the error code correctly and forward to Brex (see onApiError.ts)
 */
export const toAxiosError = (url: string, data: string | object, response: Response): AxiosError => {
  const err = new Error(`Request (${url}) failed with status code ${response.status}`) as AxiosError;

  err.request = {
    responseURL: url,
  };
  err.response = {
    data,
    headers: response.headers,
    status: response.status,
    statusText: response.statusText,
    config: {},
    request: err.request,
  };
  return err;
};

export const safeJsonParse = (rawJson: string): object | string => {
  try {
    return JSON.parse(rawJson) as object;
  } catch (e) {
    return rawJson;
  }
};
