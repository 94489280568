import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { logger, useStorage, StorageKeys, IUserOrgId } from 'obt-common';

const NotFound: React.FC = () => {
  const { data: primaryTravelerId, isFetching: isFetchingPrimaryTravelerId } = useStorage<IUserOrgId>(
    StorageKeys.PRIMARY_TRAVELER_ID,
  );
  const { data: loggedInUserId, isFetching: isFetchingLoggedInUserId } = useStorage<IUserOrgId>(
    StorageKeys.LOGGED_IN_USER_ID,
  );
  const travelerId = primaryTravelerId ?? loggedInUserId;

  React.useEffect(() => {
    logger.error(
      new Error('NOT_FOUND_404', {
        cause: {
          message: `travelerid: ${travelerId?.userId?.id}, travelerOrganizationId: ${travelerId?.organizationId?.id}, arrangerId: ${loggedInUserId?.userId?.id}, arrangerOrganizationId: ${loggedInUserId?.organizationId?.id}, path: ${window.location.pathname}`,
        },
      }),
    );
  }, [
    loggedInUserId?.organizationId?.id,
    loggedInUserId?.userId?.id,
    travelerId?.organizationId?.id,
    travelerId?.userId?.id,
  ]);

  if (isFetchingPrimaryTravelerId || isFetchingLoggedInUserId) {
    return <></>;
  }

  return <Redirect to="/" />;
};

export default NotFound;
